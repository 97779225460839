import React from "react";

import {
    Box,
    Button,
    Typography,
    styled,
    Slider,
    Tooltip,
} from "@material-ui/core";
import Cfloanapplication2Controller, {
    Props
} from "./Cfloanapplication2Controller";
import { leftArrowImg } from "./assets";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles'
import { Toaster } from "react-hot-toast"

const RightSideBox = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomWalletTypography1 = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    padding: "8px 39px",

});

const CustomWalletTypography2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 400,
    fontSize: '14px',
    padding: "8px 16px",
    [theme.breakpoints.down('sm')]: {
        padding: 0
    }
}));


const CustomBackBox = styled(Box)({
    display: 'flex',
    marginBottom: "30px",
    '@media(max-width: 412px)': {
        marginLeft: "-20px",
    },
});

const CustomUserBoxNew = styled(Box)(({ theme }) => ({
    textAlign: "center",
    gap: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(0.5),
    marginBottom: "20px",
    '@media(max-width: 412px)': {
        width: "270px",
        marginLeft: "-20px",
    },
}));

const CustomUserBoxNew2 = styled(Box)(({ theme }) => ({
    textAlign: "center",
    gap: theme.spacing(1),
    backgroundColor: "#FFFFFF",
    padding: "50px",
    borderRadius: theme.spacing(0.5),
    marginBottom: "20px",
    '@media(max-width: 412px)': {
        width: "270px",
        marginLeft: "-20px",
        padding: theme.spacing(4, 3),
    },
}));

const LoanAmountBox = styled(Box)({
    maxWidth: "435px",
    margin: "25px auto"
});

const RepayTermBox = styled(Box)({
});

const HelpText1 = styled(Typography)({
    fontFamily: 'Poppins',
    color: 'rgba(15, 23, 42, 0.30)',
    fontWeight: 400,
    fontSize: '12px',
    marginTop: "30px"
});

const HelpText2 = styled(Typography)({
    fontFamily: 'Poppins',
    color: 'rgba(15, 23, 42, 0.30)',
    fontWeight: 400,
    fontSize: '12px',
    marginTop: "10px"
});

export const RangeBubbleContainer = styled(Slider)({

    width: '430px !important',
    '@media(max-width: 600px)': {
        width: '250px !important',
    },
    "& .PrivateValueLabel-offset-32": {
        marginTop: '65px !important',
        "& .PrivateValueLabel-circle-33": {
            borderRadius: '5px !important',
            transform: 'rotate(0deg) !important',
            height: 0,
            width: 0,
            padding: '12px 16px !important',
            margin: '2px !important',
            backgroundColor: '#F3D2D6 !important',

            "& .PrivateValueLabel-label-34": {
                color: '#C12030 !important',
                transform: 'rotate(0deg) !important',
            }
        }
    }
});


const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#C12030",
                height: 14,
                width: 14,
                '@media(max-width: 810px)': {
                    top: 20
                },
                '@media(height: 760px)': {
                    top: 14
                },
                top: 14,
                "&$disabled": {
                    color: "#C12030",
                    height: 14,
                    width: 14,
                    top: 14,
                    '@media(max-width: 810px)': {
                        top: 20
                    },
                    '@media(height: 760px)': {
                        top: 14
                    },
                    marginTop: -5,
                    marginLeft: -6,
                },
            },
            disabled: {},
            track: {
                color: 'red',
                height: 7,
                borderRadius: 10
            },
            rail: {
                color: 'pink',
                height: 7,
                borderRadius: 10
            },

        },
    }
});

const InvtCustomTooltip = withStyles({
    tooltip: {
        backgroundColor: '#F3D2D6', // Change this to the color you desire
        margin: "5px 0"
    },
})(Tooltip);

const LoanValueInput = styled("input")({
    '&::-webkit-outer-spin-button': {
        WebkitAppearance: "none",
        margin: 0
    },
    '&::-webkit-inner-spin-button': {
        WebkitAppearance: "none",
        margin: 0
    },
    '&[type=number]': {
        MozAppearance: "textfield"
    }
})

export default class LoanCalculator extends Cfloanapplication2Controller {
    constructor(props: Props) {
        super(props);
    }

    ValueLabelComponent(props: any) {
        const { children, open, value } = props;
        return (
            <InvtCustomTooltip
                open={open}
                interactive
                enterTouchDelay={0}
                title={value}
                id="dateModal"
                className="dateModal"
                data-test-id="valueLabelComponent"
            >
                {children}
            </InvtCustomTooltip>
        );
    }

    formatLabel(text: any, values: any) {
        return (
            <div style={{ textAlign: "center", color: '#C12030', fontWeight: 400, fontSize: '12px',fontFamily:"poppins" }} id='dateModal' className="dateModal">
                {values} Months
            </div>
        );
    }

    rangeSlider = (text: any, values: number[], handleChange: (event: any, newValue: any | any[]) => void, max: number) => {
        return (
            <ThemeProvider theme={muiTheme}>
                <RangeBubbleContainer
                    id='dateModal'
                    className="dateModal"
                    value={values}
                    ValueLabelComponent={this.ValueLabelComponent}
                    onChange={handleChange}
                    min={0}
                    aria-labelledby="discrete-slider-always"
                    valueLabelDisplay='on'
                    max={max}
                    valueLabelFormat={this.formatLabel.bind(this, text)}
                    disabled={!this.state.setLoanAmount}
                    data-test-id="rangeSlider"
                />
            </ThemeProvider>
        );
    }

    PrettoSlider = withStyles({
        root: {
            color: "#cc2f2f",
            height: 20,
            width: "435px"
        },
        thumb: {
            height: 14,
            width: 14,
            backgroundColor: "#cc2f2f",
            border: "2px solid currentColor",
            marginTop: -6,
            marginLeft: -8,
            "&:focus, &:hover, &$active": {
                boxShadow: "inherit",
            },
        },
        active: {},
        disabled: {},
        valueLabel: {
            left: "calc(-50% + 4px)",
        },
        track: {
            borderRadius: 4,
        },
        rail: {
            borderRadius: 4,
        },
    })(Slider);

    render() {
        return (
            // Customizable Area Start
            <Box style={webStyle.userContainerSetting} data-test-id='user-setting-container'>
                <SideNavbar />
                <RightSideBox style={webStyle.containerSecond}>
                    <Box style={{ width: '100%' }}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ margin: "0px 40px", marginBottom: "20px" }}>
                        <CustomBackBox >
                            <img src={leftArrowImg} onClick={this.goBackLoanCalc} style={{ width: '24px', height: '24px', cursor: 'pointer',marginRight:'10px'}} />
                            <Typography onClick={this.goBackLoanCalc} style={{ color: '#000', fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, cursor: 'pointer' }}>Back</Typography>
                        </CustomBackBox>
                        <CustomUserBoxNew style={webStyle.userSettingMainFields}>
                            <CustomWalletTypography1 style={webStyle.userSettingText as React.CSSProperties}>
                                How to Qualify
                            </CustomWalletTypography1>

                            <CustomWalletTypography2 style={webStyle.userSettingText as React.CSSProperties}>
                                Business is older than 12 months  |  RSA Citizen  |   Min Loan Duration 6 Months  |   A2Pay Membership min 4 months
                            </CustomWalletTypography2>

                        </CustomUserBoxNew>
                        <CustomUserBoxNew2 style={{ backgroundColor: "white", display: "flex", justifyContent: "center"}}>

                            <Box style={{ maxWidth: "660px", display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center" }}>

                                <LoanAmountBox>
                                    <Box sx={webStyle.labelBox}>
                                        <label style={webStyle.labelStyleTwo as React.CSSProperties}>Loan Amount</label>
                                        <LoanValueInput
                                            data-test-id="loanAmountTestID"
                                            type="text"
                                            name="loanInput"
                                            id="loanInput"
                                            placeholder="Enter loan amount"
                                            value={this.state.setLoanAmount}
                                            onChange={this.handleLoanAmount}
                                            style={{
                                                ...webStyle.inputStyle, color: this.state.inputColor, fontFamily: "Poppins", fontWeight: 600, border: "1px solid #273567", appearance: "textfield", // for non-WebKit browsers
                                                MozAppearance: "textfield", // for Firefox
                                                WebkitAppearance: "none" // for WebKit browsers
                                            }}
                                        />
                                    </Box>

                                </LoanAmountBox>

                                <LoanAmountBox>
                                    <RepayTermBox>
                                        <Box>
                                            <label style={webStyle.labelStyleThree as React.CSSProperties}>Repayment Term</label>
                                        </Box>
                                    </RepayTermBox>
                                    {this.rangeSlider("", this.state.rePayTerm, this.handleTermSelectOperator, 24)}

                                    <HelpText1>Results based on the data entered above</HelpText1>
                                </LoanAmountBox>
                                <Box sx={webStyle.labelBoxDailyRepay}>
                                    <label style={webStyle.labelStyleFour as React.CSSProperties}>Daily Repayment</label>
                                    <div style={{ border: "1px solid rgb(39, 53, 103)", padding: "10px", borderRadius: "10px", fontFamily: "Poppins", fontSize: "17px", textAlign: "center" }}>{!this.state.setDailyRepay || this.state.setDailyRepay == Infinity ? "XX" : <Typography style={{ color: "#C12030", fontFamily: "Poppins", fontWeight: 600 }}>R{Number(this.state.setDailyRepay).toLocaleString("en-IN")}</Typography>}</div>
                                </Box>

                                <HelpText2>+ R.{this.state.setDailyRepay ? "60" : "XX"} per month service fee</HelpText2>

                                <Button data-test-id="applyButtonTestID" style={webStyle.nextButton2 as React.CSSProperties} onClick={this.LoanAmount} >Apply</Button>


                            </Box>

                        </CustomUserBoxNew2>
                    </Box>
                    <Toaster data-test-id="toasterTestID" position="top-center" />

                </RightSideBox>
            </Box>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    userContainerSetting: {
        backgroundColor: "#E2E8F0"
    },
    containerSecond:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,
    labelBox: {
        width: "100%",
        maxWidth: "435px",
    },
    labelBoxDailyRepay: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        maxWidth: "435px",
    },
    labelStyleTwo: {
        color: "#273567",
        fontFamily: 'Poppins',
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontSize: "20px",
        fontWeight: "600",
    },
    labelStyleThree: {
        color: "#273567",
        fontFamily: 'Poppins',
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontSize: "20px",
        fontWeight: "600",
    },
    labelStyleFour: {
        color: "#273567",
        fontFamily: 'Poppins',
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontSize: "20px",
        fontWeight: "600",
        marginBottom: "20px"

    },
    inputStyle: {
        top: '26px',
        border: '1px solid',
        borderRadius: '8px',
        padding: 15,
        height: '50px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '17px',
        fontWeight: 500,
        marginTop: "20px",
        width: "100%",
        textAlign: "center"
    } as React.CSSProperties,
    nextButton2: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: 'Poppins',
        marginTop: "40px",
        maxWidth: "440px"

    },
    userSettingMainFields: {
    },

    userSettingText: {

    },

    exportTxt: {

        fontFamily: 'Poppins',
        fontSixe: '16px',
        fontWeigth: '400',
        color: '#C12030',
        textAlign: 'right',
        textTransform: 'capitalize',
    } as React.CSSProperties,
    exportbtn: {

    } as React.CSSProperties,
    rangeFilterDivMain: {
        width: '222px',
        justifyContent: 'space-between',
        display: 'flex',
    },
    filterStyleText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',

    },
    textZero: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white',
    },
    rangeFilterMainDiv: {
        justifyContent: 'space-between',
        display: 'flex',
    },
    filterTextStyle: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: '#0F172A',
    },
    filterNoValue: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        color: 'white'
    },
}
// Customizable Area End
