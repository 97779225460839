import React from "react";

import {
  Box,
  Typography,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start


const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const CustomBox = styled(Box)({
  display: "flex",
  marginTop:50,
  '@media(max-width: 600px)': {
    display: "unset",
    marginTop:0,
  }
})
const CustomImage = styled("img")({
  cursor: 'pointer',
  maxWidth:'450px',
  width:'100%',
  '@media(max-width: 600px)': {
    maxWidth:'350px',
    width:'100%',
    display:'flex',
    margin:'auto',
    marginTop:24
  }
})
const CustomImage2 = styled("img")({
  cursor: 'pointer',
  maxWidth:'450px',
  width:'100%',
  marginLeft: '24px',
  '@media(max-width: 600px)': {
    maxWidth:'350px',
    width:'100%',
    display:'flex',
    margin:'auto',
    marginTop:24
  }
})
const CustomImage3 = styled("img")({
  cursor: 'pointer',
  maxWidth:'450px',
  width:'100%',
  '@media(max-width: 600px)': {
    maxWidth:'350px',
    width:'100%',
    display:'flex',
    margin:'auto',
    marginTop:24
  }
})
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
  } from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import {redwallet,whitewallet,redcommision,whitecommision,redpaybills,whitepaybills} from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class WalletTransact extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { selected } = this.state;
    console.log("this.state.setImage",this.state.setImage)
    return (
      // Customizable Area Start

      <Box>
       <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                    <Box style={{ width: '100%'}}>
                    <CommonDrawer  anchor={"right"} style={{width:'100%'}}  />
                    </Box>   
                    <Box>
        <CustomBox>
          
          <CustomImage src={this.state.setImage ===  true ? redwallet : whitewallet} onMouseEnter={this.handleChangeImage} onMouseLeave={this.handleChangeImage} data-test-id="redwallet" onClick={() => this.handleClick('redwallet')} />
          
          <CustomImage2 src={this.state.setCommissionImage ===  true ? redcommision : whitecommision} onMouseEnter={this.handleChangeImageCommission} onMouseLeave={this.handleChangeImageCommission} data-test-id="redcommision" onClick={() => this.handleClick('redcommision')} />
        </CustomBox>
        <Box style={{ marginTop: '24px' }}>
          <CustomImage3 src={this.state.setPayBillImage ===  true ? redpaybills : whitepaybills} onMouseEnter={this.handlePayBillChangeImage} onMouseLeave={this.handlePayBillChangeImage} data-test-id="PayBillToStore" onClick={() => this.handleClick('PayBillToStore')} />
        </Box>
      </Box>      
          </RightBoxLanding2>
        </Box>
  
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  secondContainer:
  {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
  } as React.CSSProperties,
  mainContainer: {
    display: "flex",
    flexDirection: "column", // This will stack the child elements vertically
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "1173px",
    width: '100%',
    height: "851px",
    backgroundColor: '#fff'
  } as React.CSSProperties,
 
};
// Customizable Area End
