import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CardContent,
  Card,
  FormControlLabel,
  RadioGroup,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import StyleRadio from '../../../components/src/StyleRadio.web';
import { background_image, a2payImage } from './assets';
// Customizable Area End

import SignuploginController, {
  Props
} from "./SignuploginController";


const TimeTypography = styled(Typography)({
  '@media (max-width: 600px)': {
    fontSize: "20px !important"
  },
  '@media (max-width: 810px)': {
    fontSize: "18px !important"
  }
})
const SubTimeTypography = styled(Typography)({
  '@media (max-width: 600px)': {
    fontSize: "12px !important"
  },
  '@media (max-width: 810px)': {
    fontSize: "12px !important"
  }
})

const LogoImage = styled('img')({
  height:'100%',
  '@media (min-width: 600px) and (max-width: 960px)': {
    objectFit: 'fill',
    height: '500px ',
    width: '100%',
  },
  '@media (max-width: 600px)': {
    width:'100%',
    height: '400px ',
    objectFit: 'fill',
  },
})

const MainImage = styled('img')({
  '@media (max-width: 600px)': {
    left: 'unset !important',
    top: '250px !important'
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    left: 'unset !important',
    top: '250px !important'
  },
})
const ContainerImg = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  height: '100%',
  position:'fixed',
  width:'50%',
  left:0,
  '@media (min-width: 600px) and (max-width: 960px)': {
    height: '500px ',
    width: '100%',
    position:'relative',
    marginBottom:'20px',
  },

  '@media (max-width: 600px)': {
    marginBottom:'20px',
    height: '400px ',
    position:'relative',
    width:'100%'
  },
})

const FieldGrid = styled(Grid)({

  '@media (max-width: 810px)': {
    padding: "0 20% !important"
  },

})

const NewUserCard = styled(Card)({

  '@media (max-width: 1024px) ': {
    width: "170px"
  },

})

const ExistingUserCard = styled(Card)({

  '@media (max-width: 1024px)': {
    width: "170px"
  },

})

const MainContainer = styled(Grid)({

})

export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <MainContainer container justifyContent="flex-end" style={{  height: "100vh" }}>
        <ContainerImg item xs={12}
            sm={12} lg={6}
            md={6}
        >
          <MainImage src={a2payImage} style={{ width: '203px', height: '99px', position: 'absolute', top:50, left: 242 }} />
          <LogoImage src={background_image} style={{ width: '100%' }} />

        </ContainerImg>

        <FieldGrid style={{ padding: '0px 60px', width: "100%" }} item xl={6} lg={6} md={6} xs={12} sm={12}>
          <Box style={webStyle.rightMain as React.CSSProperties}>
            <Box style={{ width: "100%" }}>
              <Box >
                <TimeTypography style={webStyle.welcomeText as React.CSSProperties}>Welcome</TimeTypography>
                <SubTimeTypography variant="body1" style={webStyle.merchantText as React.CSSProperties}>
                  Please select if you are a new user or existing user to continue
                </SubTimeTypography>
              </Box>
              <Box style={{ display: 'flex', gap: 20, flexWrap: "wrap", width: '100%' }}>
                <Box style={{ width: '100%', maxWidth: "220px" }} onClick={this.handleNewUserChange}>
                  <NewUserCard style={{ position: 'relative', width: '100%', height: '180px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)', border: this.state.isNewUserChecked ? '2px solid #C12030' : '', borderRadius: '8px' }}>
                    <Box style={webStyle.cardBox as React.CSSProperties}>
                      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                        <FormControlLabel value="female" control={<StyleRadio checked={this.state.isNewUserChecked} />} label="" />
                      </RadioGroup>
                    </Box>
                    <CardContent style={webStyle.cardContent as React.CSSProperties}>
                      <Typography style={{ color: this.state.isNewUserChecked ? '#C12030' : 'black', fontSize: '20px', fontWeight: 500, fontFamily: 'Poppins' } as React.CSSProperties}>New User</Typography>
                    </CardContent>
                  </NewUserCard>
                </Box>
                <Box style={{ width: '100%', maxWidth: "220px" }} onClick={this.handleExistingUserChange}>
                  <ExistingUserCard style={{ position: 'relative', width: '100%', height: '180px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)', border: this.state.isExistingUserChecked ? '2px solid #C12030' : '', borderRadius: '8px' }}>
                    <Box style={webStyle.cardBox as React.CSSProperties}>
                      <RadioGroup defaultValue="female" aria-label="gender" name="customized-radios">
                        <FormControlLabel value="female" control={<StyleRadio checked={this.state.isExistingUserChecked} />} label="" />
                      </RadioGroup>
                    </Box>
                    <CardContent style={webStyle.cardContent as React.CSSProperties}>
                      <Typography style={{ color: this.state.isExistingUserChecked ? '#C12030' : 'black', fontSize: '20px', fontWeight: 500, fontFamily: 'Poppins' } as React.CSSProperties}>Existing User</Typography>

                    </CardContent>
                  </ExistingUserCard>
                </Box>
              </Box>
              <Button style={webStyle.nextButton as React.CSSProperties} onClick={this.handleNextButtonClick}>Next</Button>
            </Box>
          </Box>
        </FieldGrid>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  welcomeText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '42px'
  },
  merchantText: {
    fontSize: '14px',
    color: '#848FAC',
    fontWeight: '400',
    fontFamily: 'Poppins',
    lineHeight: '21px',
    marginBottom: 35
  },
  nextButton: {
    backgroundColor: '#C12030',
    fontSize: '16px',
    marginTop: '16px',
    color: '#fff',
    width: '100%',
    height: '50px',
    maxWidth: "486px",
    borderRadius: '8px',
    fontWeight: '400',
    textTransform: 'capitalize',
    top: 31,
    fontFamily: 'Poppins'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    cursor: 'pointer'
  },
  cardBox: {
    position: 'absolute',
    top: '8px',
    right: '-10px',
    justifyContent: 'center'
  },
  rightMain: {
    height: "100%",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: "6%"
  },


};
// Customizable Area End
