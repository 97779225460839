import React from "react";

import {
  Box,
  Typography,
  styled,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { leftArrow, bagIcon } from './assets'
// Customizable Area Start


const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

const CustomBox = styled(Box)({
  display: 'flex',
  marginTop: '100px',
  '@media(max-width: 600px)': {
    display:'block',
    marginTop: '20px'
  }
})

const WhiteBox = styled(Box)({
  maxWidth: '969px',
  marginLeft: '54px',
  '@media(max-width: 600px)': {
    maxWidth: '400px',
    marginLeft: '0px'
  }
})
const BuxBox = styled(Box)({
  marginLeft: '20px',
  '&:hover': {
    cursor: 'pointer' ,
    border:  '2px solid #C12030 !important',

  },
  '@media(max-width: 600px)': {
    marginLeft: '0px',
    marginTop:24
  }
})

const CustomTypography = styled(Typography)({
  paddingLeft: '54px',
  '@media(max-width: 600px)': {
    paddingLeft: '20px'
  }
})

const WalletHoverEffect = styled(Box)({
  '&:hover': {
    cursor: 'pointer' ,
    border:  '2px solid #C12030 !important',

  }
})
const WalletTop = styled(Box)({
  '@media(max-width: 992px)': {
    marginTop: "0 !important"
  }
})

const WalletText = styled(Typography)({
  '@media(max-width: 900px)': {
    fontSize: "16px !important"
  }
})
// Customizable Area End

import Cfwallet53Controller, {
  Props,
  configJSON,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class WalletChooseBank extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { selectedTabBank } = this.state;

    return (
      // Customizable Area Start

      <Box>
        <SideNavbar />
        <RightBoxLanding2 style={webStyle.secondContainer}>
        <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Wallet</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </Box>
          <WhiteBox style={{ backgroundColor: '#fff', marginTop: '20px', padding: '30px' }}>
            <Box style={{ display: 'flex', maxWidth:"100px" }} onClick={this.goBack}>
              <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
              <Typography style={webStyle.backIcon}>Back</Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' } as React.CSSProperties}>
              <Typography style={webStyle.chooseBank as React.CSSProperties}>Choose Bank</Typography>
            </Box>
            <CustomBox>
              <WalletHoverEffect style={{
                ...webStyle.BoxContainer,
              }}
              data-test-id="walletTop"
              onClick={() => this.handleSelect('walletTopUp')}>
                <img src={bagIcon} style={{ width: '24px', height: '24px' }} />
                <Typography style={webStyle.WalletTopText}>Wallet Top-up</Typography>
              </WalletHoverEffect>
              <BuxBox style={{
                ...webStyle.BoxContainer,
              
              }}
              data-test-id="buxTop"
              onClick={() => this.handleSelect('bux')}>
                <img src={bagIcon} style={{ width: '24px', height: '24px'}} />
                <Typography style={webStyle.WalletTopText}>BUX</Typography>
              </BuxBox>
              <BuxBox style={{
                ...webStyle.BoxContainer,
                border: selectedTabBank === 'transfer' ? '1px solid #C12030' : '1px solid #848FAC',cursor: 'pointer'
              }}
              data-test-id="transferTop"
              onClick={() => this.handleSelect('transfer')}>
                <img src={bagIcon} style={{ width: '24px', height: '24px'}} />
                <Typography style={webStyle.WalletTopText}>Transfer to Other Wallets</Typography>
              </BuxBox>
            </CustomBox>

          </WhiteBox>

        </RightBoxLanding2>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  secondContainer:
    {
      backgroundColor: "#E2E8F0",
      // height: '150vh'
    } as React.CSSProperties,

  BoxContainer: {
    borderRadius: '12px',
    border: '1px solid #848FAC',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    maxWidth: '283px',
    padding: '16px',
    width: '100%',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  } as React.CSSProperties,
  WalletTopText:
  {
    marginTop: '8px',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Inter',
    display:'block',
    justifyContent:'center'
  },
  chooseBank: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    justifyContent: 'center'
  },
  backIcon: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    paddingLeft: 8,
    cursor: 'pointer'
  },
  HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
