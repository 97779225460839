import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { getStorageData } from "../../../framework/src/Utilities";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { arrow_back, leftArrow, } from "./assets";
const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const CustomTypography = styled(Typography)({
    paddingLeft: '54px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "757px",
    width: '100%',
    height: "542px",
    paddingTop: "30px",
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto',
        height: "500px",
    }
})
const CustomButton = styled(Button)({
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: 100,
    color: "#fff",
    width: "100%", // Full width on all devices
    height: "50px", // Adjust height as needed
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    '@media(max-width: 600px)': {
        marginTop: 30,
    }
})
const WalletTop = styled(Box)({
    '@media(max-width: 992px)': {
      marginTop: "0 !important"
    }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
      fontSize: "16px !important"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";

export default class CommisionSecond extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        let amount = await getStorageData('amount')
        let selectStore = await getStorageData('selectStore')
        this.setState({ getAmount: amount, selectedStore: selectStore })

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Commission</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </Box>
                    <CustomBox>
                        <Box style={{ display: 'flex', marginLeft: 20 }} >
                            <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} onClick={() => this.gotoCommision()}  data-test-id={"backArrow"}/>
                            <Typography style={webStyle.backIcon} onClick={() => this.gotoCommision()}  data-test-id={"backArrowText"}>Back</Typography>
                        </Box>
                        <Box style={webStyle.cardStyle}>
                            <Typography style={webStyle.textOne as React.CSSProperties}>{this.state.selectedStore}  </Typography>
                            <Typography style={webStyle.subText as React.CSSProperties}>Adding R{this.state.getAmount} to {this.state.selectedStore}'s Wallet</Typography>
                            <Typography style={webStyle.amountStyle as React.CSSProperties}>R {localStorage.getItem('amount')}</Typography>
                                <CustomButton style={webStyle.payButton as React.CSSProperties} data-test-id={'btnProceedToPay'}  onClick={() => this.handlePay()}>
                                    Pay
                                </CustomButton>
                        </Box>
                    </CustomBox>

                </RightBoxLanding2>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    secondContainer:
        {
            backgroundColor: "#E2E8F0",
            // height: '150vh'
        } as React.CSSProperties,

    BoxContainer: {
        borderRadius: '12px',
        border: '1px solid #848FAC',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
        maxWidth: '283px',
        padding: '16px',
        width: '100%',
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    } as React.CSSProperties,
    WalletTopText:
    {
        marginTop: '8px',
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        display: 'block',
        justifyContent: 'center'
    },
    chooseBank: {
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        justifyContent: 'center'
    },
    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },
    cardStyle: {
        maxWidth: "630px",
        height: "378px",
        top: "282px",
        borderRadius: '12px',
        marginBottom: '20px',
        marginTop: '25px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginLeft: '30px',
        marginRight: '30px',
        paddingTop: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    },
    textOne: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        textAlign: 'center',
        marginTop: 15
    },
    subText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'center'
    },
    amountStyle: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        marginTop: 100,
        textAlign: 'center'
    },
    payButton: {
        backgroundColor: "#C12030",
    },
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
