import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    styled,
    Modal
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { upload, accepted } from './assets';
// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import SideNavbar from "../../../components/src/SideBar.web";
import { Toaster } from "react-hot-toast";


const TimeTypograph = styled(Typography)({
    '@media (max-width: 600px)': {
      fontSize:"16px !important"
    }
  })

  const TimeTypographSta = styled(Typography)({
    '@media (max-width: 600px)': {
      fontSize:"16px !important"
    }
  })

  const StyledFileInputDoc = styled("input")({
    border: "none",
    outline: "none",
    '::placeholder': {
        fontSize: '14px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        marginTop: '10px'
    }

})

  export default class UserProfileOperatorDocument extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    componentDidMount = async () => {
        this.handleGetUserOperatorDocument()
       }
    render() {
        return (
            // Customizable Area Start
           
                 <Box>
                <Box style={webStyle.userSettingContainer as React.CSSProperties}>
                    <Box style={webStyle.userSettingFeildsMian as React.CSSProperties}>
                        <Box style={webStyle.userSettingTextMain as React.CSSProperties}>
                            <TimeTypograph style={webStyle.userSettingText as React.CSSProperties}>User Setting</TimeTypograph>
                            <TimeTypographSta style={webStyle.userSettingText as React.CSSProperties}>Status</TimeTypographSta>
                        </Box>
                        <Box style={{ ...webStyle.fieldsMain } as React.CSSProperties}>
                            <Box style={webStyle.userSettingTextMain2 as React.CSSProperties}>
                                <label style={webStyle.labelStyle as React.CSSProperties}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <span style={{width:'max-content'}}>ID/Passport</span>
                                        {this.state.uploadedFileName1 && (
                                            <>
                                                <img
                                                    src={accepted} 
                                                    alt="checkbox"
                                                    style={{ width: '16px', height: '16px', marginLeft: 10 }} />

                                            </>
                                        )}
                                    </div>
                                </label>
                                <Box style={{ marginTop: 40 }}>
                                    {this.state.uploadedFileName1 && (
                                        <span style={{ ...webStyle.rejectedText, color: 'green', marginLeft: '10px', marginRight: '5px',fontSize:'16px' }}>In-Progress</span>
                                    )}

                                </Box>
                            </Box>

                            <div>
                                <div
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: '5px',
                                    }}
                                >
                                    <label htmlFor="vacc" style={{ display: 'block' }}>
                                        <div
                                            style={{
                                                ...webStyle.inputStyle,
                                                border:this.state.errorMessages.field1 ? "1px solid red" : "1px solid #273567",
                                                position: 'relative', 
                                            }}
                                        >
                                            {this.state.uploadedFileName1 ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        width: '95%',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            marginLeft: '5px',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            color: '#273567',
                                                        }}
                                                    >
                                                        {this.state.uploadedFileName1}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={webStyle.style16}>
                                                    <span
                                                        style={{
                                                            marginLeft: '5px',
                                                            fontFamily: 'Poppins',
                                                            fontSize: '14px',
                                                            fontWeight: 400,
                                                            color: '#273567',
                                                        }}
                                                    >
                                                        Upload Document
                                                    </span>
                                                </div>
                                            )}
                                            <StyledFileInputDoc
                                            type="file"
                                            id="vacc"
                                            style={{
                                                width: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                height: '100%',
                                                cursor: 'pointer',
                                                opacity: 0,
                                            }}
                                            data-test-id="testoperator"
                                            onChange={(e:any) => this.handleMultipleFileChangeVac(1, e)}
                                    onBlur={this.handleFileBlur}
                                        />
                                        </div>
                                    </label>
                                </div>
                                <div style={webStyle.validationMessage}>
                            { this.state.errorMessages.field1}
                            </div>
                               
                            </div>
                        </Box>
                        <Toaster position="top-center"/> 
                    </Box>
                    <Modal
                        open={this.state.showUploadModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={{
                            backgroundColor: "black",
                            color: "white",
                            padding: "10px",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 300,
                            border: '2px solid #000',
                            textAlign: 'center',
                            borderRadius: "12px",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 500
                        }}>
                            Uploading.....
                        </Box>
                    </Modal>
                </Box></Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    userSettingContainer: {
        backgroundColor: "#E2E8F0",
        height:"100%"
    },
    userSettingFeildsMian: {
        backgroundColor: "#FFFFFF",
        maxWidth: "1000px",
        borderRadius: "4px",
        margin: "40px",
        padding: "20px",
    },
    userSettingTextMain: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    userSettingTextMain2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems:"baseline"
    },
    userSettingText: {
        fontFamily: 'Poppins',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '40px',
        fontSize: '20px'
    },
    fieldsMain: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    labelStyle: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
        marginTop: 30
    },
    inputStyle: {
        width: '100%',
        maxWidth:"550px",
        height: '40px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '8px 40px 8px 8px', 
        backgroundImage: `url(${upload})`,
        backgroundSize: '24px', 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center', 
    } as React.CSSProperties,
    cordinateBox: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
    captureIcon: {
        width: 'auto',
        height: '30px',
        right: '10',
        top: '30',
        position: 'absolute',
    },
    settingTabMain: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        margin: "20px"
    },
    activesettingTab: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"

    },
    activesettingTab2: {
        backgroundColor: 'rgba(193, 32, 48, 0.03)',
        padding: '15px',
        borderRadius: '16px',
        width: '100%',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        borderBottom: '2px solid #C12030',
    },
    settingTab: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer"
    },
    settingTab2: {
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "2px solid #848FAC"
    },
    activetabText: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    tabText: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
    checkboxText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
        marginTop: 16
    },
    radioMain: {
        display: 'flex',
        flexDirection: 'row',
        gap: 50
    },
    submitButton: {
        backgroundColor: '#C12030',
        fontSize: '16px',
        marginTop: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        fontFamily:'Poppins',
        fontWeight: 400,
    },
    rejectedText: {
        color: '#FF0404',
        fonstSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 500,
        alignSelf: 'flex-end',
        width:'max-content',
        display:'block'
    },
    imageStyle: {
        right: '8px',
        top: '50%',
        width: '24px',
        height: '24px', 
    } as React.CSSProperties,
    inputContainer: {
        position: 'relative',
        width: '100%',
    } as React.CSSProperties,
    validationMessage: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    } as React.CSSProperties,
    style16: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%"
    },
}
// Customizable Area End
