import React from "react";
import {
    Box,
    Typography,
    Button,
    Fade,
    Modal,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick } from "./assets"
import { Toaster } from "react-hot-toast"
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import OperatorHeader from "../../../components/src/OperatorHeader.web";

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const ModalBoxForgot = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

export default class AddOtherStoreOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    renderInputField3Opera(name: any, label: any) {
        let inputField;
        if (name === "contactDetail") {
            inputField = (
                <>
                    <input
                        data-test-id="contactDetail"
                        name={name}
                        value={this.state.contactDetail}
                        type="text"
                        onChange={this.handleContactDetailsChange}
                        style={{
                            ...webStyleOpera.inputStyle3Opera,
                            borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                        }}
                    />
                    {!this.state.isPhoneNumberValid && <div style={{ color: 'red', marginTop: '5px', fontFamily:"poppins",fontSize:"12px",fontWeight:400 }}>Invalid Phone Number</div>}
                </>
            );
        } else if (name === "emailAddress") {
            inputField = (
                <>
                    <input
                        data-test-id="emailAddress"
                        name={name}
                        type="email"
                        style={{
                            ...webStyleOpera.inputStyle3Opera,borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567', border: this.state.isEmailValid ? '1px solid #273567' : '1px solid red',
                        }}onChange={this.handleEmailChange}value={this.state.email}
                    />{this.state.errorMessage && 
                    <div 
                    style={{ 
                        color: 'red', marginTop: '5px', 
                        fontFamily:"poppins",
                        fontSize:"12px",fontWeight:400 }}>
                            {this.state.errorMessage}</div>}
                </>
            );
        } else {
            inputField = (<input type="text" data-test-id={name} name={name} 
                    style={{...webStyleOpera.inputStyle3Opera,
                        borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                    }}
                    onChange={this.handleInputChanges2}
                />
            );
        }

        return (
            <Box style={webStyleOpera.fieldsMainOtherOpera as React.CSSProperties}>
                <label style={webStyleOpera.labelStyle2Opera as React.CSSProperties}>{label}</label>
                {inputField}
            </Box>
        );
    }

    render() {
        return (
            <Box style={webStyleOpera.userSettingContainer2Opera}>
                <OperatorSideBar />
                <RightBoxLanding style={webStyleOpera.secondContainerOpera as React.CSSProperties}>
                    <Box style={webStyleOpera.drawerBoxOpera}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <OperatorHeader/>
                    <Box data-test-id="back" style={{ paddingLeft: 40, display: 'flex',maxWidth:"80px",cursor: 'pointer', marginTop:"25px"}} onClick={this.navigateToSettingsStoreOperator}>
                        <img src={leftArrow} style={{ width: '24px', height: '24px' }} />
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#000', fontWeight: 500, paddingLeft: 8,cursor:'pointer' }}>Back</Typography>
                    </Box>
                    <Box style={webStyleOpera.userSettingFeildsMianOpera}>
                        <Box style={webStyleOpera.userSettingTextMainOpera as React.CSSProperties}>
                            <Typography style={webStyleOpera.userSettingTextOpera as React.CSSProperties}>Add Other Store</Typography>
                        </Box>
                        {this.renderInputField3Opera("storename", "Store Name")}
                        {this.renderInputField3Opera("contactDetail", "Contact Details")}
                        {this.renderInputField3Opera("emailAddress", "Email Address")}
                        {this.renderInputField3Opera("nextOfKin", "Next of Kin")}
                        {this.renderInputField3Opera("storeAddress", "Store Address")}
                        <Box sx={webStyleOpera.cordinateBoxOpera}>
                            <label style={webStyleOpera.labelStyleOpera as React.CSSProperties}>Store coordinates</label>
                            <input
                                name="updateCoordinates"
                                type="text"
                                style={{
                                    borderColor: this.state.requiredInput.includes('updateCoordinates') ? 'red' : '#273567',
                                    ...webStyleOpera.inputStyleAddOpera,
                                }}
                                onChange={this.handleInputChanges2}
                            />
                            <img src={capture_icon} alt="Capture Icon" style={webStyleOpera.captureIconOpera as React.CSSProperties} />
                            {this.state.requiredInput.includes('updateCoordinates') && <div style={{ marginTop: '5px',color: 'red' }}>Store Coordinates is required</div>}
                        </Box>
                        {this.renderInputField3Opera("typeStore", "Type of Store")}
                        {this.renderInputField3Opera("cipro", "CIPRO Registration Status")}
                        {this.renderInputField3Opera("vatStatus", "VAT Status")}
                        {this.renderInputField3Opera("uifNumber", "UIF Number")}
                        {this.renderInputField3Opera("kycDocumentation", "KYC Documentation")}
                        <Button data-test-id="submit" onClick={()=>this.handleSubmit3(false)} style={webStyleOpera.submitButton2Opera as React.CSSProperties}>
                            Submitss
                        </Button>
                    </Box>
                    <Toaster position="top-center" />
                    <Modal
                        aria-labelledby="transition-modal-title"
                        open={this.state.tickModel}
                        closeAfterTransition
                        aria-describedby="transition-modal-description"
                        onClose={this.handleCloseModal2}
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.tickModel}>
                            <ModalBoxForgot sx={webStyleOpera.greentickModal2Opera}>
                                <img src={greentick} alt="Green Tick" style={{  width: '100%', height: '119px', marginBottom: '20px',maxWidth: '119px' }} />
                                <Typography variant="h5" style={webStyleOpera.userVerifiedText2Opera as React.CSSProperties}>Store has been sent to Admin for approval, Hang Tight!"</Typography>
                            </ModalBoxForgot>
                        </Fade>
                    </Modal>
                </RightBoxLanding>
            </Box>
        );
    }
}

const webStyleOpera = {
    userSettingContainer2Opera: {
        backgroundColor: "#E2E8F0"
    },
    userSettingFeildsMianOpera: {
        maxWidth: "610px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        margin: "40px",
        padding: "20px",
    },
    labelStyle2Opera: {
        fontFamily: 'Poppins',
        color: "#273567",
        letterSpacing: "0em",
        fontSize: "16px",
        lineHeight: " 24px",
        fontWeight: "400",
    },
    userSettingTextMainOpera: {
        marginBottom: "40px"
    },
    userSettingTextOpera: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '40px',
        color: '#000000',
        fontSize: '20px'
    },
    inputStyle3Opera: {
        color: '#273567',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        padding: "15px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    drawerBoxOpera: { width: '100%' } as React.CSSProperties,
    fieldsMainOtherOpera: {
        maxWidth: '100%',
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    inputStyleAddOpera: {
        color: '#273567',
        top: '26px',
        width: '100%',
        border: '1px solid',
        borderRadius: '8px',
        padding: "15px",
        fontFamily: 'Poppins',
        height: '40px',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    labelStyleOpera: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    cordinateBoxOpera: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
    captureIconOpera: {
        width: 'auto',
        height: '30px',
        right: '10',
        top: '30',
        position: 'absolute',
    },
    submitButton2Opera: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        textTransform: 'capitalize',
        height: '50px',
        borderRadius: '8px',
        width: "100%",
        fontFamily: 'Poppins',
    },
    secondContainerOpera: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: "#E2E8F0",
    },
    greentickModal2Opera: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2Opera: {
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        display: 'flex',
        textAlign: "center",
        color: '#030F2D',
        fontFamily: "Poppins",
        fontStyle: "normal"
    }
};
