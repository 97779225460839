import React from "react";

import {
    Box, Button, Card, CardContent, Fade, FormControl, MenuItem, Modal, Select, Typography, styled,
} from "@material-ui/core";
import Cfapplyforvirtualassistance2Controller, {
    Props
} from "./Cfapplyforvirtualassistance2Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { BackIcn, RightIcn } from './assets';
import { Toaster } from "react-hot-toast"



const LayoutMain = styled(Box)({
    height: "100%",
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const TitleText = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#334155',
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: '20px',
    alignSelf: 'center',
    padding: "20px 45px",
})

const ModalBoxForgot = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

const SubHeadingSelect = styled(Select)({
    outline: "none",
    boxShadow: "none",
    border: "none",
    "&:focus": {
        outline: "none", // Remove focus outline
        boxShadow: "none",
        border: "1px solid #273567",
    }
})



const maxLength = 1000;

export default class VirtualAssistance extends Cfapplyforvirtualassistance2Controller {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <LayoutMain style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                    <Box style={webStyle.LoanHeader}>

                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />

                    </Box>
                    <Box style={{ padding: "0 40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <Box style={{ cursor: "pointer", marginBottom: "30px", maxWidth: "80px" }} data-test-id="backLanding" onClick={this.handleBackAssit}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500, }}>
                                <img src={BackIcn} alt="back-icn" style={{ marginRight: "10px" }} />
                                Back
                            </Typography>
                        </Box>
                        <TitleText>
                            Virtual Assistance
                        </TitleText>
                        <Card style={{ padding: "40px 25px", margin: "30px 0", maxWidth: "600px" }}>
                            <CardContent style={{ maxWidth: "100%", display: "flex", gap: 35, flexDirection: "column", alignItems: "center", padding: "0" }}>
                                <FormControl variant="outlined" required style={{
                                    border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px", outline: "none",
                                }}>
                                    <div style={{ display: this.state.setHeading ? 'none' : 'block', position: "absolute", top: 17, left: 15, fontFamily: "Poppins", fontSize: "14px", color: "#273567", fontWeight: 400 }}>
                                        Heading
                                    </div>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue=""
                                        value={this.state.setHeading}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%', height: '55px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                            fontWeight: 400, borderRadius: '8px',
                                        }}
                                        onChange={this.handleSelectHeading}
                                    >
                                         {Object.keys(this.options).map((key) => (
                                         <MenuItem key={key} value={key} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>
                                            {key}
                                             </MenuItem>
                                             ))}
                                    </Select>

                                </FormControl>
                                <FormControl variant="outlined" required style={{
                                    border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px"
                                }}>
                                    <div style={{ display: this.state.setSubHeading ? 'none' : 'block', position: "absolute", top: 17, left: 15, fontFamily: "Poppins", fontSize: "14px", color: "#273567", fontWeight: 400 }}>
                                        Sub-Heading
                                    </div>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue=""
                                        value={this.state.setSubHeading}
                                        disabled={!this.state.setHeading}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%', height: '55px', fontFamily: "Poppins", fontSize: "14px", color: "#273567",
                                            fontWeight: 400, borderRadius: '8px',
                                        }}
                                        onChange={this.handleSelectSubHeading}
                                    >
                                         {this.state.setHeading && this.options[this.state.setHeading]?.map((subheading) => (
                                             <MenuItem key={subheading} value={subheading} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>
                                                {subheading}
                                                 </MenuItem>
                                                 ))}
                                    </Select>
                                </FormControl>
                                <Box style={webStyle.fieldsMain}>
                                    <label style={webStyle.labelStyle as React.CSSProperties}>Message</label>
                                    <textarea
                                        placeholder="Add your message here"
                                        value={this.state.setMessage}
                                        style={{ ...webStyle.inputStyleAdd, resize:"none",scrollbarWidth:"none",border: this.state.setMessage?.length >= maxLength ? '1px solid #F87171' : webStyle.inputStyleAdd.border, outline: "none" }} onChange={this.handleSetMessage} maxLength={1000} />
                                    {this.state.setMessage?.length >= maxLength ? <Typography style={{ paddingTop: "10px", color: "#FF0404", fontFamily: "Poppins", fontWeight: 400, fontSize: "12px" }}>*Word limit exceeded in input. Please shorten your text.</Typography> : ""}
                                </Box>
                                <Button style={webStyle.submitLoanButton} data-test-id="virtualassistance" onClick={this.virtualAssistance}>
                                    <Typography style={webStyle.submitTitleText as React.CSSProperties}>Submit</Typography>
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        closeAfterTransition
                        open={this.state.successModal}
                        onClose={this.handleCloseSuccessModal}
                        aria-describedby="transition-modal-description"
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.successModal}>
                            <ModalBoxForgot sx={webStyle.greentickModal}>
                                <img src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '119px', width: '100%', height: '119px' }} />
                                <Typography variant="h5" style={webStyle.userVerifiedText2 as React.CSSProperties}>Your request has been submitted successfully.</Typography>
                            </ModalBoxForgot>
                        </Fade>
                    </Modal>
                    <Toaster position="top-center" />
                </LayoutMain>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    LoanHeader: { width: '100%', backgroundColor: "rgb(226, 232, 240)", } as React.CSSProperties,
    maritalText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
    },
    maritalradioMain: {
        fontFamily: 'Poppins',
        marginLeft: "15px"
    },
    uploadInput: {
        color: '#273567',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        height: '50px',
        padding: "15px",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadInputDoc: {
        width: '100%',
        height: '50px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxSizing: 'border-box',
        padding: '8px 20px 8px 10px', // Adjust padding for text and image
        backgroundSize: '24px', // Adjust the size of the image
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center', // Adjust the position of the image
        borderColor: '#273567',
    } as React.CSSProperties,
    uploadText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    submitLoanButton: {
        maxWidth: '550px',
        width: '100%',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'C12030',
    },
    submitTitleText: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: '#fff'
    },
    greentickModal: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2: {
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    },
    fieldsMain: {
        width: "100%",
        maxWidth: "550px",
        display:"flex",
        flexDirection:"column"
    } as React.CSSProperties,
    labelStyle: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
        fontWeight: "400",
    },
    inputStyleAdd: {
        width: '100%',
        borderRadius: '8px',
        border: "1px solid #273567",
        padding: "15px",
        height: '200px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        color:"#273567",
        maxWidth:"550px",
    },
}
// Customizable Area End