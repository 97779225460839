import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Box, FormControl, Modal, Typography, styled } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { Link } from "react-router-dom";
export const downIcon = require("./downIcon.png");
export const CloseIcn = require('./CloseIcn.png');

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "unset",
  },
  "& .MuiSelect-icon": {
    display: "none", // Hide the arrow icon
  },
  "& .MuiSelect-selectMenu": {
    backgroundImage: `url(${downIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 8px center",
    paddingRight: "24px",
  },
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
  },
}));

const styles = {
  root: {
    backgroundColor: "#EBEDF3",
    justifyContent: "center",
    display: "flex",
  },
  formControl: {
    minWidth: "350px",
    width: "100%",
    padding: "10px 8px 10px 15px",
    height: "44px",
    borderBottom: "none",
    justifyContent: "center",
  },
  selectedMenuItem: {
    color: "red",
    backgroundColor: "none",
    // Remove the default background color
  },
  modalTextOpper:{
    fontSize:"13px",
    fontFamily:"Poppins",
    fontWeight:"600",
    lineHeight:"24px",
    marginTop:"10px"
},
buttonContainerOpper: {
  display: "flex",
  alignItems: "center",
  justifyContent:"flex-end",
  margin:"15px"
},
noButtonOpper: {
  borderRadius: "8px",
  border:"1px solid #848FAC",
  width: "100%",
  maxWidth: "105px",
  height: "40px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  cursor: "pointer",
  fontFamily: 'Poppins',
  fontWeight: 700,
  lineHeight: '24px',
  fontSize: '13px',
  color: "#848FAC",
  marginRight:"10px"
},
yesButtonOpper: {
  all:"unset",
  borderRadius: "8px",
  background: "#C12030",
  width: "100%",
  maxWidth: "105px",
  height: "40px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  cursor: "pointer",
  fontFamily: 'Poppins',
  fontWeight: 700,
  lineHeight: '24px',
  fontSize: '13px',
  color: "#ffffff",
},
};

const options = [
  { label: "User Settings", route: "/UserProfile" },
  { label: "Operator Settings", route: "/OperatorSettings" },
  { label: "Billing Settings", route: "/BillingSettings" },
  { label: "Store Settings", route: "/StoreSettings" },
  { label: "Logout", route: "" },
];

const SimpleListMenu = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [logoutModal, setlogoutModal] = React.useState(false);

 const confirmLogout = () => {
  setlogoutModal(true)
}
const handeCloseLogout = () => {
  setlogoutModal(false)
}

const handleRemoveRole = () => {
  localStorage.removeItem("Role")
}
  React.useEffect(() => {
    const foundIndex = options.findIndex(
      (option) => option.route === location.pathname
    );
    if (foundIndex !== -1) {
      setSelectedIndex(foundIndex);
    }
  }, [location]);

  const handleMenuItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
  };

  return (
    <div style={styles.root}>
      <FormControl style={styles.formControl}>
        <CustomSelect
          disableUnderline
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedIndex}
          onChange={(event) => handleMenuItemClick(event, event.target.value)}
          style={styles.selectedMenuItem}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            anchorReference: "anchorPosition",
            anchorPosition: {
              top: 150, // Adjust this value to position the dropdown menu below the input
              left: 0, // Center horizontally
            },
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.route}
              value={index}
              style={{ justifyContent:"left" }}
            >
             
                
             {option.label === "Logout" ? (
      <Box 
        onClick={confirmLogout}  
        style={{ cursor: "pointer",width:"100%",  color: "inherit", fontFamily: 'Poppins', fontWeight: 400 ,display:"block"}}
      >
        {option.label}
      </Box>
    ) : (
      <Link
        to={option.route}
        style={{ textDecoration: "none",width:"100%", color: "inherit", fontFamily: 'Poppins', fontWeight: 400 }}
      >
        {option.label}
      </Link>
    )}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
      
      <Modal
                        open={logoutModal}
                        onClose={handeCloseLogout}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={{
                            backgroundColor: "white",
                            padding: "10px",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            border: '2px solid white',
                            borderRadius: "8px 8px 32px 8px",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 500,
                            outline: "none",
                            width:"260px",
                            
                        }}>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <Box style={styles.modalTextOpper as React.CSSProperties}>
                                    Are you sure you want logout?
                                </Box>
                                <Box style={{cursor:"pointer",marginTop:"10px",marginRight:"10px"}} onClick={handeCloseLogout}>
                                    <img src={CloseIcn} alt="close-icn"/>
                                </Box>
                            </Box>
                            <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
                            <Box style={styles.buttonContainerOpper as React.CSSProperties}>
                                <Box onClick={handeCloseLogout} style={styles.noButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Cancel</Typography>
                                </Box>
                                <Link to="/Login" onClick={handleRemoveRole} style={styles.yesButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Logout</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Modal>
    </div>
  );
};
export default SimpleListMenu;
