import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Card,
    styled,
} from "@material-ui/core";
const RightBoxOperator = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    },
})
const CommonCardOprat = styled(Card)({
    padding: '30px',
    marginTop: '10px',
    alignItems: 'flex-start',
    borderRadius: '12px',
    '@media(max-width: 600px)': {

        alignItems: 'flex-start',
        padding: '10px',
        marginTop: '10px',
        borderRadius: '12px',
    },

    '@media (min-width: 600px) and (max-width: 992px)': {
        padding: '30px',
        alignItems: 'flex-start',
        marginTop: '10px',
        borderRadius: '12px',
    },
})

const CommonBoxOprt = styled(Box)({
    width: '100%',

    '@media(max-width: 600px)': {
        marginLeft: '0px',
    },

    '@media(max-width: 952px)': {
        width: '100%',
        marginLeft: '0px',
    },
})
// Customizable Area End

import NotificationsController, {
    Props
} from "./NotificationsController";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from "../../../components/src/CommonHeaderOperator.web";


export default class OperatorNotification extends NotificationsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: 'flex' }}>
                <OperatorSideBar />
                <RightBoxOperator style={webStyle.opratContainer}>
                    <Box style={{ width: '100%' }}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>


                    <CommonBoxOprt style={{ marginTop: '10px' }}>
                        <CommonCardOprat style={webStyle.cardStyleOprt}>
                            <Typography style={webStyle.headTextOprt}>Today</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprt}>Deliverer Today</Typography>
                                <Typography style={webStyle.subTextOprt}>10:30 PM</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOrderOprat}>Check Your Oders</Typography>
                                <Typography style={webStyle.subTextTimeOprat}>04:40 AM</Typography>
                            </Box>
                            <Typography style={webStyle.headTextOprt}>22 Sep 2023</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprt}>Deliverer Today</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOrderOprat}>See New Items</Typography>
                                <Typography style={webStyle.subTextTimeOprat}>Sep 22</Typography>
                            </Box>
                            <Typography style={webStyle.headTextOprt}>24 Sep 2023</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprat}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprat}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Typography style={webStyle.headTextTwoOprt}>24 Sep 2023</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextDeliveryOPratTwo}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprat}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Typography style={webStyle.headTextOprt}>24 Sep 2023</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprat}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextDeliveryOPratTwo}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextTwoOprt}>Sep 22</Typography>
                            </Box>
                            <Typography style={webStyle.headTextOprt}>24 Sep 2023</Typography>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextOprat}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                            <Box style={webStyle.firstBoxOprat}>
                                <Typography style={webStyle.subTextDeliveryOPratTwo}>Your Order Delivered</Typography>
                                <Typography style={webStyle.subTextOprt}>Sep 22</Typography>
                            </Box>
                        </CommonCardOprat>
                    </CommonBoxOprt>
                </RightBoxOperator>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    opratContainer:
        {
            backgroundColor: "#E2E8F0",
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 30,
            height: "100%",
        } as React.CSSProperties,
    cardStyleOprt: {
        // marginTop: '30px'
    } as React.CSSProperties,
    headTextOprt: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        marginTop:'15px',

    } as React.CSSProperties,
    headTextTwoOprt: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        marginTop:'15px',

    } as React.CSSProperties,
    subTextOprt: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#848FAC',
    } as React.CSSProperties,
    subTextTwoOprt: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#848FAC',
    } as React.CSSProperties,
    subTextOprat: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#0F172A',
        fontWeight: 500,
    } as React.CSSProperties,
    subTextDeliveryOPratTwo: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: '#0F172A',
        fontSize: '16px',
    } as React.CSSProperties,
    firstBoxOprat: {
        backgroundColor: 'rgba(132, 143, 172, 0.1)',
        padding: '8px',
        marginTop: '15px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: '8px',
    } as React.CSSProperties,
    subTextOrderOprat: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
    } as React.CSSProperties,
    subTextTimeOprat: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        color: '#273567',
        fontWeight: 500,
    }
};
// Customizable Area End