import React from "react";
import {
    Box,
    Typography,
    Button,
    Fade,
    Modal,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick } from "./assets"
import { Toaster } from "react-hot-toast"
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import OperatorHeader from "../../../components/src/OperatorHeader.web";

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const ModalBoxForgot = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

export default class StoreSettingDetailsOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    renderInputFieldOperato(name:any, label:any) {
        let inputField;
        if (name === "contactDetail") {
            inputField = (
                <>
                    <input
                        data-test-id="contactDetail"
                        name={name}
                        value={this.state.contactDetail}
                        type="text"
                        onChange={this.handleContactDetailsChange}
                        style={{
                            ...webStyleOperato.inputStyleOperato,
                            border: this.state.isPhoneNumberValid ? '1px solid #273567' : '1px solid red',
                        }}
                    />
                    {!this.state.isPhoneNumberValid && <div style={{ color: 'red', marginTop: '5px',fontFamily:"poppins",fontSize:"12px",fontWeight:400 }}>Invalid Phone Number</div>}
                </>
            );
        } else if (name === "emailAddress") {
            inputField = (
                <>
                    <input data-test-id="emailAddress" name={name} type="email" style={{...webStyleOperato.inputStyleOperato,borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',border: this.state.isEmailValid ? '1px solid #273567' : '1px solid red',}}
                        onChange={this.handleEmailChange}
                        value={this.state.email}
                    />
                    {this.state.errorMessage && <div 
                    style={{ color: 'red', marginTop: '5px',
                    fontFamily:"poppins",fontSize:"12px",fontWeight:400 }}>
                        {this.state.errorMessage}
                        </div>}
                </>
            );
        } else {
            inputField = (
                <input data-test-id={name}
                    name={name} type="text" style={{
                        ...webStyleOperato.inputStyleOperato,
                        borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                    }}
                    onChange={this.handleInputChanges2}
                />
            );
        }

        return (
            <Box style={webStyleOperato.fieldsMainOperato as React.CSSProperties}>
                <label style={webStyleOperato.labelStyle2Operato as React.CSSProperties}>{label}</label>
                {inputField}
            </Box>
        );
    }

    render() {
        return (
            <Box style={webStyleOperato.userSettingContainerOperato}>
                <OperatorSideBar />
                <RightBoxLanding style={webStyleOperato.secondContainerOperato as React.CSSProperties}>
                    <Box style={webStyleOperato.drawerBoxOperato }>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <OperatorHeader/>
                    <Box data-test-id="back" style={{ paddingLeft: 40, display: 'flex',maxWidth:"80px", marginTop:"25px" }} onClick={this.navigateToSettingsStoreOperator}>
                        <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                        <Typography style={{ fontFamily: 'Poppins', color: '#000', fontSize: '16px', fontWeight: 500, paddingLeft: 8,cursor:'pointer' }}>Back</Typography>
                    </Box>
                    <Box style={webStyleOperato.userSettingFeildsMian2Operato}>
                        <Box style={webStyleOperato.userSettingTextMainOperato}>
                            <Typography style={webStyleOperato.userSettingText2Operato as React.CSSProperties}>Store Setting</Typography>
                        </Box>
                        {this.renderInputFieldOperato("storename", "Store Name")}
                        {this.renderInputFieldOperato("storeref", "Store Ref Number")}
                        {this.renderInputFieldOperato("contactDetail", "Contact Details")}
                        {this.renderInputFieldOperato("emailAddress", "Email Address")}
                        {this.renderInputFieldOperato("nextOfKin", "Next of Kin")}
                        {this.renderInputFieldOperato("storeAddress", "Store Address")}
                        <Box sx={webStyleOperato.cordinateBoxOperato}>
                            <label style={webStyleOperato.labelStyleOperato as React.CSSProperties}>Store coordinates</label>
                            <input
                                name="updateCoordinates"
                                type="text"
                                style={{
                                    ...webStyleOperato.inputStyleAddOperato,
                                    borderColor: this.state.requiredInput.includes('updateCoordinates') ? 'red' : '#273567',
                                }}
                                onChange={this.handleInputChanges2}
                            />
                            <img src={capture_icon} alt="Capture Icon" style={webStyleOperato.captureIconOperato as React.CSSProperties} />
                            {this.state.requiredInput.includes('updateCoordinates') && <div style={{ color: 'red', marginTop: '5px' }}>Store Coordinates is required</div>}
                        </Box>
                        {this.renderInputFieldOperato("typeStore", "Type of Store")}
                        {this.renderInputFieldOperato("cipro", "CIPRO Registration Status")}
                        {this.renderInputFieldOperato("vatStatus", "VAT Status")}
                        {this.renderInputFieldOperato("uifNumber", "UIF Number")}
                        {this.renderInputFieldOperato("kycDocumentation", "KYC Documentation")}
                        <Button data-test-id="submitStore" onClick={this.handleSubmit2} style={webStyleOperato.submitButton2Operato as React.CSSProperties}>
                            Submit
                        </Button>
                    </Box>
                    <Toaster position="top-center" />
                    <Modal
                        aria-labelledby="transition-modal-title"
                        closeAfterTransition
                        onClose={this.handleCloseModal2}
                        open={this.state.tickModel}
                        aria-describedby="transition-modal-description"
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.tickModel}>
                            <ModalBoxForgot sx={webStyleOperato.greentickModal3Operato}>
                                <img src={greentick} alt="Green Tick" style={{ marginBottom: '20px', width: '100%', height: '119px', maxWidth: '119px' }} />
                                <Typography variant="h5" style={webStyleOperato.userVerifiedText2Operato as React.CSSProperties}>Store has been sent to Admin for approval, Hang Tight!"</Typography>
                            </ModalBoxForgot>
                        </Fade>
                    </Modal>
                </RightBoxLanding>
            </Box>
        );
    }
}

const webStyleOperato = {
    userSettingContainerOperato: {
        backgroundColor: "#E2E8F0"
    },
    userSettingFeildsMian2Operato: {
        maxWidth: "610px",
        borderRadius: "4px",
        backgroundColor: "#FFFFFF",
        padding: "20px",
        margin: "40px",
    },
    labelStyle2Operato: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        letterSpacing: "0em",
        lineHeight: " 24px",
        fontWeight: "400",
    },
    userSettingTextMainOperato: {
        marginBottom: "40px"
    },
    userSettingText2Operato: {
        fontWeight: '500',
        fontFamily: 'Poppins',
        color: '#000000',
        lineHeight: '40px',
        fontSize: '20px'
    },
    inputStyleOperato: {
        color: '#273567',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        top: '26px',
        height: '40px',
        fontFamily: 'Poppins',
        padding: "15px",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    drawerBoxOperato:{ width: '100%' }as React.CSSProperties,
    fieldsMainOperato: {
        maxWidth: '100%',
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    inputStyleAddOperato: {
        color: '#273567',
        top: '26px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        padding: "15px",
        fontFamily: 'Poppins',
        height: '40px',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    labelStyleOperato: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        letterSpacing: "0em",
        lineHeight: " 24px",
        fontWeight: "400",
    },
    cordinateBoxOperato: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    captureIconOperato: {
        right: '10',
        width: 'auto',
        height: '30px',
        top: '30',
        position: 'absolute',
    },
    submitButton2Operato: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        color: '#fff',
        fontSize: '16px',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        fontFamily: 'Poppins',
    },
    secondContainerOperato: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#E2E8F0",
    },
    greentickModal3Operato: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        marginTop: '100px',
        margin: 'auto',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2Operato: {
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        fontWeight: 400,
        alignContent: 'center',
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    }
};
