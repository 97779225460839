import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast"

const notify = () => toast.error('Fill in all your details because all the fields are manadatory', {
  style: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
    maxWidth: "100%",
    display: "flex",
    alignItems: "baseline"
  },
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedValue: string;
  isNewUserChecked: boolean;
  isExistingUserChecked: boolean;

  complianceReport: any;
  municipalPermit: any;
  registeredBusiness: any;
  vatRegistration: any;
  showPassword: boolean;
  confirmPassword: any;
  isModalOpen: boolean;
  tickModel: boolean;
  otp: string[];
  email: string;
  errorMessage: string;
  password: any;
  confirmPasswords: any;
  errors: any;
  passwordMatch: any;
  userNameValid: any;
  passwordLength: any;
  registeredBusiness2: any;
  vatRegistration2: any;
  firstName: any;
  isError: any;
  showYourPassword: any;
  oldpassword: any;
  sameoldpass: any;
  samepass: any;
  MesErrProfile: typeof configJSON.MesErrProfile;
  error: any;
  errorColor: any;
  lastName: any;
  seconds: number;
  timeout: boolean;
  birthday: string;
  userName: any;
  userNameError: any;
  passportId: any;
  isValid: any;
  phoneNumber: any;
  errorPhoneMessage: any;
  errorAlternatePhoneMessage: any;
  isValidNumber: any;
  whatsappNumber: any;
  isWhatsappNumber: any;
  isValidAlternateNumber: any;
  firstNameError: any;
  invalidInputs: any;
  citizenStatus: any;
  storeName: any;
  postion: any;
  passportIdNumberId: any;
  coordinates: any;
  mobileNumber: any;
  sex: any;
  ethinicity: any;
  addressline1: any;
  addressline2: any;
  emailAddress: any;
  alternateNumber: any;
  dob: any;
  state: any;
  country: any;
  conpassvalid: any;
  coordinateslatlong: any;
  getcoordinates: string;
  otpError:boolean;
  passportError:boolean;
  passportErrorInvalid:boolean;
  errorMessagePassID:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SignuploginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private timerInterval: NodeJS.Timeout | null = null;
  apiEmailLoginCallId: string = "";
  emailReg: RegExp;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedValue: 'option1',
      isNewUserChecked: false,
      isExistingUserChecked: false,
      complianceReport: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      municipalPermit: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      registeredBusiness: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      vatRegistration: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      showPassword: false,
      confirmPassword: false,
      isModalOpen: false,
      otp: ['', '', '', ''],
      tickModel: false,
      email: '',
      errorMessage: '',
      errorPhoneMessage: '',
      errorAlternatePhoneMessage: '',
      password: '',
      confirmPasswords: '',
      errors: {
        password: '',
        confirmPasswords: ''

      },
      firstName: '',
      passwordMatch: true,
      userNameValid: true,
      passwordLength: true,
      registeredBusiness2: {
        isCheckedYes: true, // Assuming some initial state for the registered business
      },
      vatRegistration2: {
        isCheckedYes: true, // Assuming some initial state for VAT registration
      },
      isError: false,
      showYourPassword: false,
      oldpassword: '',
      sameoldpass: false,
      samepass: true,
      MesErrProfile: configJSON.MesErrProfile,
      error: {},
      errorColor: 'false',
      lastName: '',
      seconds: 60,
      timeout: false,
      birthday: '',
      userName: '',
      userNameError: false,
      passportId: '',
      isValid: true,
      phoneNumber: '+27 ',
      isValidNumber: false,
      whatsappNumber: '',
      isWhatsappNumber: false,
      isValidAlternateNumber: false,
      firstNameError: false,
      invalidInputs: [],
      citizenStatus: '',
      storeName: '',
      postion: '',
      passportIdNumberId: '',
      coordinates: '123.123.2123',
      mobileNumber: '',
      sex: '',
      ethinicity: '',
      coordinateslatlong: { latitude: 0, longitude: 0 },
      addressline1: '',
      addressline2: '',
      emailAddress: '',
      getcoordinates: '',
      alternateNumber: '+27 ',
      dob: '',
      state: '',
      country: 'South Africa',
      conpassvalid: [],
      otpError:true,
      passportError:false,
      passportErrorInvalid:false,
      errorMessagePassID:""
      // Customizable Area End
    };
    this.emailReg = /^$/;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  navigateTologin = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Login"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start



  handleNewUserChange = () => {
    this.setState({
      isNewUserChecked: !this.state.isNewUserChecked,
      isExistingUserChecked: false,
    });
  };

  handleExistingUserChange = () => {
    this.setState({
      isExistingUserChecked: !this.state.isExistingUserChecked,
      isNewUserChecked: false,
    });
  };

  handleComplianceReportChange = (event: any) => {
    if (event && event.target) {
      const targetName = event.target.value;

      this.setState((prevState) => ({
        complianceReport: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },
        municipalPermit: prevState.municipalPermit,
        registeredBusiness: prevState.registeredBusiness,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };

  handleMunicipalPermitChange = (event: any) => {
    if (event && event.target) {
      const targetName = event.target.value;

      this.setState((prevState) => ({
        municipalPermit: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },
        complianceReport: prevState.complianceReport,
        registeredBusiness: prevState.registeredBusiness,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };

  handleRegisteredBusinessChange = (event: any) => {
    if (event && event.target) {
      const targetName = event.target.value;

      this.setState((prevState) => ({
        registeredBusiness: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },
      
        complianceReport: prevState.complianceReport,
        municipalPermit: prevState.municipalPermit,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };


  handleVatRegistrationChange = (event: any) => {
    
    if (event && event.target) {
      const targetName = event.target.value;

      this.setState((prevState) => ({
        vatRegistration: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },

        complianceReport: prevState.complianceReport,
        municipalPermit: prevState.municipalPermit,
        registeredBusiness: prevState.registeredBusiness,
      }));
    }
  };


  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  handleClickConfirmPassword = () => {
    this.setState((prevState) => ({
      confirmPassword: !prevState.confirmPassword,
    }));
  };


  handleOpenModal = () => {
    const { password, confirmPasswords } = this.state;
    this.validateUserName();
    if (password.length <= 8) {
      this.setState({ passwordLength: false, isModalOpen: false, passwordMatch: true }); 
    } else if (password === confirmPasswords && password.length > 8) {
      this.setState({ isModalOpen: true, passwordLength: true, passwordMatch: true });
    } else {
      this.setState({ passwordMatch: false, passwordLength: true, isModalOpen: false }); 
    }
  };

  handleToast = () => {
    notify()
  };
  handleOpenModalOtp = () => {
    const { isValidNumber } = this.state

    if (isValidNumber) {
      this.setState({ isModalOpen: true });
    } else {
      this.setState({
        invalidInputs: ['mobileNumber'],
      });
    }
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleOpenModal2 = () => {
    this.setState({ tickModel: true });
  };

  handleOpenModalNewUser = () => {
    this.setState({ tickModel: true });
    setTimeout(() => {
      this.setState({ tickModel: false });
      this.navigateToNextPage();
    }, 2000);
  };

  handleOpenModalNewUser2 = () => {
    if (this.validateOTP()) {
      this.setState({ tickModel: true });
      setTimeout(() => {
        this.setState({ tickModel: false });
        this.navigateToNextPage2();
      }, 2000);
    }
  };
  
  validateOTP = (): boolean => {

    const isComplete = this.state.otp.every(digit => digit !== '');
    this.setState({ otpError: !isComplete });
    return isComplete;
  
  };

handleCloseModal2 = () => {
  this.setState({ tickModel: false });
  setTimeout(() => {
    this.navigateTologin();
}, 2000); 
};

handleCloseModalVerified = (event:any) => {
  event.stopPropagation()
  this.setState({ tickModel: false });
  if (this.validateOTP()) {
    this.navigateToNextPage();
  }
};

handleInputChange = (index: number, value: string, isBackspace: boolean = false) => {
  if (isBackspace) {
    this.setState(prevState => {
      const newOtp = [...prevState.otp];
      if (newOtp[index] === '' && index > 0) {
        newOtp[index - 1] = '';
        setTimeout(() => this.inputRefs[index - 1]?.focus(), 0);
      } else {
        newOtp[index] = '';
      }
      const isComplete = newOtp.every(digit => digit !== '');
      return { otp: newOtp, otpError: !isComplete };
    });
  } else {
    if (/^\d?$/.test(value)) {
      this.setState(prevState => {
        const newOtp = [...prevState.otp];
        newOtp[index] = value;
        const isComplete = newOtp.every(digit => digit !== '');
        return { otp: newOtp, otpError: !isComplete };
      }, () => {
        if (value && index < 3) {
          setTimeout(() => this.inputRefs[index + 1]?.focus(), 0);
        }
      });
    }
  }
};

  validateEmail = () => {
    const { email } = this.state;

    if (typeof email !== 'string' || !email.trim()) {
      this.setState({ errorMessage: "Please enter a valid email address." });
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      this.setState({ errorMessage: "Email is not in the correct format." });
      return;
    }

    this.setState({ errorMessage: '' });
  };

  handleEmailChange = (e: any) => {
    this.setState({
      email: e.target.value,
      invalidInputs: this.state.invalidInputs.filter((input: any) => input !== "email"),
    });

    this.validateEmail();
  };
  handleSubmit = () => {
    const invalidInputs = this.validateInputs();
   
    if (invalidInputs.length === 0 && this.state.errorMessage === "" && this.state.errorPhoneMessage === "" && this.state.errorAlternatePhoneMessage === "") {

      const formData = { ...this.state };
      this.navigateTonewOperatorlogin()
    } else {
      this.setState({ invalidInputs });

    }
  };
  handleOperatorSubmit = () => {
    const invalidInputs = this.validateOperatorInputs();
  
    if (invalidInputs.length === 0 && this.state.errorMessage === "" && this.state.errorPhoneMessage === "" && this.state.errorAlternatePhoneMessage === "") {
      const formData = { ...this.state };
      this.props.navigation.navigate('RegisterNewUser');
    } else {
      this.setState({ invalidInputs });
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'password') {
      this.validatePassword(value);
    } else if (name === 'confirmPassword') {
      this.validateConfirmPassword(value);
    }
  };
  validatePassword = (password: any) => {
    const isValid = password.length >= 6;
    const errorMsg = isValid ? '' : 'Password must be at least 6 characters long';
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        password: errorMsg,
      },
    }));
  };
  validateConfirmPassword = (confirmPassword: any) => {
    const isValid = confirmPassword === this.state.password;
    const errorMsg = isValid ? '' : 'Passwords do not match';
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        confirmPassword: errorMsg,
      },
    }));
  };
  handlepassword = (e: any) => {
    e.preventDefault();

    this.validatePassword(this.state.password);
    this.validateConfirmPassword(this.state.confirmPassword);


  };

  handlePasswordChange = (e: any) => {
    this.setState({ password: e.target.value })
  }

  handleConfirmPasswordChange = (e: any) => {
    this.setState({ confirmPasswords: e.target.value })
  }

  handleInputFirstName = (event: any) => {
    const inputValue = event.target.value;
    this.setState({
      firstName: inputValue,
      isError: inputValue.trim() === '',
    });
  };

  handleBlurFirstName = () => {
    this.setState({
      isError: this.state.firstName.trim() === '',
    });
  };

  handleNextButtonClick = () => {
    if (this.state.isNewUserChecked) {
      this.props.navigation.navigate('SignUp')
    } else if (this.state.isExistingUserChecked) {
      this.props.navigation.navigate('ExistingUser')
    }
  };

  validatePasswordPo = () => {
    const setPassword = this.state.password;
    const isCapitalLetter = /[A-Z]/.test(setPassword);
    const isLowerCaseLetter = /[a-z]/.test(setPassword);
    const isNumber = /\d/.test(setPassword);
    const isMinLength = setPassword.length >= 8;

    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
    };
  };
  handleChangePassword = (event: any) => {
    const { value } = event.target;

    if (this.state.oldpassword === value) {
      this.setState({ password: "", sameoldpass: true });
    } else {
      this.setState({ password: value, sameoldpass: false }, () => {
        if (this.state.password === this.state.confirmPassword) {
          this.setState({ samepass: true });
        } else {
          this.setState({ samepass: false });
        }
      });
    }
  };


  handleChangeoldPassword = (event: any) => {
    this.setState({ oldpassword: event.target.value });
  };

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleConfirmPassword = (event: any) => {
    const { value } = event.target;

    this.setState({ confirmPasswords: value }, () => {
      if (this.state.password === this.state.confirmPasswords) {
        this.setState({ samepass: true });
      } else {
        this.setState({ samepass: false });
      }
    });
  };

  handleYourTogglePassword = () => {
    this.setState((prevState) => ({
      showYourPassword: !prevState.showYourPassword,
    }));
  };

  navigateToNextPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'SignUp');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  };

  navigateToNextPage2 = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'OperatorSignUp');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
    
  };

  inputRefs: (HTMLInputElement | null)[] = [];

  async componentDidMount() {
    this.startOtpTimer();
    this.setupInputRefs();
  }

  async componentWillUnmount() {
    this.clearOtpTimer();
  }

  startOtpTimer = () => {
    this.timerInterval = setInterval(this.updateTimer, 1000);
  }

  updateTimer = () => {
    this.setState((prevState) => {
      if (prevState.seconds > 0) {
        return {
          seconds: prevState.seconds - 1,
          timeout: false
        };
      } else {
        this.clearOtpTimer();
        return {
          seconds: 0,
          timeout: true
        };
      }
    });
  }

  clearOtpTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }
  }

  setupInputRefs = () => {
    this.inputRefs = this.state.otp.map(() => null);
  }

  handleInputChangeNumber = (event: any) => {
    let sanitizedValue = event.target.value.replace(/\D/g, '');
    const inputPhoneNumber = event.target.value;

    if (/^\d{0,10}$/.test(inputPhoneNumber)) {

      this.setState({ userName: inputPhoneNumber, userNameError: inputPhoneNumber.length === 10 });
    }
    sanitizedValue = sanitizedValue.slice(0, 10);
    this.setState({
      userName: sanitizedValue,
      userNameError: false, 
    });
  };

  validateBirthday = () => {
    const { birthday } = this.state;
    const regex1 = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    const regex2 = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;


    if (!(regex1.test(birthday) || regex2.test(birthday))) {
      this.setState({ birthday: '' });
    }
  };



  onNativeChange = (e: any) => {
    this.setState({
      birthday: e.target.value,
      invalidInputs: this.state.invalidInputs.filter((input: any) => input !== "birthday"),
    });
  };


  validateUserName = () => {
    const passportIdNumberId = this.state.passportIdNumberId;

    if (typeof passportIdNumberId === 'string' && passportIdNumberId.trim() === '') {
      this.setState({
        userNameError: true,
      });
    } else {
      this.setState({
        userNameError: false,
      });
    }
  };

  handlePassportValidation = (event: any) => {
    const inputValue = event.target.value;
    this.handleValidatePassportID(inputValue)

  };
  handleValidatePassportID = (inputValue: string) => {
    const sanitizedInput = inputValue.replace(/[^0-9]/g, '').slice(0, 13);
    let isValid = true;

    if (sanitizedInput.trim() === '') {
        this.setState({errorMessagePassID:"Username (ID/Passport) is required"})
        isValid = false;
    } else if (sanitizedInput.length !== 13) {
      this.setState({errorMessagePassID:"Please enter a 13-digit numeric value."})
        isValid = false;
    }

    this.setState({
        passportIdNumberId: sanitizedInput,
        passportError: !isValid,
        passportErrorInvalid: !isValid,
        invalidInputs: isValid 
            ? this.state.invalidInputs.filter((input: string) => input !== 'passportIdNumberId')
            : [...this.state.invalidInputs, 'passportIdNumberId']
    });
};





  handlePhoneNumberChange = (e: any) => {
    let inputPhoneNumber = e.target.value;
    inputPhoneNumber = inputPhoneNumber.replace(/[^\d+]/g, '');
    const digitsOnly = inputPhoneNumber.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';

    switch (true) {
        case digitsOnly.length > 7:
            formattedNumber += ' ' + digitsOnly.slice(2, 4) + ' ' + digitsOnly.slice(4, 7) + ' ' + digitsOnly.slice(7, 11);
            break;
        case digitsOnly.length > 4:
            formattedNumber += ' ' + digitsOnly.slice(2, 4) + ' ' + digitsOnly.slice(4, 7);
            break;
        case digitsOnly.length > 2:
            formattedNumber += ' ' + digitsOnly.slice(2, 4);
            break;
    }

    const isValidNumber = digitsOnly.length === 11;

    let errorPhoneMessage = '';
    if (formattedNumber.trim() === '') {
        errorPhoneMessage = 'Please enter a mobile number';
    } else if (!isValidNumber) {
        errorPhoneMessage = 'Please enter a valid mobile number';
    }

    this.setState({
        phoneNumber: formattedNumber,
        isValidNumber: isValidNumber,
        errorPhoneMessage: errorPhoneMessage,
        invalidInputs: (formattedNumber.trim() === '' || !isValidNumber) 
            ? [...new Set([...this.state.invalidInputs, 'mobileNumber'])] 
            : this.state.invalidInputs.filter((input: any) => input !== 'mobileNumber')
    });
};


  handleWhatsappNumberNumber = (e: any) => {
    const inputPhoneNumber = e.target.value;

    if (/^[+\d\s]{0,15}$/.test(inputPhoneNumber) && inputPhoneNumber.replace(/[+\s]/g, '').length <= 11) {

      this.setState({ whatsappNumber: inputPhoneNumber, isWhatsappNumber: inputPhoneNumber.length === 10 });
    }
  };

  handleAlternateNumberChange = (e: any) => {
    let inputNumber = e?.target?.value;
    inputNumber = inputNumber?.replace(/[^\d+]/g, '');
    const digits = inputNumber?.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNum = '+27';
    if (digits.length > 2) {
      formattedNum += ' ' + digits.slice(2, 4);
    }
    if (digits.length > 4) {
      formattedNum += ' ' + digits.slice(4, 7);
    }
    if (digits.length > 7) {
      formattedNum += ' ' + digits.slice(7, 11);
    }
    const isValidNum = digits.length === 11;

    this.setState({
      alternateNumber: formattedNum,
      isValidAlternateNumber: isValidNum,
      errorAlternatePhoneMessage: isValidNum ? '' : 'Invalid Phone Number',
      invalidInputs: isValidNum ? this.state.invalidInputs.filter((input: any) => input !== 'alternateNumber') : [...this.state.invalidInputs, 'phoneNumber']
    });

  };



  handleInputChanges = (e: any) => {
    const { name, value } = e.target;
    
    if (name === 'passportIdNumberId') {
        this.handleValidatePassportID(value);
    } else {
        // General handling for other inputs
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
            invalidInputs: prevState.invalidInputs.filter((input: string) => input !== name),
        }));
    }
};

  navigateTonewOperatorlogin = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RegisterNewUser"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  validateInputs = () => {
    const requiredFields: (keyof S)[] = [
      'firstName', 'lastName', 'citizenStatus', 'storeName',
      'passportIdNumberId', 'getcoordinates', 'phoneNumber', 'sex', 'ethinicity',
      'addressline1', 'addressline2', 'email', 'alternateNumber',
      'birthday', 'state', 'country'
    ];

    const invalidInputs = requiredFields.filter(field => {
      const fieldValue = this.state[field];
      const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;

      if ((field === 'phoneNumber' || field === 'alternateNumber') && trimmedValue === '+27') {
        return true;
      }

      return !trimmedValue;
    });
    if (invalidInputs.length != 0) {
      this.handleToast()
    }
    return invalidInputs;
  }

  validateOperatorInputs = () => {
    const requiredFields: (keyof S)[] = [
      'firstName', 'lastName', 'citizenStatus', 'storeName',
      'passportIdNumberId', 'phoneNumber', 'sex', 'ethinicity',
      'addressline1', 'addressline2', 'email', 'alternateNumber',
      'country', 'postion', 'coordinates'
    ];

    const invalidInputs = requiredFields.filter(field => {
      const fieldValue = this.state[field];
      const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;

      if ((field === 'phoneNumber' || field === 'alternateNumber') && trimmedValue === '+27') {
        return true;
      }

      return !trimmedValue;
    });
    if (invalidInputs.length != 0) {
      this.handleToast()
    }
    return invalidInputs;
  }


  handleInputChangesTwo = (event: any) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
        conpassvalid: prevState.conpassvalid.filter((input: string) => input !== name),
      };

      if (name === 'confirmPasswords') {
        updatedState.samepass = updatedState.password === value;
      }

      return updatedState;
    });
  };




  validateInputsTwo = () => {
    const {
      passportIdNumberId,
      password,
      confirmPasswords
    } = this.state;

    const conpassvalid = [];

    if (!passportIdNumberId?.trim()) {
      conpassvalid.push('passportIdNumberId');
    }

    if (typeof password !== 'string' || !password.trim()) {
      conpassvalid.push('password');
    }

    if (typeof confirmPasswords !== 'string' || !confirmPasswords.trim()) {
      conpassvalid.push('confirmPasswords');
    }
    if (conpassvalid.length != 0) {
      this.handleToast()
    }
    return conpassvalid;
  }



  handleSubmitTwo = () => {
    const numberregex = /^[/d]+$/
    const alphabetsRegex = /^[a-zA-Z\s]+$/

    const conpassvalid = this.validateInputsTwo();
    if (alphabetsRegex.test(this.state.passportIdNumberId)) {
      this.setState({ userNameError: true, });
    }
    else if (numberregex.test(this.state.passportIdNumberId)) {
      this.setState({ userNameError: true, });
    }
    else {
      this.setState({ userNameError: false, });
    }
    if (conpassvalid.length === 0 && this.state.password === this.state.confirmPasswords) {
      const formData = { ...this.state };
      this.setState({ samepass: true });
      this.handleOpenModal();
    } else {
      this.setState({ conpassvalid });
    }
  };
  handlePhoneNumberChange2 = (e: any) => {
    const inputPhoneNumber = e.target?.value;
    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      this.setState({ phoneNumber: inputPhoneNumber, isValidNumber: inputPhoneNumber.length === 10 });
    }
  };
  validateMobileNumber = () => {
    const isValid = /^\d{10}$/.test(this.state.phoneNumber);
    return isValid;
  };
  handleCoordinatesClick = () => {
    if (typeof navigator !== 'undefined' && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const apiKey = 'AIzaSyDhz8_jB1AfyTrBl3jhOEfUPP6DmqT4CUA';
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.results && data.results.length > 0) {
              const formattedAddress = data.results[0].formatted_address;

              this.setState({
                getcoordinates: formattedAddress,
                invalidInputs: this.state.invalidInputs.filter((input: any) => input !== 'getcoordinates')
              });
            }
          })

      });
    }
  };


//register API call
RegisterUser = () => {
  const invalidInputs = this.validateInputs();
   
  if (invalidInputs.length === 0 && this.state.errorMessage === "" && this.state.errorPhoneMessage === "" && this.state.errorAlternatePhoneMessage === "") {

    this.navigateTonewOperatorlogin()
  } else {
    this.setState({ invalidInputs });
  }


  const header = {
    "Content-Type": configJSON.loginApiContentType,
  };

  const attrs = {
    firstname:this.state.firstName,
    lastname:this.state.lastName,
    citizenStatus:this.state.citizenStatus,
    storeName:this.state.storeName,
    passportIdNumberId:this.state.passportIdNumberId,
    coordinates:this.state.coordinates,
    birthday:this.state.birthday,
    phoneNumber:this.state.phoneNumber,
    ethinicity:this.state.ethinicity,
    gender:this.state.sex,
    state:this.state.state,
    country:this.state.country,
    addressline1:this.state.addressline1,
    addressline2:this.state.addressline2,
    email: this.state.email,
    alternateNumber: this.state.alternateNumber,
    complianceReport: this.state.complianceReport.isCheckedYes,
    municipalPermit: this.state.municipalPermit.isCheckedYes,
    registeredBusiness: this.state.registeredBusiness.isCheckedYes,
    vatRegistration: this.state.vatRegistration.isCheckedYes,
  };

  const data = {
    type: "email_account",
    attributes: attrs,
  };

  const httpBody = {
    data: data,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiEmailLoginCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "Signup"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
}




  // Customizable Area End
}
