import React from "react";

import {
  Box,
  Typography,
  styled,
  FormControl,
  Select,
  Button,
  MenuItem
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Toaster } from "react-hot-toast"
// Customizable Area Start


const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

const CustomTypography = styled(Typography)({
  paddingLeft: '54px',
  '@media(max-width: 600px)': {
    paddingLeft: '20px'
  }
})
const CustomBox = styled(Box)({
  backgroundColor: '#ffffff',
  maxWidth: "690px",
  width: '100%',
  height: "676px",
  paddingTop: "30px",
  borderRadius: '12px',
  marginTop: '25px',
  marginLeft: '54px',
  '@media(max-width: 600px)': {
    width: '90%',
    margin: '20px auto'
  }
})
const CustomButton = styled(Button)({
  backgroundColor: "#C12030",
  fontSize: "16px",
  marginTop: "30px",
  color: "#fff",
  maxWidth: '600px',
  width: "100%",
  height: "50px",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontFamily: 'Poppins',
  fontWeight: 400,
  '@media(max-width: 600px)': {
    marginTop: "10px",
  },
  '&:hover': {
    backgroundColor: "#C12030",
  }
});

const StoreBox = styled(Box)({
  maxWidth: "600px",
  width: '100%',
  height: "200px",
  borderRadius: '12px',
  marginTop: '40px',
  paddingLeft: '15px',
  paddingRight: '15px',
  marginLeft: '30px',
  marginRight: '30px',
  paddingTop: '15px',
  boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
  '@media(max-width: 600px)': {
    width: '80%',
    margin: '20px auto'
  }
})
const WalletBox = styled(Box)({
  maxWidth: "600px",
  width: '100%',
  height: "270px",
  borderRadius: '12px',
  marginTop: '25px',
  paddingLeft: '15px',
  paddingRight: '15px',
  marginLeft: '30px',
  marginRight: '30px',
  paddingTop: '15px',
  boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
  '@media(max-width: 600px)': {
    width: '80%',
    margin: '20px auto'
  }
})

const WalletTop = styled(Box)({
  '@media(max-width: 992px)': {
    marginTop: "0 !important"
  }
})

const WalletText = styled(Typography)({
  '@media(max-width: 900px)': {
    fontSize: "16px !important"
  }
})
// Customizable Area End

import Cfwallet53Controller, {
  Props,
  configJSON,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import { leftArrow, downArrow } from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';

const TotalWalletText = styled(Typography)({
  '@media(max-width: 700px)': {
    fontSize: "18px !important"
  }
})
export default class WalletTopUp extends Cfwallet53Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    let showSuccess = localStorage.getItem('showSuccess');
    if (showSuccess != null) {
      setTimeout(() => {
        this.handleSuccess()
      }, 1000);
    }

    // Customizable Area End
  }


  render() {
    const { selected, selectStore, storeError } = this.state;

    return (
      // Customizable Area Start
      <Box>
        <SideNavbar />
        <RightBoxLanding2 style={webStyle.secondContainer}>
        <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Wallet Top-up</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
            </Box>

          <CustomBox>
            <Box style={{ marginLeft: 20, maxWidth: "80px" }}>
              <Link to="/WalletChooseBank" style={{ textDecoration: 'none', maxWidth: "80px", display: 'flex' }}>
                <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                <Typography style={webStyle.backIcon}>Back</Typography>
              </Link>
            </Box>
            <StoreBox>
              <label style={{ ...webStyle.storeLable, }}>
                Select Store
              </label>
              <FormControl variant="outlined" required style={webStyle.formStyle}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  data-test-id={'selectStoreId'}
                  value={this.state.selectStore}
                  error={this.state.storeValidate}
                  onChange={this.handleChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  style={{ width: '100%', height: '50px', borderRadius: "8px", border: this.state.storeValidate === true ? "1px solid #FF0404" : "", fontFamily: "Poppins", fontWeight: 500 }}
                >
                  <MenuItem disabled={true} value={1} style={webStyle.selectStoreStyle}>Select the store</MenuItem>
                  <MenuItem value={'Kahndal Saree Store'} style={webStyle.selectStoreStyle}>Kahndal Saree Store</MenuItem>
                  <MenuItem value={'Pretty Saree Store'} style={webStyle.selectStoreStyle}>Pretty Saree Store</MenuItem>
                  <MenuItem value={'Linen Saree Shop'} style={webStyle.selectStoreStyle}>Linen Saree Shop</MenuItem>
                </Select>
              </FormControl>
              {this.state.storeValidate === true ?
                <Typography style={webStyle.validationMsg}>Please select the store </Typography> : ""}
              <TotalWalletText style={webStyle.storeDetailsText}>Total Wallet Balance</TotalWalletText>
              <Typography style={webStyle.storeDetailsText2}>{this.state.selectStore === '' ? `'${"---"}'` : `R 1000`}</Typography>
            </StoreBox>
            <WalletBox>
              <Box style={webStyle.boxStyle}>
                <TotalWalletText style={webStyle.transferText}>Add Money to</TotalWalletText>
                <TotalWalletText style={webStyle.transferText2}>Wallet</TotalWalletText>
                <img src={downArrow} style={{ width: '24px', height: '24px', cursor: 'pointer', display: 'flex', alignSelf: 'center', marginLeft: '16px' }} />
              </Box>
              <Box style={webStyle.textContainer}>
                <Box style={webStyle.textBox}>
                  <Typography style={webStyle.smalltext as React.CSSProperties}>R</Typography>
                  <input
                    className="no-outline"
                    type="text"
                    name="amount"
                    placeholder="Enter amount"
                    value={this.state.amount}
                    onChange={this.handleSetEndAmount}
                    style={webStyle.inputStyle} />
                </Box>
              </Box>
              {this.state.moneyField === true ? "Here" : ""}
              <Box style={webStyle.amountBox}>
                <Button style={webStyle.amountBoxStyle} data-test-id="amount1" onClick={() => this.onClickHandler(300)}>
                  <Typography style={webStyle.amountText}>+R300</Typography>
                </Button>
                <Button style={webStyle.amountBoxStyle} data-test-id="amount2" onClick={() => this.onClickHandler(500)}>
                  <Typography style={webStyle.amountText}>+R500</Typography>
                </Button>
                <Button style={webStyle.amountBoxStyle} data-test-id="amount3" onClick={() => this.onClickHandler(1000)}>
                  <Typography style={webStyle.amountText}>+R1000</Typography>
                </Button>
              </Box>

              <CustomButton style={webStyle.firstButton} data-test-id="proceedmoney" onClick={this.MoneyToWallet}>
                Proceed to Add
              </CustomButton>


            </WalletBox>

          </CustomBox>
          <Toaster position="top-center" />

        </RightBoxLanding2>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  secondContainer:
    {
      backgroundColor: "#E2E8F0",
      // height: '115vh'
      paddingBottom: "30px"
    } as React.CSSProperties,
  mainContainer: {
    display: "flex",
    flexDirection: "column", // This will stack the child elements vertically
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "1173px",
    width: '100%',
    height: "851px",
    backgroundColor: '#fff'
  } as React.CSSProperties,
  backIcon: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    paddingLeft: 8,
    cursor: 'pointer'
  },
  storeLable: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    color: "#273567",
  },
  formStyle: {
    border: `0.5px #273567`,
    borderRadius: "18px",
    width: '100%'
  },
  storeDetailsText: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '24px',
    marginTop: '8px'
  },
  storeDetailsText2: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '24px',
    marginTop: '8px'
  },
  boxStyle: {
    display: 'flex',
    marginTop: '8px'
  },
  transferText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500
  },
  transferText2: {
    color: '#C12030',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    marginLeft: '5px'
  },
  textContainer: {
    display: 'flex',
    borderRadius: '12px',
    justifyContent: 'space-between',
    border: '0.5px solid #273567',
    alignItems: 'center',
    width: '96%',
    paddingLeft: '10px',
    paddingRight: '10px',
    height: '50px',
    marginTop: 22
  },
  textBox: {
    display: 'flex'
  },
  smalltext: {
    alignSelf: 'center',
    fontWeight: '500',
    marginRight: '1%',
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#C12030'
  },
  inputStyle: {
    color: "#000000",
    fontFamily: 'Poppins',
    border: 'none',
    borderColor: 'white',
    fontWeight: 500,
    fontSize: '14px',
    alignSelf: 'center',
    outline: 'none',
    width: '100%',
  },
  amountBox: {
    display: 'flex',
    marginTop: '20px',
    justifyContent: 'flex-end'
  },
  amountBoxStyle: {
    height: '32px',
    maxWidth: '73px',
    width: '100%',
    borderRadius: '12px',
    borderWidth: '5px',
    border: '1px solid #273567',
    marginRight: '25px'
  },
  amountText: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,
  firstButton: {
    // backgroundColor: "#C12030",
    // fontSize: "16px",
    // marginTop: "30px",
    // color: "#fff",
    // maxWidth: '600px',
    // width: "100%",
    // height: "50px",
    // borderRadius: "8px",
    // textTransform: "capitalize",
    // fontFamily: 'Poppins',
    // fontWeight: 400
  } as React.CSSProperties,

  selectStoreStyle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,

  validationMsg: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '12px',
    color: "#FF0404"
  },
  
  HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,

};
// Customizable Area End
