import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

interface Data {
  customerID: string;
  name: string;
  accountBalance: string;
  phoneNo: string
}

const providerrows: Data[] = [
  {
    customerID: '111aaa4444',
    accountBalance: "-R100",
    name: "Aakshit Shrivastav",
    phoneNo: '+91 91235*****'
  },
  {
    name: "Hemang Joshi",
    phoneNo: '+91 92345*****',
    customerID: '222CCC9999',
    accountBalance: "+R500",
  },
  {
    phoneNo: '+91 97345*****',
    name: "Purva Sharma",
    customerID: '333bbb8888',
    accountBalance: "+R500",
  },
  {
    accountBalance: "+R500",
    phoneNo: '+91 97895*****',
    name: "Deepanshi Agarwal",
    customerID: '444ddd7777',
  },
  {
    customerID: '555eee6666',
    accountBalance: "+R500",
    phoneNo: '+91 96547*****',
    name: "Jatin Vyas",
  },
  {
    customerID: '666fff5555',
    phoneNo: '+91 95544*****',
    accountBalance: "+R500",
    name: "Sahil Ahmed",
  },
  {
    customerID: '555nnn4444',
    accountBalance: "+R500",
    phoneNo: '+91 97744*****',
    name: "Purva Ahmed",
  },
  {
    name: "Jatin Sharma",
    customerID: '444mmm4444',
    accountBalance: "+R500",
    phoneNo: '+91 96599*****',
  },
  {
    customerID: '333sss7666',
    accountBalance: "+R500",
    name: "Purva Sharma",
    phoneNo: '+91 95854*****',
  },
  {
    customerID: '124CCC4884',
    accountBalance: "+R500",
    phoneNo: '+91 94455*****',
    name: "Purva Sharma",
  },
  {
    customerID: '187cfr4487',
    name: "Deepanshi Sharma",
    accountBalance: "+R500",
    phoneNo: '+91 96655*****',

  },

];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchText: any
  rowsPerPage: any
  page: any
  name: any
  phoneNumber: any
  nameError: any
  phoneNumberError: any
  openModal: any;
  isSearchText: any
  data: any
  originalData: any
  phoneNumbererror:string
  shopperAccountList:string[]
  shopperAccountDetail:any
  operatorShopperAccountList:string[]
  operatorShopperAccountDetail:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfshoppersaccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallMessageGetOperatorShopperAccountList:string = ''
    apiCallMessageGetOperatorShopperAccountDetail:string = ''
    apiCallMessageGetShopperAccountList:string = ''
    apiCallMessageGetShopperAccountDetail:string = ''
    apiCallMessageCreateShopperAccount:string = ''
    apiCallMessageCreateOperatorShopperAccount:string = ''
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage
      )
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchText: '',
      rowsPerPage: 10,
      page: 0,
      name: '',
      phoneNumbererror:"",
      phoneNumber: '+27 ',
      nameError: false,
      phoneNumberError: false,
      openModal: false,
      isSearchText: false,
      data: providerrows,
      originalData: providerrows,
      shopperAccountList:[],
      shopperAccountDetail:'',
      operatorShopperAccountList:[],
      operatorShopperAccountDetail:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
  if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
    this.handleRestAPIResponseMessage(message);
  }
  
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.apiCallMessageGetShopperAccountList:
          this.handleMessageGetShopperAccountList(responseJson)
          break;
        case this.apiCallMessageGetShopperAccountDetail:
          this.handleMessageGetShopperAccountDetail(responseJson)
          break;
        case this.apiCallMessageCreateShopperAccount:
          this.handleMessageCreateShopperAccount(responseJson)
          break;
        case this.apiCallMessageGetOperatorShopperAccountList:
          this.handleMessageGetOperatorShopperAccountList(responseJson)
          break;
        case this.apiCallMessageGetOperatorShopperAccountDetail:
          this.handleMessageGetOperatorShopperAccountDetail(responseJson)
          break;
          case this.apiCallMessageCreateOperatorShopperAccount:
            this.handleMessageCreateOperatorShopperAccount(responseJson)
            break;
      }
    }
  }  
  handleMessageGetShopperAccountList(responseJson:any){
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({ shopperAccountList: responseJson.data });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetShopperAccountDetail(resJson:any){
    if (resJson != null && resJson.errors === undefined) {
      this.setState({ shopperAccountDetail: resJson.data });
    } else {
      this.parseApiErrorResponse(resJson);
    }
  }
  handleMessageGetOperatorShopperAccountList(res:any){
    if (res != null && res.errors === undefined) {
      this.setState({ operatorShopperAccountList: res.data });
    } else {
      this.parseApiErrorResponse(res);
    }
  }
  handleMessageGetOperatorShopperAccountDetail(response:any){
    if (response != null && response.errors === undefined) {
      this.setState({ operatorShopperAccountDetail: response.data });
    } else {
      this.parseApiErrorResponse(response);
    }
  }
  handleMessageCreateShopperAccount(rspJson:any){
    if (rspJson != null && rspJson.errors === undefined) {
      return
    } else {
      this.parseApiErrorResponse(rspJson);
    }
  }
  handleMessageCreateOperatorShopperAccount(resJson:any){
    if (resJson != null && resJson.errors === undefined) {
      return
    } else {
      this.parseApiErrorResponse(resJson);
    }
  }
  handleName = (event: any) => {
    this.setState({ name: event?.target?.value })
    if (event?.target?.value.length >= 1) {
      this.setState({ nameError: false })
    }
  }
  handleSetPhoneNumber = (event: any) => {
    let inputPhoneN = event.target.value;
    inputPhoneN = inputPhoneN.replace(/[^\d+]/g, '');
    const digitsOnl = inputPhoneN.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';
    if (digitsOnl.length > 2) {
        formattedNumber += ' ' + digitsOnl.slice(2, 4);
    }
    if (digitsOnl.length > 4) {
        formattedNumber += ' ' + digitsOnl.slice(4, 7);
    }
    if (digitsOnl.length > 7) {
        formattedNumber += ' ' + digitsOnl.slice(7, 11);
    }
    const isValidNu = digitsOnl.length === 11;

    this.setState({
        phoneNumber: formattedNumber,
        phoneNumberError: isValidNu,
        phoneNumbererror:isValidNu ? '' : 'Invalid Phone Number',
        
    });
  }
  handleBackToOperatorDashboard =()=>{
    this.props.navigation.navigate('OperatorDashboard')
  }
  handleLandingPageFirst = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'Dashboard');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleButtonClick = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateShopperAccount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)

  }
  handleButtonClickOperator = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateShopperAccountOperator');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)

  }
  handleButtonShopperDetails = (accountId:string) => {
    this.props.navigation.navigate('ShopperAccountDetailsOperator',{accountId})
  }
  handleBackClick = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppersAccount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)

  }
  handleGoToshopperAccount = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppersAccount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  handleGoToshopperAccountOperator = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'OperatorShopperAccount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }
  ProceedToSubmit = () => {
    let nameRegex = /^[a-zA-Z]*$/
    if (this.state.name == '') {
      this.setState({ nameError: true })
    }
    // else if (!nameRegex.test(this.state.name)) {
    //   this.setState({ nameError: true })
    // }
    // else  if (this.state.name.length >=1) {
    //   this.setState({ nameError: false })
    // }
    else if (this.state.phoneNumber == '+27 ') {
      this.setState({ phoneNumbererror: "Phone Number is Required", nameError: false })
    } else if (this.state.phoneNumbererror != "") {
      this.setState({ phoneNumbererror: "Invalid Phone Number", nameError: false })
    }
    else {
      this.setState({ phoneNumberError: false, openModal: true, nameError: false })
      const header = {
      "Content-Type": "application/json",
    };
      const httpBody = {
       account:this.state.name,
        phonenumber:this.state.phoneNumber
      }
  
      const submitMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiCallMessageCreateShopperAccount = submitMessage.messageId;
  
      
      submitMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
          submitMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      submitMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'shopperAccount/create'
      );
      
      submitMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(submitMessage.id, submitMessage);
      setTimeout(() => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppersAccount');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
      }, 2000)


    }
  }
  ProceedToSubmitOperator = () => {
    if (this.state.name == '') {
      this.setState({ nameError: true })
    }
    else if (this.state.phoneNumber == '+27 ') {
      this.setState({ phoneNumbererror: "Phone Number is Required", nameError: false })
    } else if (this.state.phoneNumbererror != "") {
      this.setState({ phoneNumbererror: "Invalid Phone Number", nameError: false })
    }
    else {
      this.setState({ phoneNumbererror: "", openModal: true, nameError: false })
      const header = {
        "Content-Type": "application/json",
      };
        const httpBody = {
         account:this.state.name,
          phonenumber:this.state.phoneNumber
        }
    
        const submitMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiCallMessageCreateOperatorShopperAccount = submitMessage.messageId;
    
        
        submitMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        
            submitMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(httpBody)
            );
        submitMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'shopperAccount/create'
        );
        
        submitMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(submitMessage.id, submitMessage);
      setTimeout(() => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), 'OperatorShopperAccount');
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg)
      }, 2000)


    }
  }
   handleKeyPress = (e:any) => {
    // Regular expression to match only alphabetic characters
    const regex = /^[A-Za-z]+$/;
    // Prevent the input if it does not match the regex
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };
  handleCloseModal = () => {
    this.setState({openModal:false})
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppersAccount');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  };
  onClickSearchText = () => {
    this.setState({ isSearchText: true, });
  };
  handleSearch = (e: any) => {
    const searchText = e?.target?.value;
    const { data,originalData } = this.state;
    console.log("searchText====>>>>",searchText)
    // Filter the data based on the input value
    let filteredData = originalData.filter((item: any) =>
      item.name?.toLowerCase().includes(searchText?.toLowerCase()) || item.customerID?.toLowerCase().includes(searchText?.toLowerCase()) || item.phoneNo?.toLowerCase().includes(searchText?.toLowerCase())
    );
    console.log("filteredData====>>>>",filteredData)

    this.setState({ data: filteredData, searchText: searchText });
    if (searchText === '') {
      this.setState({ data: this.state.originalData })
    }
  };

  handleBodyClickOnScreen(event: any) {
    if (event?.target?.id != 'dateModal') {
      this.setState({ isSearchText: false, searchText: '', data: this.state.originalData });
    }
  }
  gotoShopperDetails= (id:string) => {
   this.props.navigation.navigate('ShopperAccountDetails',{accountId:id})
  };
  getShopperAccountList = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetShopperAccountListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "shopperAccountList/get"
    );
    
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiCallMessageGetShopperAccountList = requestGetShopperAccountListMessage.messageId;
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetShopperAccountListMessage.id, requestGetShopperAccountListMessage);
  };
  getShopperAccountDetail= (id:string)=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetShopperAccountDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `shopperAccountDetai/${id}`
    );
    
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiCallMessageGetShopperAccountDetail = requestGetShopperAccountDetailMessage.messageId;
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetShopperAccountDetailMessage.id, requestGetShopperAccountDetailMessage);
  };
  getOperatorShopperAccountList= ()=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetShopperAccountListMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "operatorShopperAccountList/get"
    );
    
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiCallMessageGetOperatorShopperAccountList = requestGetShopperAccountListMessage.messageId;
    requestGetShopperAccountListMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetShopperAccountListMessage.id, requestGetShopperAccountListMessage);
  };
  getOperatorShopperAccountDetail= (id:string)=>{
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetShopperAccountDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `operatorShopperAccountDetai/${id}`
    );
    
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    this.apiCallMessageGetOperatorShopperAccountDetail = requestGetShopperAccountDetailMessage.messageId;
    requestGetShopperAccountDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetShopperAccountDetailMessage.id, requestGetShopperAccountDetailMessage);
  };
  // Customizable Area End
}
