import React from "react";

import {
    Box,
    Typography,
    styled,
    Button
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { leftArrow } from './assets';
import Cfwallet53Controller, {
    Props,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';

// Customizable Area Start


const InvoiceRIghtContainer = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const InvoiceText = styled(Typography)({

    paddingLeft: '54px',

    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }

})

const InvoiceCard = styled(Box)({

    backgroundColor: '#ffffff',
    borderRadius: '12px',
    maxWidth: "665px",
    padding: "40px",
})

const PayButton = styled(Button)({
    backgroundColor: "#C12030 !important",
    color: "#fff !important",
    fontSize: "16px",
    marginTop: 100,
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: 500,
    '@media(max-width: 600px)': {
        marginTop: 30,
    }
})

const BillsText = styled(Box)({
    '@media(max-width: 992px)': {
        marginTop: "0 !important"
    }
})

const PayText = styled(Typography)({
    '@media(max-width: 900px)': {
        fontSize: "16px !important"
    }
})

const InvoiceTitleText = styled(Typography)({
    '@media(max-width: 600px)': {
        fontSize: "18px !important"
    }
})

const SupplierText = styled(Typography)({
    '@media(max-width: 600px)': {
        fontSize: "12px !important"
    }
})

const SupplierMoney = styled(Typography)({
    '@media(max-width: 600px)': {
        fontSize: "14px !important"
    }
})
// Customizable Area End


export default class InvoiceDetail extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {

        return (
            // Customizable Area Start

            <Box>
                <SideNavbar />
                <InvoiceRIghtContainer style={webStyle.MainContainer}>
                    <Box style={webStyle.HeadersMain}>
                        <BillsText style={{ marginTop: "20px", paddingLeft: "40px" }}>
                            <PayText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Pay Bills</PayText>
                        </BillsText>
                        <Box>
                            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                        </Box>
                    </Box>
                    <Box style={{ padding: "40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <InvoiceCard style={webStyle.backContainer}>

                            <Box style={{ display: 'flex', marginBottom: "30px", cursor: 'pointer',maxWidth:"80px"}} onClick={this.handleInvoiceBack}>
                                <img src={leftArrow} style={{ width: '24px', height: '24px', }} />
                                <Typography style={webStyle.backIcon}>Back</Typography>
                            </Box>

                            <Box style={webStyle.cardStyle}>
                                <InvoiceTitleText style={webStyle.InvoiceTitle as React.CSSProperties}>Invoice Number</InvoiceTitleText>
                                <SupplierText style={webStyle.InvoiceNumber as React.CSSProperties}>******Invoice Number***** Supplier Name</SupplierText>
                                <SupplierMoney style={webStyle.InvoiceamountStyle as React.CSSProperties}>R 1000</SupplierMoney>
                                <Link to="/PaymentMode">
                                    <PayButton style={webStyle.payButton as React.CSSProperties}>
                                        Pay
                                    </PayButton>
                                </Link>
                            </Box>
                        </InvoiceCard>
                    </Box>
                </InvoiceRIghtContainer>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {

    MainContainer:
        {
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,

    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },
    backIconMain: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },

    backContainer: {
        // backgroundColor: '#ffffff',
    },
    cardStyle: {
        maxWidth: "630px",
        borderRadius: '12px',
        padding: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    },
    InvoiceTitle: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        textAlign: 'center',
        marginTop: 15
    },
    InvoiceNumber: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'center'
    },
    InvoiceamountStyle: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        marginTop: 100,
        textAlign: 'center'
    },
    payButton: {
        // backgroundColor: "#C12030",
    },
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
