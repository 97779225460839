import React from "react";


// Customizable Area Start

import {
    Box,
    Typography,
    styled,
} from "@material-ui/core";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { leftArrow, africanBank, standardBank, investEC, nedBank, bidvestBank, absaBank, discoveryBank, CapitecBank, FNBBank, TymeBank } from "./assets";
;
const ApplyLoanBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const ApplyLoanBackMain = styled(Box)({
    display: 'flex',
    margin: "10px 0",
    maxWidth: "230px"
})
const ApplyLoanBox = styled(Box)({
    display: 'flex',
    flexWrap: "wrap",
    gap: '8px',
    backgroundColor: "#FFFFFF",
    padding: "17px 24px 17px",
    borderRadius: "4px",
    justifyContent: 'space-between',
    margin: "20px 0",
    maxWidth: "100%",

})
const ApplyLoanWallet = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: 600,
    fontSize: '20px',
    alignSelf: 'center',
    '@media(max-width: 600px)': {
        padding: '0px'
    }
})
const MainBox = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    padding:"0 40px",
    marginTop: '15px',
    gap:"20px",
    '@media (max-width: 750px)': {
        justifyContent:"center"
    }
});



// Customizable Area End

import CfapplyforabankaccountController, {
    Props,
    configJSON,
} from "./CfapplyforabankaccountController";

export default class CfapplyforabankaccountOperator extends CfapplyforabankaccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={webStyle.userSettingContainer}>
                <OperatorSideBar />
                <ApplyLoanBoxLanding style={webStyle.providerSecondContainer}>
                    <Box style={{ width: '100%' }}>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Box style={{ padding: "0px 40px 0px 40px" }}>
                        <ApplyLoanBackMain data-test-id={'backTextID'} onClick={this.handleLandingPageFirst} >
                            <img src={leftArrow} style={webStyle.backArrow} data-test-id={'backImageID'} />
                            <Typography style={webStyle.backText} >Apply for Bank account</Typography>
                        </ApplyLoanBackMain>
                        <ApplyLoanBox>
                            <ApplyLoanWallet>
                                {configJSON.selectBankHeadingText}
                            </ApplyLoanWallet>

                        </ApplyLoanBox>
                    </Box>

                    <MainBox>
                        <a href="https://www.absa.co.za/personal/" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img data-test-id="absabank" src={absaBank} style={webStyle.bankDynamicIcon} />
                            </Box>
                        </a>

                        <a href="https://www.africanbank.co.za/en/home/" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={africanBank} style={{...webStyle.bankIcon,height:"100px"}} />
                            </Box>
                        </a>
                        <a href="https://www.bidvestbank.co.za/" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={bidvestBank} style={webStyle.bankIcon} />
                            </Box>
                        </a>
                        <a href="https://www.discovery.co.za/bank/join-discovery-bank/?utm_source=google&utm_medium=cpc&utm_campaign=Discovery_Bank_Branded_Campaign&utm_content=Discovery+Bank+Exact+and+Phrase+Match&utm_ad=497289941900&utm_term=discovery+bank&g_network=g%60&gad_source=1&gclid=Cj0KCQiAqsitBhDlARIsAGMR1RhTlAWvL24RZmCrdREdeZXgGmZxjqw0pF_7BAO5Kq5v8mZEMdjE4FcaAjwXEALw_wcB&gclsrc=aw.ds" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={discoveryBank} style={webStyle.bankIcon} />
                            </Box>
                        </a>
                        <a href="https://www.africanbank.co.za/en/home/" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={CapitecBank} style={webStyle.bankIcon} />
                            </Box>
                        </a>
                        <a href="https://www.fnb.co.za/index.html" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={FNBBank} style={{...webStyle.bankDynamicIcon,width:"130px",height:"55px"}} />
                            </Box>
                        </a>

                        <a href="https://www.investec.com/en_za.html?section=Home" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={investEC} style={webStyle.bankIcon} />
                            </Box>
                        </a>
                        <a href="https://www.tymebank.co.za/" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={TymeBank} style={{...webStyle.bankIcon,height:"70px"}} />
                            </Box>
                        </a>
                        <a href="https://personal.nedbank.co.za/home.html" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={nedBank} style={{...webStyle.bankIcon,height:"65px",width:"150px"}} />
                            </Box>
                        </a>
                        <a href="https://www.standardbank.co.za/southafrica/personal/products-and-services/ways-to-bank/self-service-banking/mobile-app-banking?cid=WPS_rDNEz&gad_source=1&gclid=CjwKCAjwupGyBhBBEiwA0UcqaNv4OlkUCLpgF1mekUfld6TZTCxu7vpk0AIb7LBsK6O6nShAzV_cjRoCNEcQAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noopener noreferrer">
                            <Box style={webStyle.dynamicImageBox}>
                                <img src={standardBank} style={{...webStyle.bankIcon,height:"55px"}} />
                            </Box>
                        </a>
                    </MainBox>
                    
                    
                    <Box style={{ marginBottom: '30px' }} />
                </ApplyLoanBoxLanding >
            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    userSettingContainer: {
        backgroundColor: "#E2E8F0"
    },
    providerSecondContainer:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: "#E2E8F0",
        } as React.CSSProperties,

    backArrow: {
        width: '24px',
        height: '24px',
        cursor: 'pointer'
    },
    bankIcon: {
        width: '179px',
        height: '40px',
        cursor: 'pointer',
    },
    bankDynamicIcon: {
        width: '70px',
        height: '70px',
        cursor: 'pointer'
    },
    dynamicImageBox: {
        width: '209px',
        height: '108px',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    filterText: {
        display: 'flex',
        gap: 16,
        width: "60%"
    },
    backText: {
        color: '#000',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer',
        marginLeft: '5px'
    },

}


// Customizable Area End
