import React from "react";

import {
    Box,
    Card,
    CardContent,
    Typography,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import Header from "../../../components/src/Header.web"
import SideNavbar from "../../../components/src/SideBar.web"
import { BackIcn } from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { Toaster } from "react-hot-toast"


const RightBoxLandingCommon = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

export default class RegisterNewOperator extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const renderInputField = (index: number, name: string, label: string) => (
            <Box style={webStyle.fieldsMain} key={name}>
                <label style={webStyle.labelStyle as React.CSSProperties}>{label}</label>
                <input
                    type="text"
                    name={name}
                    value={this.state.cardData[index][name]}
                    maxLength={name === "Registerposition"? 20 : undefined}
                    style={{
                        MozAppearance: "textfield",
                        borderColor: this.state.requiredInput.includes(`${name}_${index}`) ? 'red' : '#273567',
                        ...webStyle.inputStyleAdd,
                    }}
                    onChange={(e: any) => name === "RegisterphoneNumber" ? this.handlePhoneNumberChange2(index, e) : this.handleInputChanges3(index, e)}
                />
                {this.state.requiredInput.includes(`${name}_${index}`) && <div style={{ color: 'red', marginTop: '5px', marginBottom: "-23px", fontFamily: "poppins", fontSize: "12px", fontWeight: 400 }}>{`${label} is required`}</div>}
                {name === "RegisterphoneNumber" && !this.state.cardData[index].isValidNumber && (
                    <div style={{ color: 'red', marginTop: '5px', fontFamily: "poppins", fontSize: "12px", fontWeight: 400, marginBottom: "-23px" }}>
                        {this.state.cardData[index].errorPhoneMessage}
                    </div>
                )}
            </Box>
        );

        const { numCards } = this.state;
        const cards = [];

        for (let i = 0; i < numCards; i++) {
            cards.push(
                <Card style={{ margin: "20px 0" }} key={i}>
                    <CardContent style={{ padding: "20px", maxWidth: "100%" }}>
                        <Typography style={webStyle.registerText as React.CSSProperties}>Register as Operator</Typography>
                        <Box style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                            <Box style={{ margin: "10px" }}>
                                {renderInputField(i, "Registerfullname", "Full Name")}
                            </Box>
                            <Box style={{ margin: "10px" }}>
                                {renderInputField(i, "Registerposition", "Position")}
                            </Box>
                            <Box style={{ margin: "10px" }}>
                                {renderInputField(i, "RegisterphoneNumber", "Phone Number")}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            );
        }

        return (
            <Box style={{ display: "flex" }}>
                <SideNavbar />
                <RightBoxLandingCommon style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                    <Box style={webStyle.drawerBox}>
                        <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <Header />
                    <Box style={{ padding: "20px 40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <Box style={{ cursor: "pointer", marginBottom: "30px" }} data-test-id="addoperator" onClick={() => this.handlenavigateOperator()}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500 }}>
                                <img src={BackIcn} alt="back-icn" />
                                Back
                            </Typography>
                        </Box>
                        <Box style={webStyle.buttonContainer as React.CSSProperties}>
                            <Box style={webStyle.noButton as React.CSSProperties} onClick={this.addCard}>
                                <Typography style={webStyle.buttonText}>Add More</Typography>
                            </Box>
                            <Box style={webStyle.yesButton as React.CSSProperties} onClick={this.handleSubmitAll}>
                                <Typography style={webStyle.buttonText}>Submit All</Typography>
                            </Box>
                        </Box>
                        {cards}
                    </Box>
                    <Toaster position="top-center" />
                </RightBoxLandingCommon>
            </Box>
        );
    }
}

// Customizable Area Start
const webStyle = {

    fieldsMain: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "70px"
    } as React.CSSProperties,
    // label styling
    labelStyle: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },

    buttonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    } as React.CSSProperties,

    yesButton: {
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "134px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: "Poppins !important",
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#ffffff",
    } as React.CSSProperties,

    noButton: {
        borderRadius: "8px",
        border: "1px solid #C12030",
        width: "100%",
        maxWidth: "134px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: "Poppins !important",
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#C12030",
        marginRight: "10px"
    } as React.CSSProperties,

    // input styling
    inputStyleAdd: {
        color: '#273567',
        top: '26px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        padding: "15px",
        height: '40px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    registerText: {
        paddingLeft:'10px',
        color: "#000000",
        fontSize: "20px",
        fontFamily: "Poppins",
        fontWeight: "500",
        lineHeight: "40px"
    },
    buttonText: {
        fontFamily: "Poppins",
    },

    drawerBox: { width: '100%', backgroundColor: "rgb(226, 232, 240)" } as React.CSSProperties,

}
// Customizable Area End