import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Card,
  styled,
} from "@material-ui/core";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";
const RightBox = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})
const CommonCard = styled(Card)({
  padding: '30px',
  borderRadius: '12px',
  alignItems: 'flex-start',
  margin: '0 30px 30px',
  '@media(max-width: 600px)': {

    padding: '10px',
    borderRadius: '12px',
    alignItems: 'flex-start',
    marginTop: '10px'      
  },
  '@media (min-width: 600px) and (max-width: 992px)': {
    padding: '30px',
    borderRadius: '12px',
    alignItems: 'flex-start',
    marginTop: '10px'    
  },
})

const CommonBox = styled(Box)({
  width: '100%',
  '@media(max-width: 600px)': {
    marginLeft: '0px' ,
  },
  '@media(max-width: 952px)': {
    marginLeft: '0px' ,
    width:'100%'
  },
})
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: 'flex' }}>
        <SideNavbar />
        <RightBox style={webStyle.secondContainer}>
          <Box style={{ width: '100%' }}>
            <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
          </Box>


          <CommonBox style={{ marginTop: '0px' }}>
            <CommonCard style={webStyle.cardStyle}>
              <Typography style={webStyle.headText}>Today</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subText}>Deliverer Today</Typography>
                <Typography style={webStyle.subText}>10:30 PM</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextOrder}>Check Your Oders</Typography>
                <Typography style={webStyle.subTextTime}>04:40 AM</Typography>
              </Box>
              <Typography style={webStyle.headText}>22 Sep 2023</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subText}>Deliverer Today</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextOrder}>See New Items</Typography>
                <Typography style={webStyle.subTextTime}>Sep 22</Typography>
              </Box>
              <Typography style={webStyle.headText}>24 Sep 2023</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDelivery}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDelivery}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Typography style={webStyle.headTextTwo}>24 Sep 2023</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDeliveryTwo}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDelivery}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Typography style={webStyle.headText}>24 Sep 2023</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDelivery}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDeliveryTwo}>Your Order Delivered</Typography>
                <Typography style={webStyle.subTextTwo}>Sep 22</Typography>
              </Box>
              <Typography style={webStyle.headText}>24 Sep 2023</Typography>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDelivery}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
              <Box style={webStyle.firstBox}>
                <Typography style={webStyle.subTextDeliveryTwo}>Your Order Delivered</Typography>
                <Typography style={webStyle.subText}>Sep 22</Typography>
              </Box>
            </CommonCard>
          </CommonBox>
        </RightBox>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  secondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
      // height:'1100px',
      // padding:30,
      height:"100%"
    } as React.CSSProperties,
  cardStyle: {
    // maxWidth: '1189px',
    // width: '100%',
    // padding: '30px',
    // borderRadius: '12px',
    // alignItems: 'flex-start',
    // marginTop: '30px'
  } as React.CSSProperties,
  headText: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginTop:'15px',

  } as React.CSSProperties,
  headTextTwo: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginTop:'15px',

  } as React.CSSProperties,
  subText: {
    color: '#848FAC',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  subTextTwo: {
    color: '#848FAC',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  subTextDelivery: {
    color: '#0F172A',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  subTextDeliveryTwo: {
    color: '#0F172A',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  firstBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '8px',
    borderRadius: '8px',
    marginTop: '15px',
    backgroundColor: 'rgba(132, 143, 172, 0.1)'
  } as React.CSSProperties,
  subTextOrder: {
    color: '#000',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  subTextTime: {
    color: '#273567',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  }
};
// Customizable Area End
