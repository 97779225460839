import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast"
import { ChangeEvent } from "react";

const notify = () => toast.success('Update Successful', {
  style: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
});

const errorNotify = () => toast.error('All fields are required', {
  style: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: "Poppins",
  },
});
interface StoreItem {
  StoreTItle: string,
  Refrence: string,
  RefValue: string,
  Commissions: string,
  CommiValue: string,
  Status: string,
  StatusName: string,
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  errorPhoneMessage: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  billingArray: any
  accountName: any;
  invalidInputs: any;
  passportIdNumberId: any;
  ethinicity: any;
  errorMessage: any;
  isValidNumber: boolean;
  accountNumber: any;
  licenseSubscription: any;
  maintenanceSubscription: any;
  showBillingSettingForm: boolean;
  billingCardIndex: any
  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;
  billingDetails: any;
  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  activeTab: any;
  uploadedFileName: any;
  uploadedFileName1: any;
  isEmailValid: boolean;
  showValidationMessage: boolean;
  fileInputTouched: boolean;
  inputValue: string;
  uploadedFileName2: any;
  settinTab: any;
  statetoastSta: any;
  showUploadModal: any;
  uploadedFileName3: any;
  uploadedFileName4: any;
  requiredInput: any
  requiredInput2: any;
  surName: any;
  passport: any;
  storename: any;
  storeref: any;
  storeAddress: any;
  typeStore: any;
  uifNumber: any;
  registrationstatus: any;
  vatStatus: any;
  kycDocumentation: any;
  cipro: any;
  contactDetail: any;
  emailAddress: any;
  nextOfKin: any;
  homeAddress: any;
  idImage: any;
  merchantAgreement: any;
  updateCoordinates: any;
  complianceReport: any;
  municipalPermit: any;
  registeredBusiness: any;
  vatRegistration: any;
  settinOperatorTab: any;
  tickModel: boolean;
  isPhoneNumberValid: boolean;
  AddOperator: any;
  nameOp: any;
  removeOprat: any;
  passportId: any;
  citizenStatus: any;
  storeName: any;
  whatsappNumber: any;
  isWhatsappNumber: boolean;
  address: any;
  coordinates: any;
  phoneNumberOp: any;
  nextOfKinOp: any;
  emailAddressOp: string;
  ethnicity: any;
  gender: any;
  positions: any;
  cardData: any;
  numCards: any;
  Registerfullname: any;
  Registerposition: any;
  RegisterphoneNumber: any;
  errorMessages: any;
  nextOfKinError: string;
  passportError: string;
  passportIdError: string;
  passportIdNumberIdError: string;
  merchantAgreementError: string;
  isUifNumberValid: boolean;
  storeSettingList: any;
  operatorSettingDetail: any
  operatorStoreDetail: any,
  userInfo: any,
  loadingUser: boolean,
  errorUserAPI: any,
  usersInfo: any,
  listOfStores: any[];
  initialStores: number;
  operatorData:any[];
  setEmail:any;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  apiCallMessageGetStoreSettings: any
  apiCallMessageGetStoreSettingDetail: any
  apiCallMessageGetBillingSettingDetail: any
  apiCallMessageUpdateStoreSettingDetail: any
  apiCallMessageAddMyStore: any
  apiCallMessageAddOtherStore: any
  apiCallMessageGetOperatorStoreDetail: any
  apiCallMessageGetOperatorSettingDetail: any
  apiCallMessageUpdateOperatorSettingDetail: any
  apiCallMessageRegisterNewOperators: any
  apiCallMessageDeleteOperator: any
  apiCallMessageApproveOperator: any
  apiCallMessageGetUserInformation: any
  apiCallMessageUpdateUserInformation: any
  apiCallMessageGetUserDocument: any
  apiCallMessageUploadUserDocument: any
  apiCallMessageGetUserOperatorInformation: any
  apiCallMessageUpdateUserOperatorInformation: any
  apiCallMessageGetUserOperatorDocument: any
  getUserInfoCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "+27 ",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      cardData: [
        {
          Registerfullname: "",
          Registerposition: "",
          RegisterphoneNumber: "+27 ",
          isValidNumber: true,
          errorPhoneMessage: ""
        }
      ],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      errorPhoneMessage: "",
      invalidInputs: [],
      ethinicity: "",
      errorMessage: "",
      isValidNumber: false,
      isWhatsappNumber: false,
      billingArray: [
        {
          name: "Khandal saree store, 402",
          reference_number: "4029997",
          banner: "https://picsum.photos/seed/picsum/200/300",
          commissions: "3456",
          loan_status: "Approve"
        },
        {
          reference_number: "402999",
          commissions: "3456",
          name: "Khandal saree store, 404",
          loan_status: "Approve",
          banner: "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
        },
        {
          reference_number: "402999",
          commissions: "3456",
          loan_status: "Approve",
          banner: "https://picsum.photos/200/300/?blur=2",
          name: "Khandal saree store, 405",
        },
        {
          banner: "https://picsum.photos/200/300",
          name: "Khandal saree store, 406",
          reference_number: "402999",
          commissions: "3456",
          loan_status: "Approve",
        },
        {
          name: "Khandal saree store, 401",
          banner: "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350",
          reference_number: "402999",
          commissions: "34560",
          loan_status: "Approve",
        },
        {
          name: "Khandal saree store, 407",
          commissions: "34561",
          loan_status: "Approve",
          banner: "https://picsum.photos/200",
          reference_number: "402999",
        }
      ],
      showBillingSettingForm: false,
      billingCardIndex: '',
      billingDetails: {},
      whatsappNumber: "",
      passportIdNumberId: "",
      accountName: "123456",
      accountNumber: "123456",
      licenseSubscription: "Monthly",
      maintenanceSubscription: "Monthly",
      edtEmailEnabled: true,
      isEmailValid: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      activeTab: "Documents",
      uploadedFileName: null,
      uploadedFileName1: null,
      showValidationMessage: false,
      fileInputTouched: false,
      inputValue: "",
      uploadedFileName2: null,
      settinTab: "UserInformation",
      statetoastSta: false,
      showUploadModal: false,
      uploadedFileName3: null,
      uploadedFileName4: null,
      requiredInput: [],
      requiredInput2: [],
      surName: "",
      passport: "",
      storename: "",
      storeref: "",
      typeStore: "",
      storeAddress: "",
      uifNumber: "",
      registrationstatus: "",
      vatStatus: "",
      contactDetail: "+27 ",
      kycDocumentation: null,
      tickModel: false,
      cipro: "",
      emailAddress: "",
      nextOfKin: "",
      homeAddress: "",
      idImage: "",
      isPhoneNumberValid: true,
      merchantAgreement: "",
      updateCoordinates: "",
      complianceReport: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      municipalPermit: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      registeredBusiness: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      vatRegistration: {
        isCheckedYes: false,
        isCheckedNo: false,
      },
      settinOperatorTab: "UserProfileOperator",
      AddOperator: "",
      nameOp: "",
      removeOprat: false,
      passportId: "",
      citizenStatus: "",
      storeName: "",
      address: "",
      coordinates: "",
      phoneNumberOp: "+27 ",
      nextOfKinOp: "",
      emailAddressOp: "",
      ethnicity: "",
      gender: "",
      positions: "",
      numCards: 1,
      Registerfullname: "",
      Registerposition: "",
      RegisterphoneNumber: "",
      errorMessages: {
        field1: "",
        field2: "",
        field3: "",
        field4: ""
      },
      nextOfKinError: "",
      merchantAgreementError: "",
      passportError: "",
      passportIdError: "",
      passportIdNumberIdError: '',
      isUifNumberValid: true,
      storeSettingList: [],
      operatorSettingDetail: {},
      operatorStoreDetail: {},
      userInfo: "",
      loadingUser: true,
      errorUserAPI: "",
      usersInfo: "",
      listOfStores: [
        {
          StoreTItle: "Khandal saree store, 403",
          Refrence: "Reference Number",
          RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status",
          StatusName: "Approve",

        },
        {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number", RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status", StatusName: "Approve",

        }, 
        {
          StoreTItle: "Khandal saree store, 403 ", Refrence: "Reference Number", RefValue: "A4D0V2",
          Commissions: "Over all Commissions", CommiValue: "3456", Status: "Loan status", StatusName: "Approve",
        },
        {
          StoreTItle: "Khandal saree store, 403",
          Refrence: "Reference Number",
          RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status",
          StatusName: "Approve",

        },
        {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number", RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status", StatusName: "Approve",

        }, 
        {
          StoreTItle: "Khandal saree store, 403 ", Refrence: "Reference Number", RefValue: "A4D0V2",
          Commissions: "Over all Commissions", CommiValue: "3456", Status: "Loan status", StatusName: "Approve",
        }, {
          StoreTItle: "Khandal saree store, 403 ", Refrence: "Reference Number", RefValue: "A4D0V2", Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status", StatusName: "Approve",
        }, {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number", RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456", Status: "Loan status",
          StatusName: "Approve",
        }, {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number",
          RefValue: "A4D0V2", Commissions: "Over all Commissions", CommiValue: "3456",
          Status: "Loan status",
          StatusName: "Approve",
        },
        {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number", RefValue: "A4D0V2", Commissions: "Over all Commissions", CommiValue: "3456", Status: "Loan status", StatusName: "Approve",
        }, {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number", RefValue: "A4D0V2", Commissions: "Over all Commissions",
          CommiValue: "3456", Status: "Loan status", StatusName: "Approve",
        },
        {
          StoreTItle: "Khandal saree store, 403 ",
          Refrence: "Reference Number",
          RefValue: "A4D0V2",
          Commissions: "Over all Commissions",
          CommiValue: "3456",
          Status: "Loan status",
          StatusName: "Approve",

        },
      ],
      initialStores: 8,
      operatorData:[
        {
            Operator: "John Doe",
            OperatorEmail: "john.doe@example.com",
        },
        {
            Operator: "Jane Smith",
            OperatorEmail: "jane.smith@example.com",
        },
        {
            Operator: "Alice Johnson",
            OperatorEmail: "alice.johnson@example.com",
        },
        {
            Operator: "Bob Brown",
            OperatorEmail: "bob.brown@example.com",
        },
        {
            Operator: "Charlie Davis",
            OperatorEmail: "charlie.davis@example.com",
        },
        {
            Operator: "Diana Evans",
            OperatorEmail: "diana.evans@example.com",
        },
        {
            Operator: "Edward Green",
            OperatorEmail: "edward.green@example.com",
        },
        {
            Operator: "Fiona Harris",
            OperatorEmail: "fiona.harris@example.com",
        },
        {
            Operator: "George Irving",
            OperatorEmail: "george.irving@example.com",
        },
        {
            Operator: "Hannah Johnson",
            OperatorEmail: "hannah.johnson@example.com",
        },
    ],
    setEmail:""  
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    const messageId = message.id;

    if (this.isCountryCodeMessage(messageId)) {
      this.handleCountryCodeMessage(message);
    }

    if (this.isRestAPIResponseMessage(messageId)) {
      this.handleRestAPIResponseMessage(message);
    }

    if (this.isSessionResponseMessage(messageId)) {
      this.handleSessionResponseMessage(message);
    }
    // New code for handling user info response
    if (messageId === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId === this.getUserInfoCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors) {
          this.setState({ userInfo: responseJson, loadingUser: false });
        } else {
          this.setState({
            errorUserAPI: 'Failed to fetch user info',
            loadingUser: false
          });
        }
      }
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start

  isCountryCodeMessage(messageId: string): boolean {
    return getName(MessageEnum.CountryCodeMessage) === messageId;
  }

  handleCountryCodeMessage(message: Message) {
    const selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      this.setState({
        currentCountryCode: selectedCode.includes("+") ? selectedCode.split("+")[1] : selectedCode
      });
    }
  }

  isRestAPIResponseMessage(messageId: string): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === messageId;
  }

  handleRestAPIResponseMessage(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.validationApiCallId:
          this.handleValidationApiCall(responseJson);
          break;
        case this.userProfileGetApiCallId:
          this.handleUserProfileGetApiCall(responseJson, errorResponse);
          break;
        case this.apiChangePhoneValidation:
          this.handleChangePhoneValidation(responseJson, errorResponse);
          break;
        case this.apiCallMessageUpdateProfileRequestId:
          this.handleUpdateProfileRequest(responseJson);
          break;
        case this.apiCallMessageGetStoreSettings:
          this.handleGetStoreSettings(responseJson);
          break;
        case this.apiCallMessageGetStoreSettingDetail:
          this.handleMessageGetStoreSettingDetail(responseJson)
          break;
        case this.apiCallMessageUpdateStoreSettingDetail:
          this.handleMessageUpdateStoreSettingDetail(responseJson)
          break;
        case this.apiCallMessageAddMyStore:
        case this.apiCallMessageAddOtherStore:
          this.handleMessageAddStore(responseJson)
          break;
        case this.apiCallMessageGetBillingSettingDetail:
          this.handleMessageGetBillingSettingDetail(responseJson)
          break;
        case this.apiCallMessageGetOperatorStoreDetail:
          this.handleMessageGetOperatorStoreDetail(responseJson)
          break;
        case this.apiCallMessageGetOperatorSettingDetail:
          this.handleMessageGetOperatorSettingDetail(responseJson)
          break;
        case this.apiCallMessageUpdateOperatorSettingDetail:
          this.handleMessageUpdateOperatorSettingDetail(responseJson)
          break;
        case this.apiCallMessageRegisterNewOperators:
          this.handleMessageRegisterNewOperator(responseJson)
          break;
        case this.apiCallMessageDeleteOperator:
          this.handleMessageDeleteOperator(responseJson)
          break;
        case this.apiCallMessageApproveOperator:
          this.handleMessageApproveOperator(responseJson)
          break;
        case this.apiCallMessageGetUserInformation:
          this.handleMessageGetUserInformation(responseJson)
          break;
        case this.apiCallMessageUpdateUserInformation:
          this.handleMessageUpdateUserInformation(responseJson)
          break;
        case this.apiCallMessageGetUserDocument:
          this.handleMessageGetUserDocument(responseJson)
          break;
        case this.apiCallMessageUploadUserDocument:
          this.handleMessageUserUploadDocument(responseJson)
          break;
        case this.apiCallMessageGetUserOperatorInformation:
          this.handleMessageGetUserOperatorInformation(responseJson)
          break;
        case this.apiCallMessageUpdateUserOperatorInformation:
          this.handleMessageUpdateUserOperatorInformation(responseJson)
          break;
        case this.apiCallMessageGetUserOperatorDocument:
          this.handleMessageGetUserOperatorDocument(responseJson)
          break;
        case this.getUserInfoCallId:
          this.handleMessageGetUserInfo(responseJson)
          break;
        default:
          break;
      }
    }
  }

  handleValidationApiCall(responseJson: any) {
    this.arrayholder = responseJson.data;
    if (this.arrayholder && this.arrayholder.length !== 0) {
      const regexData = this.arrayholder[0];
      if (regexData) {
        if (regexData.password_validation_regexp) {
          this.passwordReg = new RegExp(regexData.password_validation_regexp);
        }
        if (regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
        if (regexData.password_validation_rules) {
          this.setState({
            passwordHelperText: regexData.password_validation_rules
          });
        }
      }
    }
  }

  handleUserProfileGetApiCall(responseJson: any, errorResponse: any) {
    if (responseJson && !responseJson.errors && responseJson.data && responseJson.data.attributes) {
      this.updateUserProfile(responseJson.data.attributes);
    } else {
      this.handleErrorResponse(responseJson, errorResponse);
    }
  }

  handleMessageGetUserInfo(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({ usersInfo: responseJson.data });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  updateUserProfile(userAttr: any) {
    const { email, first_name: firstName, last_name: lastName, country_code: currentCountryCode, phone_number: phoneNumber = "" } = userAttr;

    this.setState({
      email,
      firstName,
      lastName,
      phoneNumber,
      currentCountryCode
    });

    //@ts-ignore
    this.txtInputFirstNameProps.value = firstName;
    //@ts-ignore
    this.txtInputLastNameProps.value = lastName;
    //@ts-ignore
    this.txtInputEmailProps.value = email;
    //@ts-ignore
    this.txtInputPhoneNumberProps.value = phoneNumber;

    this.registrationAndLoginType = userAttr.type;

    this.updateAccountTypeSettings(this.registrationAndLoginType);
  }

  updateAccountTypeSettings(accountType: string) {
    if (accountType === configJSON.ACCOUNT_TYPE_EMAIL) {
      this.setState({
        edtEmailEnabled: false,
        llChangePwdDummyShowContainerVisible: true
      });
      this.txtInputEmailProps.editable = false;
    } else if (accountType === configJSON.ACCOUNT_TYPE_SOCIAL) {
      this.setState({
        edtEmailEnabled: false,
        llChangePwdDummyShowContainerVisible: false,
        llDoChangePwdContainerVisible: false
      });
      this.txtInputEmailProps.editable = false;
    } else if (accountType === configJSON.ACCOUNT_TYPE_PHONE) {
      this.setState({
        llChangePwdDummyShowContainerVisible: true,
        edtMobileNoEnabled: false,
        countryCodeEnabled: false
      });
      this.txtInputPhoneNumberProps.editable = false;
    }
  }

  handleErrorResponse(responseJson: any, errorResponse: any) {
    if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }

  handleChangePhoneValidation(responseJson: any, errorResponse: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      this.validateAndUpdateProfile();
    } else {
      this.handleErrorResponse(responseJson, errorResponse);
    }
  }

  handleUpdateProfileRequest(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      this.showAlert("Success", "Profile updated successfully.");
      this.enableDisableEditPassword(false);
      this.getUserProfile();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleGetStoreSettings(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({ storeSettingList: responseJson.data });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetStoreSettingDetail(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      this.setState({
        storename: responseJson.data.storename,
        storeref: responseJson.data.storeref,
        storeAddress: responseJson.data.storeAddress,
        typeStore: responseJson.data.typeStore,
        uifNumber: responseJson.data.uifNumber,
        registrationstatus: responseJson.data.registrationstatus,
        vatStatus: responseJson.data.vatStatus,
        kycDocumentation: responseJson.data.kycDocumentation,
        cipro: responseJson.data.cipro,
        contactDetail: responseJson.data.contactDetail,
        emailAddress: responseJson.data.emailAddress,
        nextOfKin: responseJson.data.nextOfKin,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageUpdateStoreSettingDetail(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      return notify()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageAddStore(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      return this.setState({ tickModel: true });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetBillingSettingDetail(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      return this.setState({ showBillingSettingForm: true, billingDetails: responseJson.data })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetOperatorStoreDetail(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      return this.setState({ operatorStoreDetail: responseJson.data })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetOperatorSettingDetail(responseJson: any) {
    if (responseJson != null && responseJson.errors === undefined) {
      return this.setState({ operatorSettingDetail: responseJson.data })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetUserInformation(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      return this.setState({
        firstName: responseJson.firstName,
        passportIdNumberId: responseJson.passportIdNumberId,
        citizenStatus: responseJson.citizenStatus,
        homeAddress: responseJson.homeAddress,
        phoneNumber: responseJson.phoneNumber,
        nextOfKin: responseJson.nextOfKin,
        email: responseJson.email,
        ethnicity: responseJson.ethnicity,
        gender: responseJson.gender,
        nextOfKinError: responseJson.nextOfKinError,
        isValidNumber: false,
        invalidInputs: [],
        complianceReport: { isCheckedYes: false, isCheckedNo: false },
        municipalPermit: { isCheckedYes: false, isCheckedNo: false },
        registeredBusiness: { isCheckedYes: false, isCheckedNo: false },
        vatRegistration: { isCheckedYes: false, isCheckedNo: false },
        merchantAgreementError: '',
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageUpdateUserInformation(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      return notify()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetUserDocument(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      return this.setState({
        uploadedFileName1: responseJson.uploadedFileName1,
        uploadedFileName2: responseJson.uploadedFileName2,
        uploadedFileName3: responseJson.uploadedFileName3,
        uploadedFileName4: responseJson.uploadedFileName4,
      })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageUserUploadDocument(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      return
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageGetUserOperatorInformation(res: any) {
    if (res != null && res.error === undefined) {
      return
    } else {
      this.parseApiErrorResponse(res);
    }
  }
  handleMessageUpdateUserOperatorInformation(res: any) {
    if (res != null && res.error === undefined) {
      return this.setState({
        firstName: res.firstName,
        passportIdNumberId: res.passportIdNumberId,
        citizenStatus: res.citizenStatus,
        homeAddress: res.homeAddress,
        phoneNumber: res.phoneNumber,
        nextOfKin: res.nextOfKin,
        email: res.email,
        ethnicity: res.ethnicity,
        gender: res.gender,
        nextOfKinError: res.nextOfKinError,
      });
    } else {
      this.parseApiErrorResponse(res);
    }
  }
  handleMessageGetUserOperatorDocument(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      return this.setState({
        uploadedFileName1: responseJson.uploadedFileName1,
      })
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  isSessionResponseMessage(messageId: string): boolean {
    return getName(MessageEnum.SessionResponseMessage) === messageId;
  }

  handleSessionResponseMessage(message: Message) {
    const requesterId = message.getData(getName(MessageEnum.SessionRequestedBy));
    if (requesterId === this.uniqueSessionRequesterId) {
      const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
      this.authToken = sessionToken;
      this.getUserProfile();
    }
  }
  handleMessageUpdateOperatorSettingDetail(responseJson: any) {
    if (responseJson != null && responseJson.error === undefined) {
      notify()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleMessageRegisterNewOperator(response: any) {
    if (response != null && response.error === undefined) {
      notify()
    } else {
      this.parseApiErrorResponse(response);
    }
  }
  handleMessageDeleteOperator(rspJson: any) {
    if (rspJson.error === undefined && rspJson != null) {
      return
    } else {
      this.parseApiErrorResponse(rspJson);
    }
  }
  handleMessageApproveOperator(response: any) {
    if (response.error === undefined && response != null) {
      return
    } else {
      this.parseApiErrorResponse(response);
    }
  }
  handlePhoneNumberChangeUserProfile = (e: any) => {
    // Getting the value of input
    let inputPhoneN = e.target.value;
    // Replacing non-digit characters except '+'
    inputPhoneN = inputPhoneN.replace(/[^\d+]/g, '');
    // Digits only
    const digitsOnl = inputPhoneN.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';

    switch (true) {
      case digitsOnl.length > 7:
        formattedNumber += ' ' + digitsOnl.slice(2, 4) + ' ' + digitsOnl.slice(4, 7) + ' ' + digitsOnl.slice(7, 11);
        break;
      case digitsOnl.length > 4:
        formattedNumber += ' ' + digitsOnl.slice(2, 4) + ' ' + digitsOnl.slice(4, 7);
        break;
      case digitsOnl.length > 2:
        formattedNumber += ' ' + digitsOnl.slice(2, 4);
        break;
    }
    //check the length
    const isValidNu = digitsOnl.length === 11;
    this.setState({
      phoneNumber: formattedNumber,
      isValidNumber: isValidNu,
      errorPhoneMessage: isValidNu ? '' : 'Invalid Phone Number',
      invalidInputs: isValidNu
        ? this.state.invalidInputs.filter((input: any) => input !== 'phoneNumber')
        : [...this.state.invalidInputs, 'phoneNumber']
    });
  };
  handlePhoneNumberChange2 = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    let inputPhoneNumber = e.target.value;
    inputPhoneNumber = inputPhoneNumber.replace(/[^\d+]/g, '');
    const digitsOnly = inputPhoneNumber.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';
    if (digitsOnly.length > 2) {
      formattedNumber += ' ' + digitsOnly.slice(2, 4);
    }
    if (digitsOnly.length > 4) {
      formattedNumber += ' ' + digitsOnly.slice(4, 7);
    }
    if (digitsOnly.length > 7) {
      formattedNumber += ' ' + digitsOnly.slice(7, 11);
    }
    const isValidNumber = digitsOnly.length === 11;

    const cardData = [...this.state.cardData];
    cardData[index].RegisterphoneNumber = formattedNumber;
    cardData[index].isValidNumber = isValidNumber;
    cardData[index].errorPhoneMessage = isValidNumber ? '' : 'Invalid Phone Number';

    this.setState({
      cardData,
      invalidInputs: isValidNumber ? this.state.invalidInputs.filter((input: any) => input !== `phoneNumber_${index}`) : [...this.state.invalidInputs, `phoneNumber_${index}`]
    });
  };

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };
  handleCardClick = (storeId: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessageGetBillingDetail = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageGetBillingDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `billingSettings/getDetail/${storeId}`
    );

    requestMessageGetBillingDetail.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessageGetBillingDetail.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.apiCallMessageGetBillingSettingDetail = requestMessageGetBillingDetail.messageId;

    runEngine.sendMessage(requestMessageGetBillingDetail.id, requestMessageGetBillingDetail);
    this.setState({ showBillingSettingForm: true, })
  }
  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }

  handleTabClick = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  handleSettingTabs = (setab: string) => {
    this.setState({ settinTab: setab });
  };

  handleSettingOperatorTabs = (setab: string) => {
    this.setState({ settinOperatorTab: setab });
  };

  trimFileName = (fileName: string, maxLength: number = 20): string => {
    if (fileName.length <= maxLength) return fileName;

    const extension = fileName.split('.').pop();
    const nameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));

    const trimmedName = nameWithoutExtension.substring(0, maxLength - 3 - (extension?.length || 0));
    return `${trimmedName}...${extension}`;
  }

  handleMultipleFileChangeVac = (fieldNumber: number, e: any) => {
    const fileSec = e?.target?.files ? e.target.files[0] : null;
    this.setState({ showUploadModal: true });

    if (fileSec) {
      const validTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/img'];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      if (!validTypes.includes(fileSec.type)) {
        this.setState(prevState => ({
          showUploadModal: false,
          errorMessages: {
            ...prevState.errorMessages,
            [`field${fieldNumber}`]: "Please upload in correct format (.PDF / .JPEG / .JPG / .PNG / .IMG)"
          }
        }));
        return;
      }

      if (fileSec.size > maxSize) {
        this.setState(prevState => ({
          showUploadModal: false,
          errorMessages: {
            ...prevState.errorMessages,
            [`field${fieldNumber}`]: "Maximum Size allowed is 10Mb"
          }
        }));
        return;
      }
      const trimmedFileName = this.trimFileName(fileSec.name);
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        data: fileSec
      }
      const requestUserUploadDocumentMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUploadUserDocument = requestUserUploadDocumentMessage.messageId;
      requestUserUploadDocumentMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "userDocument/upload"
      );

      requestUserUploadDocumentMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUserUploadDocumentMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUserUploadDocumentMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestUserUploadDocumentMessage.id, requestUserUploadDocumentMessage);

      setTimeout(() => {
        this.setState(prevState => ({
          ...prevState,
          [`selectedCertificate${fieldNumber}`]: fileSec,
          [`uploadedFileName${fieldNumber}`]: trimmedFileName,
          fileInputTouched: true,
          showUploadModal: false,
          errorMessages: {
            ...prevState.errorMessages,
            [`field${fieldNumber}`]: ""
          }
        }));
      }, 1000);
    } else {

      this.setState({ showUploadModal: false });
    }
  };

  handleFileBlur = () => {
    this.setState({
      showValidationMessage: this.state.uploadedFileName1 === "" || this.state.uploadedFileName2 === "" || this.state.uploadedFileName3 === "" || this.state.uploadedFileName4 === "" || this.state.kycDocumentation === "",
      fileInputTouched: true,
    });
  }

  handleInputChange = (event: any) => {
    this.setState({
      inputValue: event.target.value,
      showValidationMessage: false,
    });
  };

  handleBlur = () => {
    // Check if the input is empty on blur
    if (!this.state.inputValue.trim()) {
      this.setState({ showValidationMessage: true });
    }
  };


  handleToast = () => {
    notify()
  };

  handleInputChanges = (e: any) => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validValue =value
    switch (name) {
      case 'nextOfKin':
        if (value.length > 50) {
          errorMessage = 'Next of Kin name should not exceed 50 characters';
        }
        break;

      case 'merchantAgreement':
        if (value.length > 250) {
          errorMessage = 'Merchant Agreement should not exceed 250 characters';
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          errorMessage = 'Merchant Agreement should only contain alphanumeric characters';
        }
        break;

        case 'passport':
        case'passportIdNumberId':
        validValue = value.replace(/\D/g, '');
          if (!/^\d+$/.test(value)) {
            errorMessage = 'ID/Passport should only contain numeric digits';
          }
        break;
  default:
    break;
}
  
this.setState((prevState: S) => ({
  
  [name]: validValue,
  invalidInputs: prevState.invalidInputs.filter((input: string) => input !== name),
  [`${name}Error`]: errorMessage
} as Pick<S, keyof S>));
  };

  handleInputChanges2 = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorMessage = '';
    let validValue2 =value
    switch (name) {
      case 'passportId':
        validValue2 = value.replace(/\D/g, '');
        if (!/^\d+$/.test(value)) {
          errorMessage = 'ID/Passport should only contain numeric digits';
        }
        break;
      default:
        break;
    }
    this.setState(prevState => ({
      ...prevState,
      [name]: validValue2,
  [`${name}Error`]: errorMessage
}));
  };

  handleInputChanges3 = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const cardData = [...this.state.cardData];
    if (name === 'Registerfullname') {
      const sanitizedValue = value.replace(/[^A-Za-z\s]/g, '');
      cardData[index][name] = sanitizedValue;
    } else {
      const sanitizedValue = value.replace(/[^A-Za-z0-9\s]/g, '');
      cardData[index][name] = sanitizedValue;
    }
    this.setState({ cardData });
  };

  validateOperatorInputs = () => {
    const requiredFields: (keyof S)[] = [
      'firstName', 'citizenStatus',
      'passportIdNumberId', 'phoneNumber', 'gender', 'ethnicity',
      'homeAddress', 'email', 'nextOfKin',
    ];

    const invalidInputs = requiredFields.filter(field => {
      const fieldValue = this.state[field];
      // Check if the field value is a string and trim it
      const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;

      if (field === 'phoneNumber' && trimmedValue === '+27') {
        return true;
      }

      // Check if the trimmed value is empty
      return !trimmedValue;
    });
    if (invalidInputs.length != 0) {
      errorNotify()
    }
    return invalidInputs;
  }



  validateInputs = () => {
    const requiredFields: (keyof S)[] = [
      'surName', 'passport', 'phoneNumber', 'nextOfKin', 'email', 'homeAddress', 'idImage', 'merchantAgreement', 'updateCoordinates',
    ];

    const invalidInputs = requiredFields.filter(field => {
      const fieldValue = this.state[field];
      const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;

      switch (field) {
        case 'phoneNumber':
          return !this.state.isValidNumber;

        case 'email':
          return !trimmedValue || !!this.state.errorMessage;

        case 'nextOfKin':
          return !trimmedValue || !!this.state.nextOfKinError;

        case 'merchantAgreement':
          return !trimmedValue || !!this.state.merchantAgreementError;

        default:
          return !trimmedValue;
      }
    });

    return invalidInputs;
  };
  validateInput = () => {
    const requiredFields: (keyof S)[] = [
      'storename', 'storeref', 'contactDetail', 'emailAddress', 'nextOfKin', 'storeAddress', 'typeStore', 'registrationstatus', 'updateCoordinates', 'vatStatus', 'uifNumber', 'kycDocumentation', 'cipro', 'nameOp'
    ];

    return requiredFields.filter(field => {
      const fieldValue = this.state[field];
      const trimmedValue = typeof fieldValue === 'string' ? fieldValue.trim() : fieldValue;
      return !trimmedValue;
    });
  };

  handlePassportValidation = (event: any) => {
    const inputValue = event.target.value;
    this.setState({
      passportIdNumberId: inputValue.slice(0, 10),
      // Remove 'passportIdNumberId' from invalidInputs if it's present and the field is not empty
      invalidInputs: inputValue.trim() ? this.state.invalidInputs.filter((input: any) => input !== 'passportIdNumberId') : this.state.invalidInputs
    });

  };


  handleSubmit = () => {
    const invalidInputs = this.validateInputs();

    if (invalidInputs.length === 0 && !this.state.errorMessage && this.state.isValidNumber) {
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        firstName: this.state.firstName,
        passportIdNumberId: this.state.passportIdNumberId,
        citizenStatus: this.state.citizenStatus,
        homeAddress: this.state.homeAddress,
        phoneNumber: this.state.phoneNumber,
        nextOfKin: this.state.nextOfKin,
        email: this.state.email,
        ethnicity: this.state.ethnicity,
        gender: this.state.gender,
        nextOfKinError: this.state.nextOfKinError,
      }
      const requestUpdateUserInformationMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateUserInformation = requestUpdateUserInformationMessage.messageId;
      requestUpdateUserInformationMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "userInformation/update"
      );

      requestUpdateUserInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUpdateUserInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUpdateUserInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestUpdateUserInformationMessage.id, requestUpdateUserInformationMessage);
      notify();
      this.setState({
        surName: '',
        passport: '',
        phoneNumber: '+27 ',
        nextOfKin: '',
        email: '',
        homeAddress: '',
        idImage: '',
        merchantAgreement: '',
        updateCoordinates: '',
        errorMessage: '',
        errorPhoneMessage: '',
        isValidNumber: false,
        invalidInputs: [],
        complianceReport: { isCheckedYes: false, isCheckedNo: false },
        municipalPermit: { isCheckedYes: false, isCheckedNo: false },
        registeredBusiness: { isCheckedYes: false, isCheckedNo: false },
        vatRegistration: { isCheckedYes: false, isCheckedNo: false },
        nextOfKinError: '',
        merchantAgreementError: '',
      });
    } else {
      this.setState({ invalidInputs });
      errorNotify();
    }
  };

  handleOperatorSubmit = () => {
    const invalidInputs = this.validateOperatorInputs();
    if (invalidInputs.length === 0 && !this.state.errorMessage && this.state.errorPhoneMessage === "") {
      // You can perform additional actions with the form data here
      const header = {
        "Content-Type": "application/json",
      };

      const requestUpdateUserOperatorInformationMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestUpdateUserOperatorInformationMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "userInformationOperator/update"
      );

      requestUpdateUserOperatorInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const body = {
        firstName: this.state.firstName,
        passportIdNumberId: this.state.passportIdNumberId,
        citizenStatus: this.state.citizenStatus,
        homeAddress: this.state.homeAddress,
        phoneNumber: this.state.phoneNumber,
        nextOfKin: this.state.nextOfKin,
        email: this.state.email,
        ethnicity: this.state.ethnicity,
        gender: this.state.gender,
        nextOfKinError: this.state.nextOfKinError,
      }
      requestUpdateUserOperatorInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUpdateUserOperatorInformationMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      this.apiCallMessageUpdateUserOperatorInformation = requestUpdateUserOperatorInformationMessage.messageId;

      runEngine.sendMessage(requestUpdateUserOperatorInformationMessage.id, requestUpdateUserOperatorInformationMessage);

      this.handleToast()
      this.setState({
        firstName: '',
        passportIdNumberId: '',
        citizenStatus: '',
        homeAddress: '',
        phoneNumber: '',
        nextOfKin: '',
        email: '',
        ethnicity: '',
        gender: '',
        nextOfKinError: '',
      });

    } else {
      this.setState({ invalidInputs });
    }
  };

  handleSubmit2 = () => {
    if (!this.state.storeName || !this.state.storeref || !this.state.contactDetail || !this.state.email || !this.state.nextOfKin || !this.state.storeAddress || !this.state.updateCoordinates || !this.state.typeStore || !this.state.cipro || !this.state.vatStatus || !this.state.uifNumber || !this.state.kycDocumentation) {
      errorNotify()
    } else {
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        storeName: this.state.storeName,
        storeref: this.state.storeref,
        contactDetail: this.state.contactDetail,
        email: this.state.email,
        nextOfKin: this.state.nextOfKin,
        storeAddress: this.state.storeAddress,
        updateCoordinates: this.state.updateCoordinates,
        typeStore: this.state.typeStore,
        cipro: this.state.cipro,
        vatStatus: this.state.vatStatus,
        uifNumber: this.state.uifNumber,
        kycDocumentation: this.state.kycDocumentation,
      }
      const requestUpdateStoreSettingDetailMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateStoreSettingDetail = requestUpdateStoreSettingDetailMessage.messageId;
      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "storeSettingDetail/update"
      );

      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestUpdateStoreSettingDetailMessage.id, requestUpdateStoreSettingDetailMessage);
      notify()
    }
  };
  handleSubmit3 = (isAddMyStore: boolean) => {
    if (!this.state.storeName || !this.state.contactDetail || !this.state.email || !this.state.nextOfKin || !this.state.storeAddress || !this.state.updateCoordinates || !this.state.typeStore || !this.state.cipro || !this.state.vatStatus || !this.state.uifNumber || !this.state.kycDocumentation) {
      errorNotify()
    } else {
      const header3 = {
        "Content-Type": "application/json",
      };
      const body3 = {
        storeName: this.state.storeName,
        contactDetail: this.state.contactDetail,
        email: this.state.email,
        nextOfKin: this.state.nextOfKin,
        storeAddress: this.state.storeAddress,
        updateCoordinates: this.state.updateCoordinates,
        typeStore: this.state.typeStore,
        cipro: this.state.cipro,
        vatStatus: this.state.vatStatus,
        uifNumber: this.state.uifNumber,
        kycDocumentation: this.state.kycDocumentation,
      }
      const requestAddMyStoreMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      isAddMyStore ? this.apiCallMessageAddMyStore = requestAddMyStoreMessage.messageId : this.apiCallMessageAddOtherStore = requestAddMyStoreMessage.messageId
      requestAddMyStoreMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isAddMyStore ? "storeSetting/addMyStore" : "storeSetting/addOtherStore"
      );

      requestAddMyStoreMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header3)
      );
      requestAddMyStoreMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body3)
      );

      requestAddMyStoreMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestAddMyStoreMessage.id, requestAddMyStoreMessage);
      this.setState({ tickModel: true });
      setTimeout(() => {
        setTimeout(() => {
          this.setState({ tickModel: false });
          this.setState({
            storeName: "",
            contactDetail: '+27',
            email: '',
            nextOfKin: '',
            storeAddress: '',
            updateCoordinates: '',
            typeStore: '',
            cipro: '',
            vatStatus: '',
            uifNumber: '',
            kycDocumentation: '',
          });
        }, 2000);
      }, 2000);
    }
  };

  handleComplianceReportsChange = (event: any) => {
    // Check if event and event.target are not null or undefined
    if (event && event.target) {
      // Access the value property safely
      const targetName = event.target.value;

      this.setState((prevState) => ({
        complianceReport: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },


        // Other groups remain unchanged
        municipalPermit: prevState.municipalPermit,
        registeredBusiness: prevState.registeredBusiness,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };
  handleMunicipalPermitChanges = (event: any) => {

    if (event && event.target) {

      const targetName = event.target.value;

      this.setState((prevState) => ({
        municipalPermit: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },

        complianceReport: prevState.complianceReport,
        registeredBusiness: prevState.registeredBusiness,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };
  handleRegisteredBusinessChange2 = (event: any) => {

    //  Check if event and event.target are not null and undefined

    if (event && event.target) {
      // Access the value property safely
      const targetName = event.target.value;

      this.setState((prevState) => ({
        registeredBusiness: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },

        // groups remain unchanged

        complianceReport: prevState.complianceReport,
        municipalPermit: prevState.municipalPermit,
        vatRegistration: prevState.vatRegistration,
      }));
    }
  };
  handleThenVatRegistrationChange = (event: any) => {


    //  Check If Event and event.target are not null and  undefined

    if (event && event.target) {
      const targetName = event.target.value;

      this.setState((prevState) => ({
        vatRegistration: {
          isCheckedYes: targetName === 'yes',
          isCheckedNo: targetName === 'no',
        },

        // Other group remains unchanged


        complianceReport: prevState.complianceReport,
        municipalPermit: prevState.municipalPermit,
        registeredBusiness: prevState.registeredBusiness,
      }));
    }
  };

  goBack = () => {
    this.props.navigation.navigate('StoreSettings'); // This will navigate back to the previous screen
  };
  handleOpenModalNewUser2 = () => {
    this.setState({ tickModel: true });
    setTimeout(() => {
      this.setState({ tickModel: false });
    }, 2000);
  };

  handleCloseModal2 = () => {
    this.setState({ tickModel: false });
  };

  handleAddNewOperator = (storeId: string) => {
    this.props.navigation.navigate(`AddOperators`, { storeId }); // This will navigate back to the previous screen
  }

  handleAddOperator = () => {
    if (!this.state.nameOp || !this.state.passportId || !this.state.citizenStatus || !this.state.storeName || !this.state.address || !this.state.coordinates || !this.state.isPhoneNumberValid || !this.state.nextOfKinOp || !this.state.emailAddressOp || !this.state.ethnicity || !this.state.gender || !this.state.positions) {
      errorNotify()
    } else {
      notify()
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        nameOp: this.state.nameOp,
        passportId: this.state.passportId,
        citizenStatus: this.state.citizenStatus,
        storeName: this.state.storeName,
        address: this.state.address,
        coordinates: this.state.coordinates,
        nextOfKinOp: this.state.nextOfKinOp,
        emailAddressOp: this.state.emailAddressOp,
        ethnicity: this.state.ethnicity,
        gender: this.state.gender,
        positions: this.state.positions,
        phoneNumberOp: this.state.phoneNumberOp
      }
      const requestUpdateOperatorSettingDetailMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateOperatorSettingDetail = requestUpdateOperatorSettingDetailMessage.messageId;
      requestUpdateOperatorSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "operatorSettingDetail/update"
      );

      requestUpdateOperatorSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUpdateOperatorSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUpdateOperatorSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestUpdateOperatorSettingDetailMessage.id, requestUpdateOperatorSettingDetailMessage);

    }
  };
  handleDeleteOprat = (email:any) => {
    this.setState({ removeOprat: true,setEmail:email })
  }
  handleAction = (operatorEmail: string, actionType: 'approve' | 'delete') => {
    const updatedData = this.state.operatorData.map((item) => {
      if (item.OperatorEmail === operatorEmail) {
        if (actionType === 'approve') {
          this.handleApprove("")
          toast.success('Operator approved successfully', {
            style: {
              fontSize: '16px',
              fontWeight: 400,
              fontFamily: "Poppins",
            },
          });
          return { ...item, Status: 'Approved' };  // Approve the operator
        }
      }
      return item;
    });
  
    const filteredData = (actionType === 'delete' && this.state.removeOprat) ||  actionType === 'approve'
    ? updatedData.filter((item) => item.OperatorEmail !== operatorEmail)  // Remove the operator
    : updatedData;

  this.setState({ operatorData: filteredData ,removeOprat:false});
  
  };
  
  handleHide = () => {
    this.setState({ removeOprat: false })
  }
  handleNavigate = () => {
    this.props.navigation.navigate('RegisterNewOperator');
  }
  handleNavigateApprove = (operator: string) => {
    this.props.navigation.navigate('AddOperatorSettings', { operatorEmail: operator });
  }

  navigateToRegistorNewOperatorOp = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RegisterNewOperatorOp"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToSettingsStoreOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "StoreSettingsOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToSettingsStoreDetailsOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "StoreSettingDetailsOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToAddMyStoresOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddMyStoresOperators"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToAddOperatorSettingsOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddOperatorSettingsOp"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToAddOperatorOp = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddOperatorsOp"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToOperatorSettingsOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "OperatorSettingsOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToAddOtherStoresOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddOtherStoreOperator"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  navigateToAddOperOperator = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddOperatorsOp"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };
  handlePhoneNumberOpChange = (event: any) => {
    let { value } = event.target;
    value = value.replace(/[^\d+]/g, '');
    if (!value.startsWith('+27')) {
      value = '+27' + value.replace(/^\+*/, '');
    }
    const digitsOn = value.replace(/[+\s]/g, '').slice(0, 11);
    let formattedNumber = '+27';
    if (digitsOn.length > 2) {
      formattedNumber += ' ' + digitsOn.slice(2, 4);
    }
    if (digitsOn.length > 4) {
      formattedNumber += ' ' + digitsOn.slice(4, 7);
    }
    if (digitsOn.length > 7) {
      formattedNumber += ' ' + digitsOn.slice(7, 11);
    }

    const isPhoneNumberValid = digitsOn.length === 11;

    this.setState({
      phoneNumberOp: formattedNumber,
      isPhoneNumberValid: isPhoneNumberValid,
    });
  };
  handleContactDetailsChange = (event: any) => {
    let { value } = event.target;

    // Remove non-digit characters except '+'
    value = value.replace(/[^\d+]/g, '');

    // Ensure it starts with +27
    if (!value.startsWith('+27')) {
      value = '+27' + value.replace(/^\+*/, '');
    }

    // Remove spaces for easier manipulation and truncate to max length of 11 digits after +27
    const digitsOnly = value.replace(/[+\s]/g, '').slice(0, 11);

    // Reformat the number to match +27 XX XXX XXXX
    let formattedNumber = '+27';
    if (digitsOnly.length > 2) {
      formattedNumber += ' ' + digitsOnly.slice(2, 4);
    }
    if (digitsOnly.length > 4) {
      formattedNumber += ' ' + digitsOnly.slice(4, 7);
    }
    if (digitsOnly.length > 7) {
      formattedNumber += ' ' + digitsOnly.slice(7, 11);
    }

    const isPhoneNumberValid = digitsOnly.length === 11;

    this.setState({
      contactDetail: formattedNumber,
      isPhoneNumberValid: isPhoneNumberValid,
    });
  };

  validateEmailChange = () => {
    const { email } = this.state;

    // Check if the email is empty or not a string
    if (typeof email !== 'string' || !email.trim()) {
      this.setState({ errorMessage: "Please enter a valid email address.", isEmailValid: false });
      return;
    }

    // Define a regex pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,3}$/;

    // Check if the email matches the regex pattern
    if (!emailPattern.test(email)) {
      this.setState({ errorMessage: "Email is not in the correct format.", isEmailValid: false });
      return;
    }

    this.setState({ errorMessage: '', isEmailValid: true });
  };

  validateEmailOpChange = () => {
    const { emailAddressOp } = this.state;

    // Check if the email is empty or not a string
    if (typeof emailAddressOp !== 'string' || !emailAddressOp.trim()) {
      this.setState({ errorMessage: "Please enter a valid email address." });
      return;
    }

    // Define a regex pattern for a valid email address
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,3}$/;

    // Check if the email matches the regex pattern
    if (!emailPattern.test(emailAddressOp)) {
      this.setState({ errorMessage: "Email is not in the correct format." });
      return;
    }

    // If the email is valid, clear the error message
    this.setState({ errorMessage: '' });
  };

  handleEmailChange = (e: any) => {
    // Update the email state
    this.setState({
      email: e.target.value,
      invalidInputs: this.state.invalidInputs.filter((input: any) => input !== "email"),
    });

    // Validate the email on each change
    this.validateEmailChange();
  };

  handleEmailOpChange = (e: any) => {
    // Update the email state
    this.setState({
      emailAddressOp: e.target.value,
    });

    // Validate the email on each change
    this.validateEmailOpChange();
  };

  addCard = () => {
    this.setState((prevState) => ({
      numCards: prevState.numCards + 1,
      cardData: [...prevState.cardData, { Registerfullname: "", Registerposition: "", RegisterphoneNumber: "+27 " }]
    }));
  };

  handleApprove = (operatorId: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      data: operatorId
    }
    const requestUpdateStoreSettingDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageApproveOperator = requestUpdateStoreSettingDetailMessage.messageId;
    requestUpdateStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "registerNewOperator/post"
    );

    requestUpdateStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestUpdateStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestUpdateStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestUpdateStoreSettingDetailMessage.id, requestUpdateStoreSettingDetailMessage);

  }
  handleBillingCyclegoBak = () => {
    this.setState({
      billingCardIndex: "",
      showBillingSettingForm: false,
    })
  }

  handleSubmitAll = () => {
    const { cardData } = this.state;
    if (cardData.some((card: { Registerfullname: any; Registerposition: any; isValidNumber: any; }) => !card.Registerfullname || !card.Registerposition || !card.isValidNumber)) {
      errorNotify();
    } else {
      const header = {
        "Content-Type": "application/json",
      };
      const body = {
        data: cardData
      }
      const requestUpdateStoreSettingDetailMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageRegisterNewOperators = requestUpdateStoreSettingDetailMessage.messageId;
      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "registerNewOperator/post"
      );

      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      requestUpdateStoreSettingDetailMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestUpdateStoreSettingDetailMessage.id, requestUpdateStoreSettingDetailMessage);
      notify();
      const emptyCardData = cardData.map(() => ({ Registerfullname: '', Registerposition: '', RegisterphoneNumber: '' }))

      this.setState({ cardData: emptyCardData })

      setTimeout(() => {
        this.handlenavigateOperator()
      }, 500)
    }
  }

  handleInputGender = (e: any) => {
    this.setState({ gender: e.target.value })
  }

  handlenavigateOperator = () => {
    const storeId = localStorage.getItem('storeId')
    this.props.navigation.navigate('AddOperators', { storeId: storeId })
  }

  handleAddotherStore = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AddOtherStore');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleMyStore = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AddMyStores');
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg)
  }

  handleStoreDetail = (storeId: string) => {
    // const msg = new Message(getName(MessageEnum.NavigationMessage));
    // msg.addData(getName(MessageEnum.NavigationTargetMessage), 'StoreSettingDetails');
    // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // this.send(msg)
    this.props.navigation.navigate('StoreSettingDetails', { id: storeId })
  }

  handleUifNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const digitsOnly = value.replace(/[^0-9+]/g, '').slice(0, 8);

    const isUifNumberValid = digitsOnly.length === 8;

    this.setState({
      uifNumber: digitsOnly,
      isUifNumberValid: isUifNumberValid,
    });
  };
  handleUploadIdImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSec = e?.target?.files ? e.target.files[0] : null;
    const allowedTypes = ["application/pdf", "image/jpg", "image/png"];
    this.setState({ showUploadModal: true })
    if (fileSec) {
      const fileType = fileSec.type;
      if (allowedTypes.includes(fileType)) {
        setTimeout(() => {
          this.setState((prevState) => ({
            ...prevState,
            selctedCertificate: fileSec,
            fileInputTouched: true,
            idImage: fileSec.name,
            showUploadModal: false
          }));
        }, 1000);
      } else {
        this.setState(prevState => ({
          ...prevState,
          showUploadModal: false,
          fileInputTouched: true,
          idImage: '',
          showValidationMessage: true,
        }));
      }
    } else {
      this.setState({ showUploadModal: false })
    }
  };
  handleUploadKycDocumentation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSec = e?.target?.files ? e.target.files[0] : null;
    const allowedTypes = ["application/pdf", "image/jpg", "image/png"];
    this.setState({ showUploadModal: true })
    if (fileSec) {
      const fileType = fileSec.type;
      if (allowedTypes.includes(fileType)) {
        setTimeout(() => {
          this.setState((prevState) => ({
            ...prevState,
            selctedCertificate: fileSec,
            kycDocumentation: fileSec.name,
            fileInputTouched: true,
            showUploadModal: false
          }));
        }, 1000);
      } else {
        this.setState(prevState => ({
          ...prevState,
          fileInputTouched: true,
          showUploadModal: false,
          showValidationMessage: true,
          kycDocumentation: ''
        }));
      }
    } else {
      this.setState({ showUploadModal: false })
    }
  };
  handleGetStoreSettingsData = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetStoreSettings = requestGetDataMessage.messageId;
    requestGetDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "storeSettings/get"
    );

    requestGetDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetDataMessage.id, requestGetDataMessage);
  }
  handleGetStoreSettingDetail = (storeId: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetStoreSettingDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestGetStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `storeSettingDetail/get/${storeId}`
    );

    requestGetStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetStoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.apiCallMessageGetStoreSettingDetail = requestGetStoreSettingDetailMessage.messageId;

    runEngine.sendMessage(requestGetStoreSettingDetailMessage.id, requestGetStoreSettingDetailMessage);
  }
  handleGetOperatorStoreDetail = (storeId: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetOperatorStoreDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestGetOperatorStoreDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestGetOperatorStoreDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `operatorSettingDetail/get/${storeId}`
    );


    requestGetOperatorStoreDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.apiCallMessageGetOperatorStoreDetail = requestGetOperatorStoreDetailMessage.messageId;

    runEngine.sendMessage(requestGetOperatorStoreDetailMessage.id, requestGetOperatorStoreDetailMessage);
  }
  handleGetOperatorSettingDetail = (operatorEmail: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetOperatoreSettingDetailMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestGetOperatoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestGetOperatoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `operatorSettingDetail/operatorGet/${operatorEmail}`
    );


    requestGetOperatoreSettingDetailMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.apiCallMessageGetOperatorSettingDetail = requestGetOperatoreSettingDetailMessage.messageId;

    runEngine.sendMessage(requestGetOperatoreSettingDetailMessage.id, requestGetOperatoreSettingDetailMessage);
  }
  handleRemoveOperator = (operatorEmail: string) => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      operator: operatorEmail,
    }
    const requestDeleteOperatorMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestDeleteOperatorMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestDeleteOperatorMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestDeleteOperatorMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `operatorSettingDetail/deleteOperator`
    );


    requestDeleteOperatorMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.apiCallMessageDeleteOperator = requestDeleteOperatorMessage.messageId;

    runEngine.sendMessage(requestDeleteOperatorMessage.id, requestDeleteOperatorMessage);
    this.handleHide()
  }
  handleGetUserInformation = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetUserInformationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetUserInformation = requestGetUserInformationMessage.messageId;
    requestGetUserInformationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "api/User/Info"
    );

    requestGetUserInformationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetUserInformationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetUserInformationMessage.id, requestGetUserInformationMessage);
  }
  handleGetUserDocument = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetUserDocumentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetUserDocument = requestGetUserDocumentMessage.messageId;
    requestGetUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "userDocument/get"
    );

    requestGetUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetUserDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetUserDocumentMessage.id, requestGetUserDocumentMessage);
  }
  handleGetUserOperatorInformation = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetUserOperatorInformationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetUserOperatorInformation = requestGetUserOperatorInformationMessage.messageId;
    requestGetUserOperatorInformationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "userOperatorInformation/get"
    );

    requestGetUserOperatorInformationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetUserOperatorInformationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetUserOperatorInformationMessage.id, requestGetUserOperatorInformationMessage);
  }
  handleGetUserOperatorDocument = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestGetUserOperatorDocumentMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallMessageGetUserOperatorDocument = requestGetUserOperatorDocumentMessage.messageId;
    requestGetUserOperatorDocumentMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "userOperatorDocument/get"
    );

    requestGetUserOperatorDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestGetUserOperatorDocumentMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetUserOperatorDocumentMessage.id, requestGetUserOperatorDocumentMessage);
  }

  handleGetUserInfo = () => {
    const header = {
      "Content-Type": "application/json",
      // "Authorization": `Bearer`
    };

    const requestGetUserInfoMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserInfoCallId = requestGetUserInfoMessage.messageId;

    requestGetUserInfoMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "api/User/Info"
    );

    requestGetUserInfoMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestGetUserInfoMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestGetUserInfoMessage.id, requestGetUserInfoMessage);
  }
  handleSeeMore = () => {
    this.setState(prevState => ({
        initialStores: prevState.initialStores + 4,
    }));
  };

  // Customizable Area End

}
