import React, { Component } from "react";

import {
    Box,
    Typography,
    styled,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SimpleListMenu from "./SimpleListMenu.web";

interface HeaderProps { }

interface HeaderState {
    windowWidth: number;
    activeHeaderLink: string;
}



const TimeTypographyHeader = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})
const CustomBox = styled(Typography)({
    '@media (max-width: 600px)': {
        justifyContent: 'center'
    }
})
export default class OperatorHeader extends Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        windowWidth: window.innerWidth,
        activeHeaderLink: ""
    };


    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    render() {
        const { windowWidth, activeHeaderLink } = this.state;
        let path = window.location.pathname;
        return (
            // Customizable Area Start
            <>
                {windowWidth < 600 ? (
                    <SimpleListMenu />
                ) : (
                    <CustomBox style={webStyle.settingTabMainDoc as React.CSSProperties}>

                        <Link to="/OperatorUserProfile" style={webStyle.activesettingTabDoc as React.CSSProperties}>
                            <Box >
                                <TimeTypographyHeader style={path === "/OperatorUserProfile" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>User Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/OperatorSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties} >
                            <Box>
                                <TimeTypographyHeader style={path === "/OperatorSettingsOperator" || path === "/RegisterNewOperatorOp" || path === "/AddOperatorSettings" || path === "/AddOperatorsOp" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Operator Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/BillingSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties} >
                            <Box>
                                <TimeTypographyHeader style={path === "/BillingSettingsOperator" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Billing Settings</TimeTypographyHeader>
                            </Box>
                        </Link>

                        <Link to="/StoreSettingsOperator" style={{...webStyle.settingTabDoc,...webStyle.settingTabDocDisabled} as React.CSSProperties}>
                            <Box>
                                <Typography style={path === "/AddMyStoresOperators" || path === "/AddOtherStoreOperator" || path == "/StoreSettingDetailsOperator" || path === "/StoreSettingsOperator" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Store Settings</Typography>
                            </Box>
                        </Link>

                        <Link to="/Login" style={webStyle.settingTabDoc as React.CSSProperties}>
                            <Box>
                                <TimeTypographyHeader style={activeHeaderLink === "/Login" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>Log Out</TimeTypographyHeader>
                            </Box>
                        </Link>
                    </CustomBox>
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    settingTabMainDoc: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "10px 40px",
        flexWrap: "wrap",
        backgroundColor: "#E2E8F0",
    },
    activesettingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",

    },
    activetabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    settingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "186px",
        textAlign: "center",
        cursor: "pointer"
    },
    settingTabDocDisabled: {
        backgroundColor: 'rgba(132, 143, 172, 0.5)',
        color: '#6c757d',
        cursor: 'not-allowed',
        opacity: 0.65,  pointerEvents: 'none'

    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
}
// Customizable Area End
