import React from "react";

import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  styled,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import { storeicon, arrow_back } from "./assets";
import CommonDrawer from "../../../components/src/CommonSidebar.web";
const RightBoxLanding2 = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});
// Customizable Area End

import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import SideNavbar from "../../../components/src/SideBar.web";


const CustomBox = styled(Box)({
  backgroundColor: '#ffffff',
  maxWidth: "592px",
  width: '100%',
  height: "592px",
  // paddingTop: "30px",
  borderRadius: '12px',
  marginTop: '25px',
  marginLeft: '15px',
  '@media(max-width: 600px)': {
    width: '90%',
    margin: '20px auto'
  }
})
const CustomContainer = styled(Box)({
  display:"flex",
  padding:"40px",
  maxWidth:"100%",
  flexWrap:"wrap",
  gap:"30px",
  "@media (max-width:600px)":{
    alignItems:'center',
    justifyContent:'center',
  }
})
export default class BillingSettings extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  componentDidMount = async () => {
    this.handleGetStoreSettingsData()
   }
  render() {
    const { showBillingSettingForm, listOfStores, initialStores } = this.state;
    const allItemsShown = initialStores >= listOfStores.length;

    return (
      // Customizable Area Start
      <>
        <Box style={{ backgroundColor: "#F6F7FA" }}>
          <SideNavbar />
          <RightBoxLanding2 style={webStyle.secondContainer}>
            <Box style={{ width: "100%" }}>
              <CommonDrawer anchor={"right"} style={{ width: "100%" }} />
            </Box>
            <Box style={{ width: "100%" }}>
              <Header />
              {!showBillingSettingForm ?
                <Box style={{ backgroundColor: "#E2E8F0" }}>
                  <CustomContainer>

                  {listOfStores.slice(0, initialStores).map((item, index) => (
                      <Card key={index} style={webStyle.cardSmallStyle} onClick={() => this.handleCardClick(item.CommiValue)} data-test-id={`billing-card${index}`}>

                        <CardContent>
                          <Box sx={{ marginBottom: 2 }}>
                            <img src={storeicon} alt="Store" style={webStyle.srcImage} />
                          </Box>
                          <Typography style={webStyle.productTitle}>{item.StoreTItle}</Typography>
                          <Box style={webStyle.cardTexts2}>
                            <Typography style={webStyle.productDescription}>{item.Refrence}</Typography>
                            <Typography style={webStyle.referenceno}>{item.RefValue}</Typography>
                          </Box>
                          <Box style={webStyle.cardTexts2}>
                            <Typography style={webStyle.productDescription}>{item.Commissions}</Typography>
                            <Typography style={{...webStyle.productDescription,fontSize:'16px'}}>{item.CommiValue}</Typography>
                          </Box>
                          <Box style={webStyle.cardTexts2}>
                            <Typography style={webStyle.productDescription}>{item.Status}</Typography>
                            <Typography style={webStyle.approveText}>{item.StatusName}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}

                  </CustomContainer>
                  <Box style={{display:"flex",justifyContent:"center"}}>
                     <Button
             style={allItemsShown ? {...webStyle.seeMoreBilling,background:'grey',color:'white'} :webStyle.seeMoreBilling}
             data-test-id="seeMoreBillingTestID"
             disabled={allItemsShown}
             onClick={this.handleSeeMore}
            >
              <span style={{fontSize:'16px'}}>See More Stores</span>
            </Button>
            </Box>
                </Box>
                :
                <Box style={webStyle.secondPageDiv}>
                  <Box style={webStyle.backAroowBox}>
                    <img
                      src={arrow_back}
                      style={webStyle.backArrowImage} onClick={() => this.handleBillingCyclegoBak()}
                      data-test-id={"backArrowImage"}
                    />
                    <Typography
                      style={webStyle.backText}
                      onClick={() => this.handleBillingCyclegoBak()}
                      data-test-id={"backArrowText"}
                    >
                      Back
                    </Typography>
                  </Box>
                  <CustomBox>
                    <Box style={webStyle.mainBox} >
                      <Box style={webStyle.smallBox}>
                        <Typography style={webStyle.billing}>
                          Billing Settings
                        </Typography>
                        <Typography style={webStyle.bankDetailsText}>
                          Bank Details
                        </Typography>
                        <Box sx={{ marginTop: "15px" }}>
                          <label style={webStyle.headerText}>
                            Account Name
                          </label>
                          <Typography style={webStyle.inputBoxStyle} >
                            123456
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          <label style={webStyle.headerText}>
                            Account Number
                          </label>
                          <Typography style={webStyle.inputBoxStyle} >
                            123456
                          </Typography>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                          <label style={webStyle.headerText}>
                            License Subscription
                          </label>
                          <Box style={webStyle.inputBoxWebStyle}>
                            <Typography
                              style={
                                {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  color: "#273567",
                                  fontWeight: 500,
                                  border: 0,
                                  alignSelf: "center",
                                } as React.CSSProperties
                              }
                            >
                              {this.state.licenseSubscription}
                            </Typography>
                            <Typography style={webStyle.expireText}>
                              Expiry Date- 31/12/20023
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: "20px" }}>
                          <label style={webStyle.headerText}>
                            Maintenance Subscription
                          </label>
                          <Box style={webStyle.inputBoxWebStyle}>
                            <Typography
                              style={webStyle.licText}>
                              {this.state.maintenanceSubscription}
                            </Typography>
                            <Typography style={webStyle.expireText}>
                              Expiry Date- 31/12/20023
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </CustomBox>
                </Box>
                // </Grid>

              }

            </Box>
          </RightBoxLanding2 >
        </Box >
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  userSettingDocContainer: {
    backgroundColor: "#F6F7FA",
    display: "flex",
    flexWrap: "nowrap",
  },
  secondPageDiv: {
    backgroundColor: "#E2E8F0",
    marginLeft: '20px',
  } as React.CSSProperties,
  backAroowBox: {
    margin: "30px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  } as React.CSSProperties,
  backArrowImage: {
    width: "15.8px",
    height: "15.17px"
  } as React.CSSProperties,
  backText: {
    color: "#0000000",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    marginLeft: "8px",
  } as React.CSSProperties,
  headerText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#273567",
    marginBottom: "2px", // Adjust margin-bottom for reduced space
    display: "flex", // Ensure the label is displayed as a block element
  } as React.CSSProperties,
  inputBoxWebStyle: {
    width: "100%", // Full width on all devices
    border: `1px solid #273567`,
    borderRadius: "8px",
    height: "50px",
    boxSizing: "border-box",
    justifyContent: "space-between",
    paddingLeft: "4%", // Adjust padding as needed
    marginBottom: "10px", // Adjust margin as needed,
    display: "flex",
  } as React.CSSProperties,
  licText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#273567",
    fontWeight: 500,
    border: 0,
    alignSelf: "center"
  } as React.CSSProperties,
  productTitle: {
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  mainBoxStyle: {
    padding: "40px",
    display: "flex",
    gap: "30px",
    flexWrap: "wrap",
    maxWidth: "100%"
  } as React.CSSProperties,
  cardSmallStyle: {
    maxWidth: "321px",
    cursor: "pointer"
  } as React.CSSProperties,
  srcImage: {
    width: '100%',
    height: '228px'
  } as React.CSSProperties,
  cardTexts2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as React.CSSProperties,
  productDescription: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400
  } as React.CSSProperties,
  bankDetailsText: {
    color: "#273567",
    fontSize: "18px",
    fontWeight: 500,
    marginTop: "35px",
    fontFamily: "Poppins",
  } as React.CSSProperties,
  billing: {
    color: "#000000",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
  } as React.CSSProperties,
  smallBox: {
    backgroundColor: "#ffffff",
    paddingTop: "59px",
    marginLeft: "20px",
    marginRight: "20px",
    gap: "32px",
  } as React.CSSProperties,
  mainBox: {
    backgroundColor: "#ffffff",
    // width: "610px",
    // height: "592px",
    // top: "282px",
    // marginLeft: "30px",
    // borderRadius: "4",
    // marginBottom: "20px",
    margin: '15px',
  } as React.CSSProperties,
  subscriptionBox: {
    width: "100%", // Full width on all devices
    border: `1px solid '#273567'`,
    borderRadius: "8px",
    height: "50px",
    boxSizing: "border-box",
    paddingLeft: "4%", // Adjust padding as needed
    marginBottom: "10px", // Adjust margin as needed,
    display: "flex",
    justifyContent: "space-between",
  },

  cardTexts3: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  } as React.CSSProperties,
  refrence: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400
  } as React.CSSProperties,
  expireText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    alignItems: "flex-end",
    color: "#848FAC",
    alignSelf: "center",
    paddingRight: "4%",
  },
  inputBoxStyle: {
    width: '100%', // Full width on all devices
    border: `1px solid #273567`,
    borderRadius: "8px",
    fontWeight: 500,
    paddingLeft: "4%", // Adjust padding as needed
    //paddingTop: "2.5%", // Adjust padding as needed
    marginBottom: "10px", // Adjust margin as needed,
    color: "#273567",
    height: "50px",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: "14px",
    alignItems: 'center',
    display: 'flex'
  } as React.CSSProperties,
  referenceno2: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#C12030'
  } as React.CSSProperties,
  referenceno: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#C12030",
  } as React.CSSProperties,
  topHeader2: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row', // Display cards in a row
    flexWrap: 'wrap', // Allow cards to wrap to the next row if needed
    borderRadius: '12px',
    border: '10px',
    marginTop: '40px', // Add some top margin if needed
    maxWidth: '1173px', // Set a max width if needed,
    width: '100%',
    marginLeft: '40px',
    //height: '381px',
  } as React.CSSProperties,
  cardStyle: {
    marginRight: '25px',
    maxWidth: 345,
    marginTop: '15px',
    cursor: "pointer",
  },
  secondContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E2E8F0",
    height: "1100px",
  } as React.CSSProperties,
  cardTexts: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  } as React.CSSProperties,
  referenceThree: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#C12030",
  } as React.CSSProperties,
  approveText: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    color: "#059669",
  },
  approveText2: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    color: "#059669",
  },
  seeMoreBilling: {
    height: "50px",
    width: "228px",
    fonstSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#fff",
    backgroundColor: "#C12030",
    margin: "32px auto 30px",
    textTransform: "capitalize",
  } as React.CSSProperties,
};
// Customizable Area End
