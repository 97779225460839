import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled  } from "@material-ui/core/styles";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';

const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

const Dropdown = styled(Select)({
  "@media (max-width: 430px)":{
    width:"240px !important",
    marginTop:15
  },
  "@media (width: 810px)":{
    marginTop:20
  }
});



const CustomBox6 = styled(Box)({
    display: 'flex',
    borderRadius: '12px', 
    border: '1px solid #273567', 
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '340px', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    height: '50px', 
  "@media (max-width: 430px)":{
    width:"220px !important"
  }
});
const CustomBox7 = styled(Box)({
  display: 'flex',
  borderRadius: '12px', 
  border: '1px solid #273567', 
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '340px', 
  paddingLeft: '10px', 
  paddingRight: '10px',
  height: '50px', 
"@media (max-width: 430px)":{
  marginTop:"16px !important",
  width:"220px !important"
}
});

const CustomTypography2 = styled(Typography)({
  color: '#273567',
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: 'Poppins',
  "@media (max-width: 430px)":{
    fontSize: "13px",
  }
});

const CustomBox4 = styled(Box)({
    backgroundColor: '#ffffff',
    width: "80%",
    boxSizing: 'border-box',
    margin:"30px",
    padding: "30px",
    borderRadius: '12px',
    paddingLeft: '25px',
    paddingRight: '25px',
    flexWrap: 'wrap',
    "@media (max-width: 430px)":{
      marginLeft:"46px !important"
    }
});

const CustomButton = styled(Button)({
  backgroundColor: "#C12030",
  fontSize: "16px",
  marginTop: "50px",
  color: "#fff",
  width: '300px',
  height: "50px",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontFamily: 'Poppins',
  "@media (max-width: 430px)":{
    marginTop: "30px",
    width: '240px',
  }
});

const CustomBox5 = styled(Box)({
    display: 'flex',
    borderRadius: '12px', 
    border: '1px solid #273567', 
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '340px', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    height: '50px', 
    "@media (max-width: 430px)":{
      width:"220px !important"
    }
});

const CustomBox3 = styled(Box)({
  display: 'flex',
    borderRadius: '12px', 
    border: '1px solid #273567', 
    alignItems: 'center',
    width: '340px', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    height: '50px', 
  "@media (max-width: 430px)":{
    width:"220px !important"
  }
});


import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

import Cfcashfloat2Controller, {
  Props,
} from "./Cfcashfloat2Controller";
import { calenderIcon } from "./assets";



export default class CashFlotFirstOperator extends Cfcashfloat2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async ()=> {
    let terminal = await getStorageData('terminal')
    let amount = await getStorageData('amount')
    let todaysData = await getStorageData('todaysDate')
    let flag = await getStorageData("flag")

    if (flag){
      this.setState({flag:true})
    }

    if(todaysData){
      this.setState({flag:true})
      removeStorageData('todaysDate')
     removeStorageData('startCashFloatProcess')
    }
    this.setState({ selectedStore: "Azad Store", terminal: terminal || 1, amount: amount })
  }
  // Customizable Area End

  render() {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-IN', options);
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.mainBox}>
          <OperatorSideBar />
          <RightBoxLanding2 style={webStyle.secondContainer}>
            <Box style={webStyle.drawerBox}>
              <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
            </Box>
            <Box style={{ width: "100%", }}>

              <Box style={webStyle.smallBox}>
                <Box style={webStyle.backArowBox} data-test-id={'back-arrow'}>
                  <Typography style={webStyle.titleStyle}>Cash Float</Typography>
                </Box>
                <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} xl={12} style={{}}>
                  <CustomBox4 style={webStyle.boxStyle}>
                    <Box style={webStyle.squareBoxStyle}>
                      <Typography style={webStyle.detailsText}>Cash float details</Typography>
                      <Box style={{ display: 'flex', }}>
                        <img src={calenderIcon} style={webStyle.calenderIcon} />
                        <Typography style={webStyle.dateText}>{formattedDate}</Typography>
                      </Box>
                    </Box>
                    <Box flexWrap={'wrap'} display={'flex'} width={'100%'} mt={'16px'} style={webStyle.inputBox}>
                      <CustomBox5 style={webStyle.inputBoxStyle}>
                        <Typography style={{...webStyle.leftText,fontFamily:"Poppins"}}>{this.state.selectedStore}</Typography>
                        <Typography style={{...webStyle.rightText,fontFamily:"Poppins"}}>Store</Typography>
                      </CustomBox5>
                      <div style={{display:"flex",flexDirection:"column"}}>
                      {!this.state.flag ? 
                      (<FormControl variant="outlined" style={{fontFamily:"Poppins",fontSize:"16px",fontWeight:500}}>
                      <Dropdown
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.terminal}
                          onChange={this.handleTerminalChange}
                          placeholder="Select the terminal"
                          style={{...webStyle.dropDownStyle,fontFamily:"Poppins",fontSize:"16px",fontWeight:500}}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={1} style={{...webStyle.selectStoreStyle,display:"none"}}>Select the terminal</MenuItem>
                          <MenuItem value={'Terminal 1'} style={webStyle.selectStoreStyle}>Terminal 1</MenuItem>
                          <MenuItem value={'Terminal 2'} style={webStyle.selectStoreStyle}>Terminal 2</MenuItem>
                          <MenuItem value={'Terminal 3'} style={webStyle.selectStoreStyle}>Terminal 3</MenuItem>
                        </Dropdown>
                      </FormControl>
                        ):(
                          <CustomBox7 >
                        <Typography  style={{...webStyle.leftText,fontFamily:"Poppins"}}>{this.state.terminal}</Typography>
                        <Typography style={{...webStyle.rightText,fontFamily:"Poppins"}}>POS terminal</Typography>
                        </CustomBox7>)}
                         {this.state.terminalError && <div style={webStyle.terminalErrorStyle}>Please select any POS terminal</div>}
                      </div>

                      
                    </Box>
                    <Box flexWrap={'wrap'} display={'flex'} width={'100%'} mt={'16px'} style={webStyle.inputBox}>
                      
                      <div style={{display:"flex",flexDirection:"column"}}>
                      {!this.state.flag ?(<CustomBox3 style={{ border: `1px solid ${this.state.borderAmount ? 'red' : '#273567'}`, }}>
                      <Typography style={{ ...webStyle.TextRstyle, color: this.state.cashAmountError ? 'red' : "#00000", fontFamily: 'Poppins', }}>R</Typography>
                        <input
                          className="no-outline"
                          type="text"
                          disabled={this.state.flag}
                          name="username"
                          placeholder="Enter amount"
                          value={this.state.amount}
                          onChange={this.handleSetAmount}
                          style={webStyle.amountInputStyle}></input>
                      </CustomBox3>):(
                      <CustomBox6>
                        <Box style={{ display: 'flex' }}>
                          <Typography style={webStyle.textRStyle}>R</Typography>
                          <Typography style={webStyle.textStyle}>{this.state.amount}</Typography>
                        </Box>
                        
                        <CustomTypography2>Start amount</CustomTypography2>
                        </CustomBox6>)}
                    
                      {this.state.cashAmountError && <div style={webStyle.terminalErrorStyle}>Enter an appropriate amount.</div>}
                      </div>
                    </Box>
                    <CustomButton style={webStyle.buttonStyle}
                      disabled={this.state.flag}
                      onClick={() => this.StartDayOperator()}
                      data-test-id={'buttonId'}>
                      End cash float amount
                    </CustomButton>
                  </CustomBox4>
                </Grid>

              </Box>

            </Box>
          </RightBoxLanding2>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  secondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,
    mainBox:{
      display: "flex", 
      backgroundColor: "#F6F7FA",
    } as React.CSSProperties,
    smallBox:{
      width: "100%",
       backgroundColor: "#E2E8F0",
    }as React.CSSProperties,
    drawerBox:{
      width: '100%',
       backgroundColor: "#E2E8F0",
    }as React.CSSProperties,
    amountInputStyle: {
      color: "#273567",
      fontFamily: 'Poppins',
      border: 0,
      borderColor: 'white',
      fontWeight: 500,
      fontSize: '16px',
      outline: 'none',
      width: '70%'
    } as React.CSSProperties,
  selectStoreStyle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,
  TextRstyle: {
    alignSelf: 'center',
    fontWeight: 'bold',
    marginRight: '2%',
  } as React.CSSProperties,
  backArowBox:{
    marginLeft: '35px', 
    display: 'flex', 
    alignItems: 'center'
  }as React.CSSProperties,
  titleStyle:{
    color: '#0000000', 
    fontFamily: 'Poppins',
     fontWeight: 600,
      fontSize: '20px',
  }as React.CSSProperties,
  terminalErrorStyle: {
    color: 'red',
    marginTop: '2px',
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  boxStyle:{
    backgroundColor: '#ffffff',
    width: "80%",
    boxSizing: 'border-box',
    margin:"30px",
    padding: "30px",
    borderRadius: '12px',
    paddingLeft: '25px',
    paddingRight: '25px',
    flexWrap: 'wrap',
  }as React.CSSProperties,
  dropDownStyle: {
    fontFamily: "Poppins !important",
    border: '1px solid #273567', 
    fontSize: "16px",
    color: "#273567",
    fontWeight: "500 !important",
    borderRadius: '12px',
    width: '361px', 
    paddingRight: '10px',
    height: '52px', 
  },
  squareBoxStyle:{
    display: 'flex',

    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  }as React.CSSProperties,
  calenderIcon:{
    width: '18.18px', 
    height: '20px', 
    justifyContent: 'center' 
  }as React.CSSProperties,
  dateText:{
    color: '#000000', 
    fontSize: "14px", 
    fontWeight: 400, 
    fontFamily: 'Poppins', 
    marginLeft: '5px'
  }as React.CSSProperties,
  inputBoxStyle:{
    display: 'flex',
    borderRadius: '12px', 
    border: '1px solid #273567', 
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '340px', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    height: '50px', 
  }as React.CSSProperties,
  inputBoxStyle2:{
    display: 'flex',
    borderRadius: '12px', 
    border: '1px solid #273567', 
    alignItems: 'center',
    width: '340px', 
    paddingLeft: '10px', 
    paddingRight: '10px',
    height: '50px', 
  }as React.CSSProperties,
  leftText:{
    color: '#273567',
     fontSize: "16px", 
     fontWeight: 500, 
     fontFamily: 'Poppins',
  }as React.CSSProperties,
  rightText:{
    color: '#273567', 
    fontSize: "14px", 
    fontWeight: 400, 
    fontFamily: 'Poppins',
  }as React.CSSProperties,
  buttonStyle:{
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "50px",
    color: "#fff",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
  }as React.CSSProperties,
  textRStyle:{
    alignSelf: 'center',
     fontWeight: 'bold',
      marginRight: '2%', 
      fontFamily: 'Poppins',
  }as React.CSSProperties,
  textStyle:{
    color: '#273567',
     fontSize: "16px", 
     fontWeight: 500, 
     fontFamily: 'Poppins',
      marginLeft: '5px'
  }as React.CSSProperties,
  detailsText:{
    color: '#0000000',
     fontFamily: 'Poppins', 
     fontWeight: 500,
      fontSize: '20px',
  },
  inputStyle: {
    color: '#273567',
    width: '100%',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  inputBox: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap', 
    borderRadius: '12px',
    border: '10px',
    marginTop: '30px'
  } as React.CSSProperties,
  labelBox: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '12px',
    borderColor: '1px solid #273567',
    width: '340px',
    height: '50px',
    backgroudColor: 'red'
  },
  labelStyle: {
    fontFamily: 'Poppins',
    color: "#273567",
    fontSize: "16px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  registerButton: {
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "40px",
    color: "#fff",
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
  },
};
// Customizable Area End
