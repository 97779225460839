import React from "react";

import {
  Box,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrower from '../../../components/src/CommonSidebar.web';

const RightBoxLanding2 = styled(Box)({
  '@media(min-width: 992px)': {
    marginLeft: 150
  }
})

import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

import Cfcashfloat2Controller, {
  Props,
} from "./Cfcashfloat2Controller";
import { calenderIcon } from "./assets";



export default class Cfcashfloat2 extends Cfcashfloat2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async () => {
    let startCashFloatProcess = await getStorageData('startCashFloatProcess')
    let todaysData = await getStorageData('todaysDate')

    if (startCashFloatProcess) {
      this.goToNext()
    }
  }
  // Customizable Area End

  render() {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-IN', options);

    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.mainDiv}>
          <SideNavbar />
          <RightBoxLanding2 style={webStyle.secondContainer}>
            <Box style={webStyle.drawerBox}>
              <CommonDrower anchor={"right"} style={{ width: '100%' }} />
            </Box>
            <Box style={{ width: "100%", }}>

              <Box style={webStyle.smallContainerBox}>
                <Box style={webStyle.titleBox} data-test-id={'back-arrow'}>
                  <Typography style={webStyle.titleStyle}>Cash Float</Typography>
                </Box>
                <Grid item container spacing={2} xs={12} sm={12} md={6} lg={6} xl={6} style={{}}>
                  <Box style={webStyle.boxStyle}>
                    <Box style={webStyle.startDayBox}>
                      <Typography style={webStyle.startDayTable}>Start day</Typography>
                    </Box>
                    <Box style={webStyle.datebox}>
                      <img src={calenderIcon} style={webStyle.calendarIconStyle} />
                      <Typography style={webStyle.dateTextStyle}>{formattedDate}</Typography>
                    </Box>
                    <Box sx={webStyle.selectStoreBox} >
                      <label style={webStyle.selectSToreLable}>
                        Select store
                      </label>
                      <FormControl variant="outlined" required style={webStyle.formControlStyle}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          data-test-id={'selectStoreId'}
                          value={this.state.selectStore}
                          error={this.state.storeError}
                          onChange={this.handleChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          style={webStyle.dropDownStyle}
                        >
                          <MenuItem value={1} style={{...webStyle.selectStoreStyle,display:"none"}}>Select the store</MenuItem>
                          <MenuItem value={'Kahndal Saree Store'} style={webStyle.selectStoreStyle}>Kahndal Saree Store</MenuItem>
                          <MenuItem value={'Pretty Saree Store'} style={webStyle.selectStoreStyle}>Pretty Saree Store</MenuItem>
                          <MenuItem value={'Linen Saree Shop'} style={webStyle.selectStoreStyle}>Linen Saree Shop</MenuItem>
                        </Select>
                      </FormControl>
                      {this.state.storeError && <div style={webStyle.terminalErrorStyle}>Please select a store</div>}

                    </Box>
                    <Box sx={{ marginTop: '20px', width: '100%' }}>
                      <label style={webStyle.selectSToreLable}>
                        Select the POS terminal
                      </label>
                      <FormControl variant="outlined" required style={{
                        border: `0.5px #273567`, borderRadius: "18px", width: '100%'
                      }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.terminal}
                          onChange={this.handleTerminalChange}
                          error={this.state.terminalError}
                          style={webStyle.dropDownStyle}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={1} style={{...webStyle.selectStoreStyle,display:"none"}}>Select the terminal</MenuItem>
                          <MenuItem value={'Terminal 1'} style={webStyle.selectStoreStyle}>Terminal 1</MenuItem>
                          <MenuItem value={'Terminal 2'} style={webStyle.selectStoreStyle}>Terminal 2</MenuItem>
                          <MenuItem value={'Terminal 3'} style={webStyle.selectStoreStyle}>Terminal 3</MenuItem>
                        </Select>
                      </FormControl>
                      {this.state.terminalError && <div style={webStyle.terminalErrorStyle}>Please select any POS terminal</div>}
                    </Box>

                    <Box sx={{ marginTop: '20px' }}>
                      <label style={webStyle.selectSToreLable}>
                        Enter the cash amount
                      </label>
                      <Box style={{ ...webStyle.inputBoxStyle, border: `1px solid ${this.state.borderAmount ? 'red' : '#273567'}`, } as React.CSSProperties}>
                        <Typography style={{ ...webStyle.TextRstyle, color: this.state.cashAmountError ? 'red' : "#00000", fontFamily: 'Poppins', }}>R</Typography>
                        <input
                          className="no-outline"
                          type="text"
                          name="username"
                          placeholder="Enter amount"
                          value={this.state.amount}
                          onChange={this.handleSetAmount}
                          style={webStyle.amountInputStyle}></input>
                      </Box>
                      {this.state.cashAmountError && <div style={webStyle.terminalErrorStyle}>Enter an appropriate amount.</div>}

                      <Button style={webStyle.registerButton as React.CSSProperties} onClick={() => this.StartDay()} data-test-id={'btnSubmit'}>
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Grid>


              </Box>

            </Box>
          </RightBoxLanding2>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  secondContainer:
    {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#E2E8F0",
    } as React.CSSProperties,
  mainDiv: {
    display: "flex",
    backgroundColor: "#F6F7FA",
  },
  dateTextStyle: {
    color: '#000000',
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginLeft: '5px'
  } as React.CSSProperties,
  selectStoreBox: {
    marginTop: '30px',
    width: '100%'
  } as React.CSSProperties,
  formControlStyle: {
    border: `0.5px #273567`,
    borderRadius: "18px",
    width: '100%'
  } as React.CSSProperties,
  selectSToreLable: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#273567",
    marginBottom: '2px', // Adjust margin-bottom for reduced space
    display: 'block',
  } as React.CSSProperties,
  inputBoxStyle: {
    display: 'flex',
    width: "100%", // Full width on all devices
    borderRadius: "8px",
    height: "50px",
    boxSizing: "border-box",
    paddingLeft: "2%", // Adjust padding as needed
  } as React.CSSProperties,
  TextRstyle: {
    alignSelf: 'center',
    fontWeight: 'bold',
    marginRight: '2%',
  } as React.CSSProperties,
  amountInputStyle: {
    color: "#273567",
    fontFamily: 'Poppins',
    border: 0,
    borderColor: 'white',
    fontWeight: 400,
    fontSize: '14px',
    alignSelf: 'center',
    outline: 'none',
    width: '70%'
  } as React.CSSProperties,
  drawerBox: {
    width: '100%',
    backgroundColor: "#E2E8F0",
  },
  smallContainerBox: {
    width: "100%",
    backgroundColor: "#E2E8F0",
  } as React.CSSProperties,
  titleBox: {
    marginLeft: '35px',
    display: 'flex',
    alignItems: 'center'
  } as React.CSSProperties,
  titleStyle: {
    color: '#0000000',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '20px',
  } as React.CSSProperties,
  startDayBox: {
    backgroundColor: '#ffffff',
    paddingTop: "30px"
  } as React.CSSProperties,
  startDayTable: {
    color: '#000000',
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  calendarIconStyle: {
    width: '18.18px',
    height: '20px',
    justifyContent: 'center'
  } as React.CSSProperties,
  datebox: {
    marginTop: "45px",
    display: 'flex',
    marginBottom: '35px'
  },
  boxStyle: {
    backgroundColor: '#ffffff',
    width: "90%",
    height: "650px",
    top: "282px",
    margin: '35px',
    paddingTop: "59.5px, 30px, 58.5px, 30px",
    borderRadius: '12px',
    paddingLeft: '25px',
    paddingRight: '25px',
    boxSizing: 'border-box',
    alignItems: 'center',
  } as React.CSSProperties,
  selectStoreStyle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    textAlign: 'center'
  } as React.CSSProperties,
  terminalErrorStyle: {
    color: 'red',
    marginTop: '2px',
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: 'Poppins',
  } as React.CSSProperties,
  dropDownStyle: {
    width: '100%',
    height: '55px',
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#273567",
    fontWeight: 400,
    borderRadius: '8px',
  } as React.CSSProperties,
  inputStyle: {
    color: '#273567',
    width: '100%',
    top: '26px',
    borderRadius: '8px',
    border: '1px solid',
    height: '40px',
    padding: 15,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500
  },
  inputBox: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap', // Allow cards to wrap to the next row if needed
    borderRadius: '12px',
    border: '10px',
    // maxWidth: '1173px', // Set a max width if needed,
    //  width: '60%',
    marginTop: '30px'
  } as React.CSSProperties,
  labelBox: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '12px',
    borderColor: '1px solid #273567',
    width: '340px',
    height: '50px',
    backgroudColor: 'red'
  },
  labelStyle: {
    fontFamily: 'Poppins',
    color: "#273567",
    fontSize: "16px",
    lineHeight: " 24px",
    letterSpacing: "0em",
    fontWeight: "400",
  },
  registerButton: {
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "40px",
    color: "#fff",
    width: "100%", // Full width on all devices
    height: "50px", // Adjust height as needed
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
  },
};
// Customizable Area End
