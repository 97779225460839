import React from "react";

import {
    Box,
    Button,
    Typography,
    Grid,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from '../../../components/src/CommonSidebar.web';
import { getStorageData } from "../../../framework/src/Utilities";
const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { arrow_back, } from "./assets";
const CustomTypography = styled(Typography)({
    paddingLeft: '35px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "690px",
    width: '100%',
    height: "542px",
    // paddingTop: "30px",
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto'
    }
})

const WalletTop = styled(Box)({
    '@media(max-width: 992px)': {
      marginTop: "0 !important"
    }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
      fontSize: "16px !important"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";

export default class TransferToOtherWalletThree extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = async () => {
        let amount = await getStorageData('amount')
        let selectStore = await getStorageData('selectStore')
        this.setState({ getAmount: amount, selectedStore: selectStore })

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box style={webStyle.mainView}>
                    <SideNavbar />
                    <RightBoxLanding2 style={webStyle.second_container}>
                    <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>Transfer to Other Wallets</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </Box>
                        <CustomBox>
                            <Box style={webStyle.secondMainContainer}>
                                <Box style={webStyle.backIcon} data-test-id={"back-arrow"} >
                                    <img src={arrow_back}
                                        style={webStyle.arrowBack} onClick={() => this.handleTransferToOtherWalletTwo()} data-test-id={'backImageId'} />
                                    <Typography style={webStyle.backText} onClick={() => this.handleTransferToOtherWalletTwo()} data-test-id={'backTextId'}>
                                        Back
                                    </Typography>
                                </Box>
                                <Box style={webStyle.mainBoxStyle}>
                                    <Typography style={webStyle.seletecStoreText}>Kahndal Saree Store</Typography>
                                    <Typography style={webStyle.amountText}>Adding R{this.state.getAmount} to Kahndal Saree Store's Wallet</Typography>
                                    <Typography style={webStyle.getAmount}>R {this.state.getAmount}</Typography>
                                    <Button style={webStyle.button}
                                        data-test-id={'btnProceedToPay'}
                                        onClick={() => this.handlePaymentMode()}>
                                        Pay
                                    </Button>
                                </Box>
                            </Box>
                        </CustomBox>
                    </RightBoxLanding2 >


                </Box >
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainView: {
        display: "flex",
        backgroundColor: "#F6F7FA",
    },

    second_container:
        {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
            backgroundColor: "#E2E8F0",
            height: "700px"
        } as React.CSSProperties,
    arrowBack: {
        width: "15.8px",
        height: "15.17px"
    },
    commisionText: {
        fontFamily: 'Inter',
        fontWeight: 700,
        fontSize: '24px',
        marginLeft: '20px'
    },
    payButton: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        marginTop: "20px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
    },

    cardStyle: {
        width: "85%",
        height: "270px",
        top: "282px",
        borderRadius: '12px',
        marginBottom: '20px',
        marginTop: '25px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginLeft: '30px',
        marginRight: '30px',
        paddingTop: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        flexWrap: 'wrap'
    },
    secondMainContainer: {
        // backgroundColor: '#ffffff',
        // width: "100%",
        // // height: "542px",
        // top: "282px",
        padding: "30px ",
        // borderRadius: '12px',
        // margin: '40px',
    },

    backText: {
        color: "#0000000",
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        marginLeft: "8px",
    },
    backIcon: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: '10px'
    },
    textStyle: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
    },

    text: {
        color: "#000000",
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        marginLeft: '5px'
    },
    mainBoxStyle: {
        width: "90%",
        top: "188px",
        borderRadius: '12px',
        margin: '40px 20px',
        padding: '15px',
        backgroundColor: '#ffffff',
        boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
        flexWrap: 'wrap'
    } as React.CSSProperties,
    seletecStoreText: {
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
    } as React.CSSProperties,
    amountText: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '16px',
        textAlign: 'center'
    } as React.CSSProperties,
    getAmount: {
        color: '#0000000',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '24px',
        margin: '90px 0px',
        textAlign: 'center',
    } as React.CSSProperties,
    button: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        marginTop: "20px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
    } as React.CSSProperties,
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};
// Customizable Area End
