import React, { Component } from "react";

import {
    Box,
    Modal,
    Typography,
    styled,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import SimpleListMenu from "./SimpleListMenu.web";
export const CloseIcn = require('./CloseIcn.png');

interface HeaderProps { }

interface HeaderState {
    windowWidth: number;
    activeHeaderLink: string;
    logoutModal:boolean;
}



const TimeTypographyHeader = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})
const CustomBox = styled(Typography)({
    '@media (max-width: 600px)': {
        justifyContent: 'center'
    }
})
export default class Header extends Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        windowWidth: window.innerWidth,
        activeHeaderLink: "",
        logoutModal:false,
    };


    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    confirmLogout = () => {
        this.setState({ logoutModal: true });
    }
    handeCloseLogout = () => {
        this.setState({ logoutModal: false });
    }
    handleRemoveRole = () => {
        localStorage.removeItem("Role")
    }
    render() {
        const { windowWidth, activeHeaderLink } = this.state;
        let path = window.location.pathname;
        let setRole = localStorage.getItem('Role');
        const isOperator = setRole === 'Operator';
        return (
            // Customizable Area Start
            <>
                {windowWidth < 600 ? (
                    <SimpleListMenu />
                ) : (
                    <CustomBox style={webStyle.settingTabMainDoc as React.CSSProperties}>

                    <Link 
                        to="/UserProfile" 
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                            ...(path === "/UserProfile" ? webStyle.activesettingTabDoc : webStyle.tabTextDoc)
                        } as React.CSSProperties}
                    >
                        <Box>
                            <TimeTypographyHeader style={path === "/UserProfile" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                User Settings
                            </TimeTypographyHeader>
                        </Box>
                    </Link>

                    <Link 
                        to="/OperatorSettings" 
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                            ...(isOperator ? webStyle.settingTabDocDisabled : {}),
                            ...(path === "/OperatorSettings" || path === "/RegisterNewOperator" || path.includes("AddOperatorSettings") || path.includes("AddOperators") ? webStyle.activetabTextDoc : webStyle.tabTextDoc)
                        } as React.CSSProperties}
                    >
                        <Box>
                            <TimeTypographyHeader style={path === "/OperatorSettings" || path === "/RegisterNewOperator" || path.includes("AddOperatorSettings") || path.includes("AddOperators") ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                Operator Settings
                            </TimeTypographyHeader>
                        </Box>
                    </Link>

                    <Link 
                        to="/BillingSettings" 
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                            ...(isOperator ? webStyle.settingTabDocDisabled : {}),
                            ...(path === "/BillingSettings" ? webStyle.activetabTextDoc : webStyle.tabTextDoc)
                        } as React.CSSProperties}
                    >
                        <Box>
                            <TimeTypographyHeader style={path === "/BillingSettings" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                Billing Settings
                            </TimeTypographyHeader>
                        </Box>
                    </Link>

                    <Link 
                        to="/StoreSettings" 
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                            ...(isOperator ? webStyle.settingTabDocDisabled : {}),
                            ...(path === "/AddMyStores" || path === "/AddOtherStore" || path.includes("StoreSettingDetails") || path === "/StoreSettings" ? webStyle.activetabTextDoc : webStyle.tabTextDoc)
                        } as React.CSSProperties}
                    >
                        <Box>
                            <Typography style={path === "/AddMyStores" || path === "/AddOtherStore" || path.includes("StoreSettingDetails") || path === "/StoreSettings" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                Store Settings
                            </Typography>
                        </Box>
                    </Link>

                    <Box 
                        onClick={this.confirmLogout}  
                        style={{
                            ...webStyle.settingTabDoc,
                            width: "100% !important",
                            minWidth:"200px",
                        } as React.CSSProperties}
                    >
                        <Box>
                            <TimeTypographyHeader style={activeHeaderLink === "/Login" ? webStyle.activetabTextDoc as React.CSSProperties : webStyle.tabTextDoc as React.CSSProperties}>
                                Log Out
                            </TimeTypographyHeader>
                        </Box>
                    </Box>

                    <Modal
                        open={this.state.logoutModal}
                        onClose={this.handeCloseLogout}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box style={{
                            backgroundColor: "white",
                            padding: "10px",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            border: '2px solid white',
                            borderRadius: "8px 8px 32px 8px",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 500,
                            outline: "none",
                            width:"100%",
                            maxWidth:"600px"
                        }}>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                <Box style={webStyle.modalTextOpper as React.CSSProperties}>
                                    Are you sure you want logout?
                                </Box>
                                <Box style={{cursor:"pointer",marginTop:"10px",marginRight:"10px"}} onClick={this.handeCloseLogout}>
                                    <img src={CloseIcn} alt="close-icn"/>
                                </Box>
                            </Box>
                            <Box style={{ borderBottom: "1px solid gray", margin: "20px 0" }}></Box>
                            <Box style={webStyle.buttonContainerOpper as React.CSSProperties}>
                                <Box onClick={this.handeCloseLogout} style={webStyle.noButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Cancel</Typography>
                                </Box>
                                <Link to="/Login" onClick={this.handleRemoveRole} style={webStyle.yesButtonOpper as React.CSSProperties}>
                                    <Typography style={{fontFamily: "Poppins",fontWeight:400}}>Logout</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Modal>
                </CustomBox>
                )}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    settingTabMainDoc: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "0px 40px 10px",
        flexWrap: "wrap",
        backgroundColor: "#E2E8F0",
    },
    activesettingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "100%",
        maxWidth: "200px",
        textAlign: "center",
        cursor: "pointer",

    },
    activetabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#C12030",
    },
    settingTabDoc: {
        appearance: "none", all: "unset",
        backgroundColor: "rgba(132, 143, 172, 0.1)",
        padding: "15px",
        borderRadius: "16px",
        width: "200px",
        textAlign: "center",
        cursor: "pointer"
    },
    tabTextDoc: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        lineHeight: '30px',
        fontSize: '20px',
        color: "#848FAC",
    },
    modalTextOpper:{
        fontSize:"16px",
        fontFamily:"Poppins",
        fontWeight:"600",
        lineHeight:"24px",
        marginTop:"10px"
    },
    buttonContainerOpper: {
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-end",
        margin:"15px"
    },
    noButtonOpper: {
        borderRadius: "8px",
        border:"1px solid #848FAC",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#848FAC",
        marginRight:"10px"
    },
    yesButtonOpper: {
        all:"unset",
        borderRadius: "8px",
        background: "#C12030",
        width: "100%",
        maxWidth: "105px",
        height: "40px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        cursor: "pointer",
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: "#ffffff",
    },
    settingTabDocDisabled: {
        backgroundColor: 'rgba(132, 143, 172, 0.5)',
        color: '#6c757d',
        cursor: 'not-allowed',
        opacity: 0.65,  pointerEvents: 'none'

    },
}
// Customizable Area End
