import React from "react";

import {
    Box,
    Typography,
    styled,
    Button,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Toaster } from "react-hot-toast"
// Customizable Area Start

const RightBoxLanding2 = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const CustomTypography = styled(Typography)({
    paddingLeft: '54px',
    '@media(max-width: 600px)': {
        paddingLeft: '20px'
    }
})
const CustomBox = styled(Box)({
    backgroundColor: '#ffffff',
    maxWidth: "690px",
    width: '100%',
    height: "326px",
    paddingTop: "30px",
    borderRadius: '12px',
    marginTop: '25px',
    marginLeft: '54px',
    '@media(max-width: 600px)': {
        width: '90%',
        margin: '20px auto'
    }
})
const CustomButton = styled(Button)({
    backgroundColor: "#C12030",
    fontSize: "16px",
    marginTop: "35px",
    color: "#fff",
    maxWidth: '600px',
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontWeight: 400,
    '@media(max-width: 600px)': {
        marginTop: "35px",
    },
    '&:hover': {
        backgroundColor: "#C12030",
    },

});

const MainBox = styled(Box)({
    maxWidth: "630px",
    // width: '100%',
    height: "178px",
    borderRadius: '12px',
    marginTop: '25px',
    paddingLeft: '15px',
    paddingRight: '15px',
    marginLeft: '30px',
    marginRight: '30px',
    paddingTop: '15px',
    boxShadow: '5px 5px 7px 6px rgba(0, 0, 0, 0.1)',
    '@media(max-width: 600px)': {
        width: '80%',
        margin: '20px auto'
    }
})

const ErrorTypography = styled(Typography)({
    color: '#FF0404',
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Poppins",
    position: "relative", top: "0px",

});
const WalletTop = styled(Box)({
    '@media(max-width: 992px)': {
      marginTop: "0 !important"
    }
  })
  
  const WalletText = styled(Typography)({
    '@media(max-width: 900px)': {
      fontSize: "16px !important"
    }
  })
// Customizable Area End

import Cfwallet53Controller, {
    Props,
    configJSON,
} from "./Cfwallet53Controller";
import SideNavbar from "../../../components/src/SideBar.web";
import { leftArrow, downArrow } from './assets';
import CommonDrawer from '../../../components/src/CommonSidebar.web';
export default class AddBux extends Cfwallet53Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        let showSuccess = localStorage.getItem('showSuccess');
        if (showSuccess != null) {
            setTimeout(() => {
                this.handleSuccess()
            }, 1000);
        }
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <Box>
                <SideNavbar />
                <RightBoxLanding2 style={webStyle.secondContainer}>
                <Box style={webStyle.HeadersMain}>
            <WalletTop style={{ paddingLeft: "40px" }}>
              <WalletText style={{ fontSize: "24px", fontFamily: 'Inter', fontWeight: 700 }}>BUX</WalletText>
            </WalletTop>
            <Box>
              <CommonDrawer anchor={"right"} style={{ width: '100%' }} />
            </Box>
          </Box>
                    
                    <CustomBox>
                        <Box style={{ display: 'flex', marginLeft: 20 }}>
                            <img src={leftArrow} style={{ width: '24px', height: '24px', cursor: 'pointer' }} data-test-id="backchoosebank" onClick={() => this.handleAddBuxGoBack()} />
                            <Typography style={webStyle.backIcon} data-test-id="backchoosebank2" onClick={() => this.handleAddBuxGoBack()}>Back</Typography>
                        </Box>

                        <MainBox>
                            <Box style={webStyle.boxStyle}>
                                <Typography style={webStyle.transferText}>Add your BUX ID</Typography>
                            </Box>
                            <Box style={webStyle.textContainer}>
                                <input
                                    className="no-outline"
                                    type="text"
                                    name="buxId"
                                    placeholder="Enter Bux ID"
                                    value={this.state.buxId}
                                    onChange={this.handleBuxIdInput}
                                    onKeyDown={this.handleBuxIdInput}
                                    style={webStyle.inputStyle}
                                />
                                {this.state.buxIdError && (
                                    <ErrorTypography>Enter your correct ID number</ErrorTypography>
                                )}
                            </Box>
                            <CustomButton style={webStyle.buttonFirst} data-test-id="addBux" onClick={this.AddBux}>
                                Next
                            </CustomButton>
                        </MainBox>
                    </CustomBox>
                    <Toaster position="top-center" toastOptions={{
                        success: {
                            style: {
                                padding: '16px',
                            },
                        },
                    }} />
                </RightBoxLanding2>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    secondContainer: {
        backgroundColor: "#E2E8F0",
    } as React.CSSProperties,
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "1173px",
        width: '100%',
        height: "851px",
        backgroundColor: '#fff'
    } as React.CSSProperties,

    backIcon: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 500,
        color: '#000',
        paddingLeft: 8,
        cursor: 'pointer'
    },

    boxStyle: {
        width: "600px",
        height: '22px',
        marginBottom: "5px"
    },
    transferText: {
        color: "#273567",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: "22px"
    }as React.CSSProperties,

    textContainer: {
        maxWidth: "600px",
        width: "100%",
        height: '50px',
        borderRadius: '12px',
        border: '0.5px solid #273567',
        alignItems: 'center',
    }as React.CSSProperties,

    buttonFirst: {
        backgroundColor: "#C12030",
        fontSize: "16px",
        color: "#fff",
        width: "100%", // Full width on all devices
        height: "50px", // Adjust height as needed
        borderRadius: "8px",
        textTransform: "capitalize",
        fontFamily: 'Poppins',
        fontWeight: 400
    } as React.CSSProperties,

    inputStyle: {
        color: "#192252",
        fontFamily: 'Poppins',
        border: 'none',
        borderColor: 'white',
        fontWeight: 500,
        fontSize: '14px',
        alignSelf: 'center',
        outline: 'none',
        margin: "15px",
        width: '85%',
    }as React.CSSProperties,
    headingText: {
        color: "#0F172A",
        fontSize: "24px",
        fontFamily: "Inter",
        fontWeight: 700,
        lineHeight: "32px",
    } as React.CSSProperties,
    '@media only screen and (max-width: 375px)': {
        inputStyle: {
            fontSize: '8px',
            margin: "10px",
            color: "red"
        }
    },
    HeadersMain: { width: '100%', backgroundColor: "rgb(226, 232, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" } as React.CSSProperties,
};

// Customizable Area End

