import React from "react";

import {
    Box, Button, Card, CardContent, Fade, FormControl, FormControlLabel, MenuItem, Modal, RadioGroup, Select, Typography, styled,
} from "@material-ui/core";
import MultipageFormsController, {
    Props
} from "./MultipageFormsController";
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import { BackIcn, AttachMent, CloseIcn, FileIcn, RightIcn } from './assets';
import LoanCustomRadio from "./LoanCustomRadio.web";
import { Toaster } from "react-hot-toast"


const LayoutMain = styled(Box)({
    height: "100%",
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})

const LoanText = styled(Typography)({
    fontFamily: 'Poppins',
    color: '#334155',
    backgroundColor: "#FFFFFF",
    fontWeight: 600,
    fontSize: '20px',
    alignSelf: 'center',
    padding: "20px 45px",
})
const TimeTypograph = styled(Typography)({
    '@media (max-width: 600px)': {
        fontSize: "16px !important"
    }
})

const radioLabel = (value: any) => ({
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#273567'
});

const ModalBoxForgot = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

const UploadContainer = styled('div')({
    cursor: 'pointer',
    marginTop: '5px',
    maxWidth: '550px',
    position: 'relative',
});

const UploadInputDoc = styled('div')({
    borderColor: '#273567',
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
});

const UploadedDocName = styled(Typography)({
    marginLeft: '15px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#273567',
    '@media (max-width: 600px)': {
        fontSize: '14px',
    },
});

const FileInput = styled('input')({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0,
    cursor: 'pointer',
});

const CloseButtonOperator = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
    '@media (max-width: 600px)': {
        top: '70px',
    },
    '@media (max-width: 385px)': {
        top: '93px',
    },
});

const CloseButton2Operator = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
    '@media (max-width: 600px)': {
        top: '70px',
    },
    '@media (max-width: 400px)': {
        top: '70px',
    },
});

const CloseButton3Operator = styled('div')({
    position: 'absolute',
    top: '47px',
    right: '25px',
    zIndex: 999999,
});

const UploadText = styled('span')({
    marginLeft: '5px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    color: '#273567',
    '@media (max-width: 600px)': {
        fontSize: '14px',
    },
});


export default class LoanApplicationFormOperator extends MultipageFormsController {
    constructor(props: Props) {
        super(props);
    }

    renderComSelectOperator = () => {
        return (
            <FormControl variant="outlined" required style={{
                width: '100%', maxWidth: "550px", borderRadius: "18px", border: `0.5px #273567`, marginBottom: "25px"
            }}>

                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={"Selectcommunityofproperty"}

                    MenuProps={{

                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },

                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },

                        getContentAnchorEl: null,
                    }}

                    style={{
                        height: '55px',
                        width: '100%',
                        borderRadius: '8px',
                        fontFamily: "Poppins",
                        fontWeight: 400,
                        color: "#273567",
                        fontSize: "14px",
                    }}

                    value={this.state.communityofproperty}
                    onChange={this.handleSelectCommunityProperty}
                >
                    <MenuItem value={"Selectcommunityofproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select community of property</MenuItem>
                    <MenuItem value={"Marriedincommunityofproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }} >Married in community of property</MenuItem>
                    <MenuItem value={"Marriedoutofcommunityproperty"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Married out of community of property</MenuItem>
                </Select>

            </FormControl>
        )
    }

    render() {
        const { uploadedDocNameproof, uploadedDocNamestatement, uploadedDocNamespouse } = this.state;
        return (
            // Customizable Area Start
            <Box style={{ display: "flex" }}>
                <OperatorSideBar />
                <LayoutMain style={{ width: "100%", backgroundColor: "#F6F7FA" }}>
                    <Box style={webStyle.LoanHeader}>

                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />

                    </Box>
                    <Box style={{ padding: "0 40px", backgroundColor: "rgb(226, 232, 240)" }}>
                        <Box data-test-id="backapply" onClick={this.navigateToApplyForLoanOperator} style={{ cursor: "pointer", marginBottom: "30px", maxWidth: "80px" }}>
                            <Typography style={{ fontSize: "16px", display: "flex", alignItems: "center", fontFamily: 'Poppins', fontWeight: 500, }} >
                                <img src={BackIcn} alt="back-icn" style={{ marginRight: "10px" }} />
                                Back
                            </Typography>
                        </Box>

                        <LoanText>
                            Loan Application Form
                        </LoanText>
                        <Card style={{ padding: "30px", margin: "30px 0" }}>
                            <CardContent style={{ maxWidth: "100%", display: "flex", gap: 32, flexDirection: "column" }}>

                                <FormControl variant="outlined" required style={{
                                    width: '100%', maxWidth: "550px", borderRadius: "18px", border: `0.5px #273567`,
                                }}>

                                    <Select
                                        defaultValue={"SelectTypeOfLoan"}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"

                                        MenuProps={{

                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },

                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },

                                            getContentAnchorEl: null,
                                        }}


                                        style={{
                                            width: '100%',
                                            height: '55px',
                                            color: "#273567",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            borderRadius: '8px',
                                            fontSize: "14px",
                                        }}

                                        value={this.state.setLoanType}
                                        onChange={this.handleSelectTypeOfLoan}
                                    >

                                        <MenuItem value={"SelectTypeOfLoan"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select type of loan</MenuItem>
                                        <MenuItem value={"CashAdvance"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }} >Cash Advance</MenuItem>
                                        <MenuItem value={"StockonCredit"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Stock on Credit</MenuItem>
                                        <MenuItem value={'Loantoexpandthebusiness'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", }}>Loan to expand the business</MenuItem>
                                        <MenuItem value={'Loantopurchaseequipment'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Loan to purchase equipment</MenuItem>
                                        <MenuItem value={'Loantoassistoperations'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Loan to assist operations</MenuItem>
                                        <MenuItem value={'Other'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>Other</MenuItem>

                                    </Select>

                                </FormControl>

                                <FormControl variant="outlined" required style={{
                                    border: `0.5px #273567`, borderRadius: "18px", width: '100%', maxWidth: "550px"
                                }}>
                                    <Select
                                        data-test-id="selectValue"
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={"Selectthereasonforwantingfinance"}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        style={{
                                            width: '100%',
                                            height: '55px',
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            color: "#273567",
                                            fontWeight: 400,
                                            borderRadius: '8px',
                                        }}
                                        value={this.state.setFinanceReason}
                                        onChange={this.handleSelectReason}
                                    >
                                        <MenuItem value={"Selectthereasonforwantingfinance"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", display: "none" }}>Select the reason for wanting finance</MenuItem>
                                        <MenuItem value={"SelectthereasonforwantingfinanceI"} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To invest in expanding business operations</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinance1'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", }}>To cover unexpected medical expenses</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinance2'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To purchase a new home or property</MenuItem>
                                        <MenuItem value={'Selectthereasonforwantingfinancerty3'} style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px" }}>To consolidate and pay off existing debts</MenuItem>
                                    </Select>

                                </FormControl>


                                <Box sx={webStyle.maritalradioMain}>
                                    <Box>
                                        <Typography style={webStyle.maritalText as React.CSSProperties}>
                                            Are you married community of property?
                                        </Typography>
                                    </Box>
                                    <RadioGroup aria-label="gender" name="customized-radios"
                                        style={{ display: 'flex', flexDirection: 'row', gap: 50, fontFamily: "Poppins", fontWeight: 400, marginTop: "5px" }}
                                        value={this.state.maritalStatus.isCheckedYes ? 'yes' : (this.state.maritalStatus.isCheckedNo ? 'no' : "")}
                                        onChange={this.handleMaritalStatus}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<LoanCustomRadio />}
                                            label={<span style={radioLabel('yes')}>Yes</span>}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={<LoanCustomRadio />}
                                            label={<span style={radioLabel('no')}>No</span>}
                                        />
                                    </RadioGroup>
                                    {this.state.validateMaritalStatus == true && <Typography style={{ color: "#DC2626", fontSize: "12px", fontFamily: "Poppins", fontWeight: 400, marginTop: "10px" }}>*You must select an input before proceeding.</Typography>}
                                </Box>
                                {this.state.maritalStatus.isCheckedYes && (
                                    <div>
                                        {this.renderComSelectOperator()}
                                        <UploadContainer>
                                            <label htmlFor="vacc" style={{ display: 'block' }}>
                                                <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}>
                                                    Upload spouse's ID
                                                </TimeTypograph>
                                                <UploadInputDoc style={webStyle.uploadInputDoc}>
                                                    {uploadedDocNamespouse ? (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={FileIcn} alt="back-icn" />
                                                            <UploadedDocName>
                                                                {uploadedDocNamespouse.length > (window.innerWidth <= 600 ? 10 : 30)
                                                                    ? `${uploadedDocNamespouse.slice(0, window.innerWidth <= 600 ? 10 : 30)}...`
                                                                    : uploadedDocNamespouse}
                                                            </UploadedDocName>
                                                        </div>
                                                    ) : (
                                                        <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
                                                            <UploadText>Upload Document</UploadText>
                                                                <FileInput
                                                                    type="file"
                                                                    id="fileInput"
                                                                    name="compliance"
                                                                    onChange={(e) => this.handleDocUpload("spouse", e)}
                                                                    accept="application/pdf"
                                                                />
                                                            <div style={{ position: 'relative' }}>
                                                                <label htmlFor="fileInput">
                                                                    <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                </UploadInputDoc>
                                            </label>
                                            <div style={webStyle.validationMessage2}>
                                                {this.state.errorMsgspouse}
                                            </div>
                                            {uploadedDocNamespouse && (
                                                <CloseButton3Operator onClick={this.removeUploadedDoc.bind(this, "spouse")}>
                                                    <img src={CloseIcn} alt="attachment" />
                                                </CloseButton3Operator>
                                            )}
                                        </UploadContainer>
                                    </div>
                                )}

                                <UploadContainer>
                                    <label htmlFor="vacc">
                                        <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}>
                                            Upload personal bank statement (3 months)
                                        </TimeTypograph>
                                        <UploadInputDoc style={webStyle.uploadInputDoc}>
                                            {uploadedDocNamestatement ? (
                                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={FileIcn} alt="back-icn" />
                                                        <UploadedDocName>
                                                            {uploadedDocNamestatement.length > (window.innerWidth <= 600 ? 10 : 30)
                                                                ? `${uploadedDocNamestatement.slice(0, window.innerWidth <= 600 ? 10 : 30)}...`
                                                                : uploadedDocNamestatement}
                                                        </UploadedDocName>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
                                                    <UploadText>Upload Document</UploadText>
                                                        <FileInput
                                                            data-test-id="DocInput"
                                                            type="file"
                                                            id="fileInputStatement"
                                                            name="compliance"
                                                            onChange={(e) => this.handleDocUpload('statement', e)}
                                                            accept="application/pdf"
                                                        />
                                                    <div style={{ position: 'relative' }}>
                                                        <label htmlFor="fileInputStatement" style={{ cursor: 'pointer' }}>
                                                            <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </UploadInputDoc>
                                    </label>
                                    <div style={webStyle.validationMessage2}>
                                     { this.state.errorMsgstatement}
                                    </div>
                                    {uploadedDocNamestatement && (
                                        <CloseButton2Operator onClick={this.removeUploadedDoc.bind(this, 'statement')}>
                                            <img src={CloseIcn} alt="attachment" />
                                        </CloseButton2Operator>
                                    )}
                                </UploadContainer>
                                <UploadContainer>
                                    <label htmlFor="vacc">
                                        <TimeTypograph style={{ fontFamily: "Poppins", fontWeight: 400, paddingBottom: "5px", color: "#273567" }}>
                                            Upload Proof of Residence (not older than 3 months)
                                        </TimeTypograph>
                                        <UploadInputDoc style={webStyle.uploadInputDoc}>
                                            {uploadedDocNameproof ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={FileIcn} alt="back-icn" />
                                                    <UploadedDocName>
                                                        {uploadedDocNameproof.length > (window.innerWidth <= 600 ? 10 : 30)
                                                            ? `${uploadedDocNameproof.slice(0, window.innerWidth <= 600 ? 10 : 30)}...`
                                                            : uploadedDocNameproof}
                                                    </UploadedDocName>
                                                </div>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', width: '100%' }}>
                                                    <UploadText>Upload Document</UploadText>
                                                        <FileInput
                                                            type="file"
                                                            data-test-id="inputDoc"
                                                            id="fileInput"
                                                            name="compliance"
                                                            onChange={(e) => this.handleDocUpload('proof', e)}
                                                            accept="application/pdf"
                                                        />
                                                    <div style={{ position: 'relative' }}>
                                                        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                                                            <img src={AttachMent} alt="attachment" style={{ cursor: 'pointer' }} />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}
                                        </UploadInputDoc>
                                    </label>
                                    <div style={webStyle.validationMessage2}>
                                     { this.state.errorMsgproof}
                                    </div>
                                    {uploadedDocNameproof && (
                                        <CloseButtonOperator onClick={this.removeUploadedDoc.bind(this, 'proof')}>
                                            <img src={CloseIcn} alt="attachment" />
                                        </CloseButtonOperator>
                                    )}
                                </UploadContainer>

                                <Button data-test-id="sumbit" style={webStyle.submitLoanButton} onClick={this.loanNextStep}>
                                    <Typography style={webStyle.submitLoanText as React.CSSProperties}>Submit</Typography>
                                </Button>
                            </CardContent>
                        </Card>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            closeAfterTransition
                            open={this.state.loanSuccessModa}
                            onClose={this.handleCloseLoanModal}
                            aria-describedby="transition-modal-description"
                            BackdropProps={{
                                style: {
                                    backdropFilter: "blur(4px)",
                                },
                            }}
                        >
                            <Fade in={this.state.loanSuccessModa}>
                                <ModalBoxForgot sx={webStyle.greentickModal}>
                                    <img src={RightIcn} alt="Green Tick" style={{ marginBottom: '20px', maxWidth: '119px', width: '100%', height: '119px' }} />
                                    <Typography variant="h5" style={webStyle.userVerifiedText2 as React.CSSProperties}>Your application has been submitted successfully.</Typography>
                                </ModalBoxForgot>
                            </Fade>
                        </Modal>
                    </Box>
                    <Toaster position="top-center" />
                </LayoutMain>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    LoanHeader: { width: '100%', backgroundColor: "rgb(226, 232, 240)", } as React.CSSProperties,
    maritalText: {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: '#273567',
    },
    maritalradioMain: {
        fontFamily: 'Poppins',
        marginLeft: "15px"
    },
    uploadInput: {
        color: '#273567',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        height: '50px',
        padding: "15px",
        fontColor: "#273567",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadInputDoc: {
        width: '100%',
        height: '50px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxSizing: 'border-box',
        padding: '12px 20px 8px 10px',
        backgroundSize: '24px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 8px center',
        borderColor: '#273567',
    } as React.CSSProperties,
    uploadText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    submitLoanButton: {
        maxWidth: '260px',
        width: '100%',
        height: '56px',
        padding: '16px',
        borderRadius: '8px',
        backgroundColor: 'C12030',
        marginTop: "70px"
    },
     validationMessage2: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px',
        fontWeight: 400,
        fontFamily: 'Poppins'
    } ,
    submitLoanText: {
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        textTransform: 'capitalize',
        color: '#fff'
    },
    greentickModal: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2: {
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    }
}
// Customizable Area End