import React from "react";
import {
  Box,
  Typography,
  Button,
  Fade,
  Modal,
  styled,
  Select,
  MenuItem,
} from "@material-ui/core";
import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick, upload_icn } from "./assets";
import { Toaster } from "react-hot-toast";
import SideNavbar from "../../../components/src/SideBar.web";
import CommonDrawer from "../../../components/src/CommonSidebar.web";
import Header from "../../../components/src/Header.web";

const RightBoxLanding = styled(Box)({
  "@media(min-width: 992px)": {
    marginLeft: 150,
  },
});
const ModalBoxForgot = styled(Box)({
  height: "60%",
  width: "100%",
  maxWidth: "400px",
  "@media (max-width: 600px)": {
    height: "auto",
    width: "70%",
    maxWidth: "400px",
  },
});
const StyledFileInput = styled("input")({
  border: "none",
  outline: "none",
  "::placeholder": {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    marginTop: "10px",
  },
});
const StyledSelect = styled(Select)({
  '&>#demo-simple-select-outlined':{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    height:"100%",
    paddingLeft:'15px',
    color:'#273567',
    fontWeight:500,
    fontSize:'14px'
  },
  '&>.MuiSelect-select:focus':{
    backgroundColor:'transparent',
  }
})
export default class StoreSettingDetails extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  renderInputField(name:any, label:any) {
      const _this = this
      const inputStyles = {
      baseStyle: { ...webStyle.inputStyle },
      errorStyle: { border: "1px solid red" },
      validStyle: { border: "1px solid #273567" },
    };
    const borderValidate = this.state.requiredInput.includes(name)
    ? "red"
    : "#273567";
    const checkValidBorder = (condition:boolean)=>{
      return condition? inputStyles.validStyle.border
      : inputStyles.errorStyle.border
    }
      const currentValue = this.state[name as keyof typeof _this.state];
      const renderContactDetailField = () => (
      <>
        <input
          data-test-id="contactDetail"
          name={name}
          value={this.state.contactDetail}
          type="text"
          onChange={this.handleContactDetailsChange}
          style={{
            ...inputStyles.baseStyle,
            border: checkValidBorder(this.state.isPhoneNumberValid)
              ,
          }}
        />
        {!this.state.isPhoneNumberValid && (
          <div style={webStyle.validationMessage}>Invalid Phone Number</div>
        )}
      </>
    );

    const renderEmailAddressField = () => (
      <>
        <input
          name={name}
          type="email"
          style={{
            ...inputStyles.baseStyle,
            // borderColor: borderValidate,
            border: checkValidBorder(this.state.isEmailValid)
              ,
          }}
          onChange={this.handleEmailChange}
          value={this.state.email}
        />
        {this.state.errorMessage && (
          <div className="errorMessageEmail" style={webStyle.validationMessage}>
            {this.state.errorMessage}
          </div>
        )}
      </>
    );

    const renderSelectField = () => {
      return (
        <StyledSelect
          name={name}
          value={currentValue}
          id="demo-simple-select-outlined"
          onChange={this.handleInputChanges}
          style={{
            ...inputStyles.baseStyle,
            padding:0,
            top: 0,
            borderColor: borderValidate,
          }}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          disableUnderline
        >
          <MenuItem value={'es'} style={webStyle.menuItem}>Yes</MenuItem>
          <MenuItem value={'no'} style={webStyle.menuItem}>No</MenuItem>
        </StyledSelect>
      );
    };

    const renderUifNumberField = () => (
      <>
        <input
          data-test-id="uifNumber"
          name={name}
          value={this.state.uifNumber}
          type="text"
          onChange={this.handleUifNumberChange}
          style={{
            ...inputStyles.baseStyle,
            border: checkValidBorder(this.state.isUifNumberValid),
          }}
        />
        {!this.state.isUifNumberValid && (
          <div style={webStyle.validationMessage}>
            UIF Number should be 8 digits
          </div>
        )}
      </>
    );

    const renderKycDocumentationField = () => (
      <div id="kycDocumentation">
        <div style={{ cursor: "pointer", marginTop: "5px" }}>
          <label htmlFor="kycDoc" style={{ display: "block" }}>
            <div
              style={{
                ...webStyle.inputStyleDoc,
                borderColor:this.state.showValidationMessage && !this.state.kycDocumentation 
                ? "red"
                : "",
                position: "relative",
              }}
            >
              <div id="kycDocumentationName" style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                <span style={{
                  color:'rgb(39, 53, 103)',
                    marginLeft: '5px',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}>
                  {this.state.kycDocumentation ? this.state.kycDocumentation : 'Upload Document'}
                </span>
              </div>
              <StyledFileInput
                type="file"
                id="kycDoc"
                data-test-id="kycDocumentation"
                name={name}
                style={{
                  width: "100%",
                  top: 0,
                  position: "absolute",
                  height: "100%",
                  cursor: "pointer",
                  opacity: 0,
                  left: 0,
                  }}
                onChange={this.handleUploadKycDocumentation}
                onBlur={this.handleFileBlur}
              />
            </div>
          </label>
        </div>
        {this.state.showValidationMessage && !this.state.kycDocumentation && (
          <div id="errorMessageFileUpload" style={webStyle.validationMessageDoc}>
            Please upload in correct format (.PDF / .JPG / .PNG)
          </div>
        )}
      </div>
    );

    const renderDefaultField = () => (
      <input
      maxLength={name==='nextOfKin'?50:undefined}
      name={name}
        value={currentValue}
        type="text"
        style={{
          ...inputStyles.baseStyle,
          borderColor: borderValidate,
        }}
        onChange={this.handleInputChanges2}
      />
    );

    const inputFieldMap: Record<string, () => JSX.Element> = {
      contactDetail: renderContactDetailField,
      emailAddress: renderEmailAddressField,
      typeStore: renderSelectField,
      cipro: renderSelectField,
      vatStatus: renderSelectField,
      uifNumber: renderUifNumberField,
      kycDocumentation: renderKycDocumentationField,
    };

    const inputField:JSX.Element = inputFieldMap[name]  ? inputFieldMap[name]() : renderDefaultField();

    return (
      <Box style={webStyle.fieldsMain as React.CSSProperties}>
        <label style={webStyle.labelStyle2 as React.CSSProperties}>{label}</label>
        {inputField}
      </Box>
    );
  }
  componentDidMount = async () => {
    const storeId = this.props.navigation.getParam('id')
    this.handleGetStoreSettingDetail(storeId)
   }

  render() {
    return (
      <Box style={webStyle.userSettingContainer}>
        <SideNavbar />
        <RightBoxLanding
          style={webStyle.secondContainer as React.CSSProperties}
        >
          <Box style={webStyle.drawerBox}>
            <CommonDrawer anchor={"right"} style={{ width: "100%" }} />
          </Box>
          <Header />
          <Box
            style={{
              paddingLeft: 40,
              display: "flex",
              maxWidth: "80px",
              marginTop: "25px",
            }}
            onClick={this.goBack}
          >
            <img
              src={leftArrow}
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
            />
            <Typography
              style={{
                fontFamily: "Poppins",
                color: "#000",
                fontSize: "16px",
                fontWeight: 500,
                paddingLeft: 8,
                cursor: "pointer",
              }}
            >
              Back
            </Typography>
          </Box>
          <Box style={webStyle.userSettingFeildsMian2}>
            <Box style={webStyle.userSettingTextMain}>
              <Typography
                style={webStyle.userSettingText2 as React.CSSProperties}
              >
                Store Setting
              </Typography>
            </Box>
            {this.renderInputField("storeName", "Store Name")}
            {this.renderInputField("storeref", "Store Ref Number")}
            {this.renderInputField("contactDetail", "Contact Details")}
            {this.renderInputField("emailAddress", "Email Address")}
            {this.renderInputField("nextOfKin", "Next of Kin")}
            {this.renderInputField("storeAddress", "Store Address")}
            <Box sx={webStyle.cordinateBox}>
              <label style={webStyle.labelStyle as React.CSSProperties}>
                Store coordinates
              </label>
              <input
                name="updateCoordinates"
                type="text"
                style={{
                  ...webStyle.inputStyleAdd,
                  borderColor: this.state.requiredInput.includes(
                    "updateCoordinates"
                  )
                    ? "red"
                    : "#273567",
                }}
                onChange={this.handleInputChanges2}
                value={this.state.updateCoordinates}
              />
              <img
                src={capture_icon}
                alt="Capture Icon"
                style={webStyle.captureIcon as React.CSSProperties}
              />
              {this.state.requiredInput.includes("updateCoordinates") && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  Store Coordinates is required
                </div>
              )}
            </Box>
            {this.renderInputField("typeStore", "Type of Store")}
            {this.renderInputField("cipro", "CIPRO Registration Status")}
            {this.renderInputField("vatStatus", "VAT Status")}
            {this.renderInputField("uifNumber", "UIF Number")}
            {this.renderInputField("kycDocumentation", "KYC Documentation")}
            <Button
              onClick={this.handleSubmit2}
              style={webStyle.submitButton2 as React.CSSProperties}
            >
              Submit
            </Button>
          </Box>
          <Toaster position="top-center" />
          <Modal
            aria-labelledby="transition-modal-title"
            closeAfterTransition
            onClose={this.handleCloseModal2}
            open={this.state.tickModel}
            aria-describedby="transition-modal-description"
            BackdropProps={{
              style: {
                backdropFilter: "blur(4px)",
              },
            }}
          >
            <Fade in={this.state.tickModel}>
              <ModalBoxForgot sx={webStyle.greentickModal3}>
                <img
                  src={greentick}
                  alt="Green Tick"
                  style={{
                    marginBottom: "20px",
                    width: "100%",
                    height: "119px",
                    maxWidth: "119px",
                  }}
                />
                <Typography
                  variant="h5"
                  style={webStyle.userVerifiedText2 as React.CSSProperties}
                >
                  Store has been sent to Admin for approval, Hang Tight!"
                </Typography>
              </ModalBoxForgot>
            </Fade>
          </Modal>
          <Modal
                    open={this.state.showUploadModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box style={{
                      color: "white",
                      backgroundColor: "black",
                      position: 'absolute',
                      padding: "10px",
                      left: '50%',
                      top: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: '2px solid #000',
                        width: 300,
                        borderRadius: "12px",
                        textAlign: 'center',
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "24px",
                    }}>
                        Uploading.....
                    </Box>
                </Modal>
        </RightBoxLanding>
      </Box>
    );
  }
}

const webStyle = {
  userSettingContainer: {
    backgroundColor: "#E2E8F0",
  },
  userSettingFeildsMian2: {
    maxWidth: "610px",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    padding: "20px",
    margin: "40px",
  },
  labelStyle2: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#273567",
    letterSpacing: "0em",
    lineHeight: " 24px",
    fontWeight: "400",
  },
  userSettingTextMain: {
    marginBottom: "40px",
  },
  userSettingText2: {
    fontWeight: "500",
    fontFamily: "Poppins",
    color: "#000000",
    lineHeight: "40px",
    fontSize: "20px",
  },
  inputStyle: {
    color: "#273567",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid",
    top: "26px",
    height: "40px",
    fontFamily: "Poppins",
    padding: "15px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  drawerBox: { width: "100%" } as React.CSSProperties,
  fieldsMain: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "20px 0",
  },
  inputStyleAdd: {
    color: "#273567",
    top: "26px",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid",
    padding: "15px",
    fontFamily: "Poppins",
    height: "40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: "#273567",
    letterSpacing: "0em",
    lineHeight: " 24px",
    fontWeight: "400",
  },
  cordinateBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  captureIcon: {
    right: "10",
    width: "auto",
    height: "30px",
    top: "30",
    position: "absolute",
  },
  submitButton2: {
    backgroundColor: "#C12030",
    marginTop: "16px",
    color: "#fff",
    fontSize: "16px",
    height: "50px",
    borderRadius: "8px",
    textTransform: "capitalize",
    width: "100%",
    fontFamily: "Poppins",
  },
  secondContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#E2E8F0",
  },
  greentickModal3: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    marginTop: "100px",
    margin: "auto",
    flexShrink: "0",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 28px 0px rgba(131, 131, 131, 0.17)",
  },
  userVerifiedText2: {
    fontSize: "33px",
    lineHeight: "140%",
    justifyContent: "center",
    fontWeight: 400,
    alignContent: "center",
    textAlign: "center",
    color: "#030F2D",
    display: "flex",
    fontFamily: "Poppins",
    fontStyle: "normal",
  },
  validationMessage:{
    color: "red",
    marginTop: "5px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 400,
  },
  validationMessageDoc: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
    fontWeight: 400,
    fontFamily: "Poppins",
  } as React.CSSProperties,
  menuItem:{
    color:'#273567',
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize:'14px'
  },
  inputStyleDoc: {
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    padding: "8px 40px 8px 8px", // Adjust padding for text and image
    backgroundImage: `url(${upload_icn})`, // Set the image as the background
    backgroundSize: "24px", // Adjust the size of the image
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 8px center", // Adjust the position of the image
  } as React.CSSProperties,
};
