import React from "react";
import {
    Box,
    Typography,
    Button,
    Fade,
    Modal,
    styled,
} from "@material-ui/core";
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import { capture_icon, leftArrow, greentick } from "./assets"
import { Toaster } from "react-hot-toast"
import OperatorSideBar from "../../../components/src/OperatorSideBar.web";
import CommonHeaderOperator from '../../../components/src/CommonHeaderOperator.web';
import OperatorHeader from "../../../components/src/OperatorHeader.web";

const RightBoxLanding = styled(Box)({
    '@media(min-width: 992px)': {
        marginLeft: 150
    }
})
const ModalBoxForgot = styled(Box)({
    height: '60%',
    width: '100%',
    maxWidth: '400px',
    '@media (max-width: 600px)': {
        height: 'auto',
        width: '70%',
        maxWidth: '400px',
    }
})

export default class AddMyStoresOperators extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    renderInputField2Op(name:any, label:any) {
        let inputField;
        if (name === "contactDetail") {
            inputField = (
                <>
                    <input
                        data-test-id="contactDetail"
                        name={name}
                        type="text"
                        value={this.state.contactDetail}
                        onChange={this.handleContactDetailsChange}
                        style={{
                            ...webStyleOpe.inputStyle2Ope,
                            borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                        }}
                    />
                    {!this.state.isPhoneNumberValid && <div style={{ color: 'red', marginTop: '5px',fontFamily:"Poppins",fontSize:"12px",fontWeight:400 }}>Invalid Phone Number</div>}
                </>
            );
        } else if (name === "emailAddress") {
            inputField = (
                <>
                    <input
                        data-test-id="emailAddress"
                        name={name}
                        type="email"
                        style={{...webStyleOpe.inputStyle2Ope,borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',border: this.state.isEmailValid ? '1px solid #273567' : '1px solid red',}}
                        onChange={this.handleEmailChange}
                        value={this.state.email}
                    />
                    {this.state.errorMessage && 
                    <div 
                    style={{
                         color: 'red', 
                         marginTop: '5px',
                         fontFamily:"Poppins",
                         fontSize:"12px",
                         fontWeight:400 
                         }}>{this.state.errorMessage}</div>}
                </>
            );
        } else {
            inputField = (
                <input data-test-id={name} name={name} type="text"
                    style={{
                        ...webStyleOpe.inputStyle2Ope,
                        borderColor: this.state.requiredInput.includes(name) ? 'red' : '#273567',
                    }}
                    onChange={this.handleInputChanges2}
                />
            );
        }

        return (
            <Box style={webStyleOpe.fieldsMain3Ope as React.CSSProperties}>
                <label style={webStyleOpe.labelStyle2Ope as React.CSSProperties}>{label}</label>
                {inputField}
            </Box>
        );
    }

    render() {
        return (
            <Box style={webStyleOpe.userSettingContainer3Ope}>
                <OperatorSideBar />
                <RightBoxLanding style={webStyleOpe.secondContainerOpe as React.CSSProperties}>
                    <Box style={webStyleOpe.drawerBoxOpe }>
                        <CommonHeaderOperator anchor={"right"} style={{ width: '100%' }} />
                    </Box>
                    <OperatorHeader />
                    <Box data-test-id="back" style={{ paddingLeft: 40, display: 'flex',cursor: 'pointer', maxWidth:"80px", marginTop:"25px" }} onClick={this.navigateToSettingsStoreOperator}>
                        <img src={leftArrow} style={{ width: '24px', height: '24px',  }} />
                        <Typography style={{ fontFamily: 'Poppins', fontSize: '16px', fontWeight: 500, color: '#000',paddingLeft: 8,cursor:'pointer' }}>Back</Typography>
                    </Box>
                    <Box style={webStyleOpe.userSettingFeildsMianOpe}>
                        <Box style={webStyleOpe.userSettingTextMainOpe as React.CSSProperties}>
                            <Typography style={webStyleOpe.userSettingTextOpe as React.CSSProperties}>Add My Store</Typography>
                        </Box>
                        {this.renderInputField2Op("storename", "Store Name")}
                        {this.renderInputField2Op("contactDetail", "Contact Details")}
                        {this.renderInputField2Op("emailAddress", "Email Address")}
                        {this.renderInputField2Op("nextOfKin", "Next of Kin")}
                        {this.renderInputField2Op("storeAddress", "Store Address")}
                        <Box sx={webStyleOpe.cordinateBoxOpe}>
                            <label style={webStyleOpe.labelStyleOpe as React.CSSProperties}>Store coordinates</label>
                            <input
                                type="text"
                                name="updateCoordinates"
                                style={{
                                    ...webStyleOpe.inputStyleAddOpe,
                                    borderColor: this.state.requiredInput.includes('updateCoordinates') ? 'red' : '#273567',
                                }}
                                onChange={this.handleInputChanges2}
                            />
                            <img src={capture_icon} alt="Capture Icon" style={webStyleOpe.captureIconOpe as React.CSSProperties} />
                            {this.state.requiredInput.includes('updateCoordinates') && <div style={{ color: 'red', marginTop: '5px' }}>Store Coordinates is required</div>}
                        </Box>
                        {this.renderInputField2Op("typeStore", "Type of Store")}
                        {this.renderInputField2Op("cipro", "CIPRO Registration Status")}
                        {this.renderInputField2Op("vatStatus", "VAT Status")}
                        {this.renderInputField2Op("uifNumber", "UIF Number")}
                        {this.renderInputField2Op("kycDocumentation", "KYC Documentation")}
                        <Button data-test-id="Submit" onClick={()=>this.handleSubmit3(true)} style={webStyleOpe.submitButton2Ope as React.CSSProperties}>
                            Submit
                        </Button>
                    </Box>
                    <Toaster position="top-center" />
                    <Modal
                        aria-labelledby="transition-modal-title"
                        closeAfterTransition
                        open={this.state.tickModel}
                        onClose={this.handleCloseModal2}
                        aria-describedby="transition-modal-description"
                        BackdropProps={{
                            style: {
                                backdropFilter: "blur(4px)",
                            },
                        }}
                    >
                        <Fade in={this.state.tickModel}>
                            <ModalBoxForgot sx={webStyleOpe.greentickModalOpe}>
                                <img src={greentick} alt="Green Tick" style={{ marginBottom: '20px',maxWidth: '119px', width: '100%', height: '119px' }} />
                                <Typography variant="h5" style={webStyleOpe.userVerifiedText2Ope as React.CSSProperties}>Store has been sent to Admin for approval, Hang Tight!"</Typography>
                            </ModalBoxForgot>
                        </Fade>
                    </Modal>
                </RightBoxLanding>
            </Box>
        );
    }
}

const webStyleOpe = {
    userSettingContainer3Ope: {
        backgroundColor: "#E2E8F0"
    },
    userSettingFeildsMianOpe: {
        maxWidth: "610px",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        padding: "20px",
        margin: "40px",
    },
    labelStyle2Ope: {
        fontFamily: 'Poppins',
        color: "#273567",
        fontSize: "16px",
        letterSpacing: "0em",
        lineHeight: " 24px",
        fontWeight: "400",
    },
    userSettingTextMainOpe: {
        marginBottom: "40px"
    },
    userSettingTextOpe: {
        fontFamily: 'Poppins',
        fontWeight: '500',
        color: '#000000',
        lineHeight: '40px',
        fontSize: '20px'
    },
    inputStyle2Ope: {
        color: '#273567',
        width: '100%',
        top: '26px',
        borderRadius: '8px',
        border: '1px solid',
        height: '40px',
        fontFamily: 'Poppins',
        padding: "15px",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    drawerBoxOpe:{ width: '100%' }as React.CSSProperties,
    fieldsMain3Ope: {
        display: "flex",
        maxWidth: '100%',
        flexDirection: "column",
        width: "100%",
        margin: "20px 0",
    },
    inputStyleAddOpe: {
        color: '#273567',
        top: '26px',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid',
        padding: "15px",
        height: '40px',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    labelStyleOpe: {
        fontFamily: 'Poppins',
        fontSize: "16px",
        color: "#273567",
        lineHeight: " 24px",
        letterSpacing: "0em",
        fontWeight: "400",
    },
    cordinateBoxOpe: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        width: '100%'
    },
    captureIconOpe: {
        width: 'auto',
        right: '10',
        height: '30px',
        top: '30',
        position: 'absolute',
    },
    submitButton2Ope: {
        backgroundColor: '#C12030',
        marginTop: '16px',
        fontSize: '16px',
        color: '#fff',
        height: '50px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        width: "100%",
        fontFamily: 'Poppins',
    },
    secondContainerOpe: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        backgroundColor: "#E2E8F0",
    },
    greentickModalOpe: {
        position: 'relative',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        marginTop: '100px',
        flexShrink: '0',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 28px 0px rgba(131, 131, 131, 0.17)',
    },
    userVerifiedText2Ope: {
        fontSize: "33px",
        lineHeight: "140%",
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 400,
        textAlign: "center",
        color: '#030F2D',
        display: 'flex',
        fontFamily: "Poppins",
        fontStyle: "normal"
    }
};
