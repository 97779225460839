// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordFlow from "../../blocks/forgot-password/src/ForgotPasswordFlow.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserProfileOperator from "../../blocks/user-profile-basic/src/UserProfileOperatorBasicBlock.web";
import UserProfileOperatorDocument from "../../blocks/user-profile-basic/src/UserProfileOperatorDocument.web";
import Signuplogin from "../../blocks/signuplogin/src/Signuplogin";
import CfapplyforabankaccountOperator from "../../blocks/cfapplyforabankaccount/src/CfapplyforabankaccountOperator.web";
import SignupNewUser from "../../blocks/signuplogin/src/SignupNewUser.web";
import newOperatorlogin from  "../../blocks/signuplogin/src/NewOperatorLogin.web";
import RegisterNewUser from "../../blocks/signuplogin/src/RegisterNewUser.web"
import Cfrewordreports from "../../blocks/cfrewordreports/src/Cfrewordreports";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Termsandconditions from "../../blocks/termsandconditions/src/Termsandconditions";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import SearchItem from '../../blocks/notifications/src/SearchItem.Web';
import Posintegration3 from "../../blocks/posintegration3/src/Posintegration3";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfservicedeskintegration from "../../blocks/cfservicedeskintegration/src/Cfservicedeskintegration";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import AddMyStoresOperators from "../../blocks/user-profile-basic/src/AddMyStoresOperators.web";
import AddOtherStoreOperator from "../../blocks/user-profile-basic/src/AddOtherStoreOperator.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Settings from "../../blocks/settings/src/Settings";
import ProductSalesByCategory from "../../blocks/visualanalytics/src/ProductSalesByCategory.web"
import ProductSalesByProvider from "../../blocks/visualanalytics/src/ProductSalesByProvider.web"
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import LoanApplicationFormOperator from "../../blocks/multipageforms/src/LoanApplicationFormOperator.web"
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import ExistingUser from "../../blocks/signuplogin/src/ExistingUserFlow";
import ExistingOperatorFlow from "../../blocks/signuplogin/src/ExistingOperatorFlow.web"
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlockWeb.web"
import UserProfileDocumentWeb from "../../blocks/user-profile-basic/src/UserProfileDocumentWeb.web"
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import LandingPageFirst from '../../blocks/landingpage/src/LandingPageFirst.web';
import BillingSettings from '../../blocks/user-profile-basic/src/BillingSettings.web' 
import Cfcashfloat2 from '../../blocks/cfcashfloat2/src/Cfcashfloat2.web' 
import TransferToOtherWallet from '../../blocks/cfwallet53/src/TransferToOtherWallet.web'
import TransferToOtherWalletTwo from '../../blocks/cfwallet53/src/TransferToOtherWalletTwo.web'
import TransferToOtherWalletThree from '../../blocks/cfwallet53/src/TransferToOtherWalletThree.web'
import CashFlotDetails from '../../blocks/cfcashfloat2/src/CashFlotDetails.web'
import CashFlotFirst from '../../blocks/cfcashfloat2/src/CashFlotFirst.web'
import CashFlotDetailsOperator from '../../blocks/cfcashfloat2/src/CashFlotDetailsOperator.web'
import CashFlotFirstOperator from '../../blocks/cfcashfloat2/src/CashFlotFirstOperator.web'
import NewPasswordFlow from "../../blocks/forgot-password/src/NewPasswordFlow.web";
import ExistingUserOperator from '../../blocks/signuplogin/src/ExistingOperatorFlow.web'
import UserSettingCommon from "../../blocks/user-profile-basic/src/UserSettingCommon.web"
import OperatorSettings from "../../blocks/user-profile-basic/src/OperatorSettingWeb.web"
import StoreSettings from "../../blocks/user-profile-basic/src/StoreSettings.web"
import StoreSettingsOperator from "../../blocks/user-profile-basic/src/StoreSettingsOperator.web"
import StoreSettingDetailsOperator from "../../blocks/user-profile-basic/src/StoreSettingDetailsOperator.web"
import StoreSettingDetails from "../../blocks/user-profile-basic/src/StoreSettingDetails.web"
import AddOperators from "../../blocks/user-profile-basic/src/AddOperatorWeb.web"
import UserOperatorCommon from "../../blocks/user-profile-basic/src/UserOperatorCommon.web"
import AddMyStores from '../../blocks/user-profile-basic/src/AddMyStores.web'; 
import AddOtherStore from "../../blocks/user-profile-basic/src/AddOtherStore.web";
import WalletHistory from "../../blocks/visualanalytics/src/WalletHistory";
import Cfshoppersaccount from "../../blocks/cfshoppersaccount/src/Cfshoppersaccount.web";
import CreateShopperAccount from "../../blocks/cfshoppersaccount/src/CreateShopperAccount.web";
import ShopperAccountDetails from "../../blocks/cfshoppersaccount/src/ShopperAccountDetails.web";
import AddOperatorSettings from "../../blocks/user-profile-basic/src/AddOperatorSettings.web";
import RegisterNewOperator from "../../blocks/user-profile-basic/src/RegisterNewOperator.web";
import WalletTransact from "../../blocks/cfwallet53/src/WalletTransact.web";
import WalletChooseBank from "../../blocks/cfwallet53/src/WalletChooseBank.web";
import WalletTopUp from "../../blocks/cfwallet53/src/WalletTopUp.web";
import PaymentFirstPage from "../../blocks/cfwallet53/src/PaymentFirstPage.web";
import PaymentSecondPage from "../../blocks/cfwallet53/src/PaymentSecondPage.web";
import CfWallletPaymentMode from "../../blocks/cfwallet53/src/CfWallletPaymentMode.web";
import PayBillToStore from "../../blocks/cfwallet53/src/PayBillToStore.web"
import Commision from "../../blocks/cfwallet53/src/Commision.web"
import CommisionSecond from "../../blocks/cfwallet53/src/CommisionSecond.web"
import InvoiceDetail from "../../blocks/cfwallet53/src/InvoiceDetail.web"
import PaymentMode from "../../blocks/cfwallet53/src/PaymentMode.web"
import EFTPaymentMode from "../../blocks/cfwallet53/src/EFTPaymentMode.web"
import AddBux from "../../blocks/cfwallet53/src/AddBux.web";
import BuxWallet from "../../blocks/cfwallet53/src/BuxWallet.web";
import BuxPaymentFirstPages from "../../blocks/cfwallet53/src/BuxPaymentFirstPages.web";
import BuxPaymentSecondPages from "../../blocks/cfwallet53/src/BuxPaymentSecondPages.web";
import InventoryTrend from "../../blocks/visualanalytics/src/InventoryTrend.web"
import SalesReportingPage from "../../blocks/salesreporting/src/SalesReportingPage.web";
import MultipageForms from "../../blocks/multipageforms/src/MultipageForms.web";
import ApplyForLoan from "../../blocks/cfloanapplication2/src/ApplyForLoan.web";
import LoanCalculator from "../../blocks/cfloanapplication2/src/LoanCalculator.web";
import Cfapplyforabankaccount from "../../blocks/cfapplyforabankaccount/src/Cfapplyforabankaccount.web";
import LoanApplicationForm from "../../blocks/multipageforms/src/LoanApplicationForm.web";
import CfshoppersaccountOperator from "../../blocks/cfshoppersaccount/src/Cfshoppersaccountoperator.web"
import CreateShopperAccountOperator from "../../blocks/cfshoppersaccount/src/CreateShopperAccountOperator.web"
import ShopperAccountDetailsOperator from "../../blocks/cfshoppersaccount/src/ShopperAccountDetailsOperator.web"
import VirtualAssistance from "../../blocks/cfapplyforvirtualassistance2/src/VirtuaAssistance.web";
import OperatorSettingsOperator from "../../blocks/user-profile-basic/src/OperatorSettingsOperator.web";
import BillingSettingsOperator from "../../blocks/user-profile-basic/src/BillingSettingsOperator.web";
import ApplicationForm from "../../blocks/cfloanapplication2/src/ApplicationForm.web";
import ApplicationFormOperator from "../../blocks/cfloanapplication2/src/ApplicationFormOperator.web";
import ApplyForLoanOperator from "../../blocks/cfloanapplication2/src/ApplyForLoanOperator.web"
import LoanCalculatorOperator from "../../blocks/cfloanapplication2/src/LoanCalculatorOperator.web"
import ChooseOperator from "../../blocks/signuplogin/src/ChooseOperator.web";
import OperatorForgotPass from "../../blocks/forgot-password/src/OperatorForgotPass.web"
import AddOperatorsOp from "../../blocks/user-profile-basic/src/AddOperatorsOp.web"
import RegisterNewOperatorOp from "../../blocks/user-profile-basic/src/RegisterNewOperatorOp.web"
import AddOperatorSettingsOp from "../../blocks/user-profile-basic/src/AddOperatorSettingsOperator.web"
import OperatorLandingPage from "../../blocks/landingpage/src/OperatorLandingPage.web";
import OperatorNotification from "../../blocks/notifications/src/OperatorNotification.web";
import OperatorSearch from '../../blocks/notifications/src/OperatorSearch.web';
import VirtualAssistanceOperator from "../../blocks/cfapplyforvirtualassistance2/src/VirtualAssistanceOperator.web";
import ScrollToTop from "../../components/src/ScrollToTop.web";


const routeMap = {  
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
// ForgotPassword:{
//  component:ForgotPassword,
// path:"/ForgotPassword"},
ForgotPassword:{
  component:ForgotPasswordFlow,
  path:"/ForgotPassword"
},
CreateShopperAccountOperator:{
  component:CreateShopperAccountOperator,
  path:"/CreateShopperAccountOperator"
},
ShopperAccountDetailsOperator:{
  component:ShopperAccountDetailsOperator,
  path:"/ShopperAccountDetailsOperator/:accountId"
},
AddOperatorSettingsOp:{
  component:AddOperatorSettingsOp,
  path:"/AddOperatorSettingsOp"
},
SetPassword:{
  component: NewPasswordFlow,
  path: "/SetPassword"
},
OperatorApplyForBankAccount:{
  component:CfapplyforabankaccountOperator,
  path:"/OperatorApplyForBankAccount"
},
OperatorShopperAccount:{
component:CfshoppersaccountOperator,
path:"/OperatorShopperAccount"
},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
MultipageForms:{
  component:MultipageForms,
  path:"/Loan"
},
ApplyForBankAccount:{
  component:Cfapplyforabankaccount,
  path:"/ApplyForBankAccount"
},
LoanApplicationFormOperator:{
  component:LoanApplicationFormOperator,
  path:"/LoanApplicationFormOperator"
},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserProfileBasicBlockWeb:{
  component:UserProfileBasicBlockWeb,
 path:"/UserProfileBasicBlockWeb"},
 UserProfileDocumentWeb:{
  component:UserProfileDocumentWeb,
 path:"/UserProfileDocumentWeb"},
 UserProfile:{
  component:UserSettingCommon,
 path:"/UserProfile"},
UserProfileOperator:{
  component:UserProfileOperator,
  path:"/UserProfileOperator"
},
UserProfileOperatorDocument:{
  component:UserProfileOperatorDocument,
  path:"/UserProfileOperatorDocument"
},
SignIn:{
 component:Signuplogin,
path:"/SignIn"},
Cfrewordreports:{
 component:Cfrewordreports,
path:"/Cfrewordreports"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Termsandconditions:{
 component:Termsandconditions,
path:"/Termsandconditions"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
SearchItem:{
  component:SearchItem,
 path:"/SearchItem"},
Posintegration3:{
 component:Posintegration3,
path:"/Posintegration3"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfservicedeskintegration:{
 component:Cfservicedeskintegration,
path:"/Cfservicedeskintegration"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Settings:{
 component:Settings,
path:"/Settings"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/Login"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
SignUp:{
  component:SignupNewUser,
 path:"/SignUp"},
 RegisterNewUser:{
  component:RegisterNewUser,
  path:"/RegisterNewUser"
 },
 ExistingUser:{
  component:ExistingUser,
  path:"/ExistingUser"
 },
 ExistingOperatorFlow:{
  component:ExistingOperatorFlow,
  path:"/ExistingOperatorFlow"
 },
 LandingPageWeb:{
  component:LandingPageWeb,
  path:"/LandingPageWeb"
 },
 Dashboard:{
  component:LandingPageFirst,
  path:"/Dashboard"
 },
 BillingSettings:{
  component:BillingSettings,
  path:"/BillingSettings"
 },
 BillingSettingsOperator:{
  component:BillingSettingsOperator,
  path:"/BillingSettingsOperator"
 },
 ProductSalesByCategory:{
  component:ProductSalesByCategory,
  path:"/ProductSalesByCategory"
 },
 ProductSalesByProvider:{
  component:ProductSalesByProvider,
  path:"/ProductSalesByProvider"
 },
 MerchantCashFloat:{
  component:Cfcashfloat2,
  path:"/MerchantCashFloat"
},
TransferToOtherWallet:{
  component:TransferToOtherWallet,
  path:"/TransferToOtherWallet"
}, 
TransferToOtherWalletTwo:{
  component:TransferToOtherWalletTwo,
  path:"/TransferToOtherWalletTwo"
}, 
TransferToOtherWalletThree:{
  component:TransferToOtherWalletThree,
  path:"/TransferToOtherWalletThree"
},
CashFlotDetails:{
  component:CashFlotDetails,
  path:"/CashFlotDetails"
},
CashFlotFirst:{
  component:CashFlotFirst,
  path:"/CashFlotFirst"
},
CashFlotDetailsOperator:{
  component:CashFlotDetailsOperator,
  path:"/CashFlotDetailsOperator"
},
OperatorCashFloat:{
  component:CashFlotFirstOperator,
  path:"/OperatorCashFloat"
},
 ExistingUserOperator:{
  component:ExistingUserOperator,
  path:"/ExistingUserOperator"
 },
 newOperatorlogin:{
  component:newOperatorlogin,
  path:"/newOperatorlogin",
  exact: true
},

Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: false
  // },
  OperatorSettings:{
  component:OperatorSettings,
  path:"/OperatorSettings"
},
OperatorSettingsOperator:{
  component:OperatorSettingsOperator,
  path:"/OperatorSettingsOperator"
},
StoreSettings:{
  component:StoreSettings,
  path:"/StoreSettings"
},
StoreSettingsOperator:{
  component:StoreSettingsOperator,
  path:"/StoreSettingsOperator"

},
StoreSettingDetails:{
  component:StoreSettingDetails,
  path:"/StoreSettingDetails/:id"
},
StoreSettingDetailsOperator:{
 component:StoreSettingDetailsOperator,
 path:"/StoreSettingDetailsOperator"
},
AddOperators:{
  component:AddOperators,
  path:"/AddOperators/:storeId"
},
AddOperatorsOp:{
 component:AddOperatorsOp,
 path:"/AddOperatorsOp"
},
RegisterNewOperatorOp:{
  component:RegisterNewOperatorOp,
  path:"/RegisterNewOperatorOp"
},
OperatorUserProfile:{
  component:UserOperatorCommon,
  path:"/OperatorUserProfile"
},
WalletHistory:{
  component:WalletHistory,
  path:"/WalletHistory"
},

ShoppersAccount:{
  component:Cfshoppersaccount,
  path:"/ShoppersAccount"
},
CreateShopperAccount:{
  component:CreateShopperAccount,
  path:"/CreateShopperAccount"
},
ShopperAccountDetails:{
  component:ShopperAccountDetails,
  path:"/ShopperAccountDetails/:accountId"
},
AddMyStores:{
  component:AddMyStores,
  path:"/AddMyStores"
},
AddMyStoresOperators:{
  component:AddMyStoresOperators,
  path:"/AddMyStoresOperators"
},
AddOtherStore:{
  component:AddOtherStore,
  path:"/AddOtherStore"
},
AddOtherStoreOperator:{
  component:AddOtherStoreOperator,
  path:"/AddOtherStoreOperator"
},
AddOperatorSettings:{
  component:AddOperatorSettings,
  path:"/AddOperatorSettings/:operatorEmail"
},
WalletTransact:{
  component:WalletTransact,
  path:"/WalletTransact"
},
WalletChooseBank:{
  component:WalletChooseBank,
  path:"/WalletChooseBank"
},
WalletTopUp:{
  component:WalletTopUp,
  path:"/WalletTopUp"
},
PaymentFirstPage:{
  component:PaymentFirstPage,
  path:"/PaymentFirstPage"
},
PaymentSecondPage:{
  component:PaymentSecondPage,
  path:"/PaymentSecondPage"
},
CfWallletPaymentMode:{
  component:CfWallletPaymentMode,
  path:"/CfWallletPaymentMode"
},
CommisionSecond:{
  component:CommisionSecond,
  path:"/CommisionSecond"
},
Commision:{
  component:Commision,
  path:"/Commision"
},
RegisterNewOperator:{
  component:RegisterNewOperator,
  path:"/RegisterNewOperator"
},
PayBillToStore:{
  component:PayBillToStore,
  path:"/PayBillToStore"
},
InvoiceDetail:{
  component:InvoiceDetail,
  path:"/InvoiceDetail"
},
PaymentMode:{
  component:PaymentMode,
  path:"/PaymentMode"
},
EFTPaymentMode:{
  component:EFTPaymentMode,
  path:"/EFTPaymentMode"
},
AddBux:{
  component:AddBux,
  path:"/AddBux"
},
BuxWallet:{
  component:BuxWallet,
  path:"/BuxWallet"
},
BuxPaymentFirstPages:{
  component:BuxPaymentFirstPages,
  path:"/BuxPaymentFirstPages"
},
BuxPaymentSecondPages:{
  component:BuxPaymentSecondPages,
  path:"/BuxPaymentSecondPages"
},
InventoryTrend:{
  component:InventoryTrend,
  path:"/InventoryTrend"
},SalesReport:{
  component:SalesReportingPage,
  path:"/SalesReport"
},
OperatorApplyForLoan:{
  component:ApplyForLoanOperator,
  path:"/OperatorApplyForLoan"
},
LoanCalculatorOperator:{
 component:LoanCalculatorOperator,
 path:"/LoanCalculatorOperator"
},
ApplyForLoan:{
  component:ApplyForLoan,
  path:"/ApplyForLoan"
},
LoanCalculator:{
  component:LoanCalculator,
  path:"/LoanCalculator"
},
LoanApplicationForm:{
  component:LoanApplicationForm,
  path:"/LoanApplicationForm"
},
VirtualAssistance:{
  component:VirtualAssistance,
  path:"/VirtualAssistance"
},
ApplicationForm:{
  component:ApplicationForm,
  path:"/ApplicationForm"
},
ApplicationFormOperator:{
 component:ApplicationFormOperator,
 path:"/ApplicationFormOperator"
},
ChooseOperator:{
  component:ChooseOperator,
  path:"/ChooseOperator"
},
OperatorForgotPass:{
  component:OperatorForgotPass,
  path:"/OperatorForgotPass"
},OperatorDashboard:{
  component:OperatorLandingPage,
  path:"/OperatorDashboard"
},
OperatorNotification:{
  component:OperatorNotification,
 path:"/OperatorNotification"},
 OperatorSearch:{
  component:OperatorSearch,
 path:"/OperatorSearch"},
 VirtualAssistanceOperator:{
  component:VirtualAssistanceOperator,
 path:"/VirtualAssistanceOperator"},
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{maxWidth:"100%"}}>
        {/* <TopNav /> */}
        <ScrollToTop/>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;